import { ResponsivePie } from '@nivo/pie';
import { Box, Card, Paper, ToggleButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as React from 'react';
import { TrashTypes } from 'constants/trash';
import { StoreInterface } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import ChartLoader from './ChartLoader';
import { PatternDefinitions, WasteChartPatterns, WastesChartColors } from 'constants/charts';
import { listNonSortableWasteTypes } from 'helpers/trash';

const mapStateToProps = (state: StoreInterface) => ({
    data: state.StatsReducer.trashes,
    unsortableTrash: state.StatsReducer.unsortableTrash,
    nbTrashes: state.StatsReducer.totalTrashes,
    nbBatches: state.StatsReducer.totalBatches,
    nbUnsortableBatches: state.StatsReducer.totalUnsortableBatches,
});

const connector = connect(mapStateToProps);

type PieChartProps = ConnectedProps<typeof connector> & {

};

function WastePieChart(props: PieChartProps) {
    const { data, nbTrashes, nbBatches, unsortableTrash, nbUnsortableBatches } = props;

    const { t } = useTranslation([Namespaces.stats]);

    const theme = useTheme();

    // responsiveness
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isVerySmall = useMediaQuery(theme.breakpoints.down("xs"));

    const MARGIN = isSmall ? { top: 40, right: 0, bottom: 40, left: 0 } : { top: 40, right: 120, bottom: 40, left: 120 };

    const [selected, setSelected] = React.useState(false);

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <ChartLoader />
            <ResponsivePie
                data={selected ? unsortableTrash : data}
                margin={MARGIN}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={(datum) => WastesChartColors[datum.id.toString() as TrashTypes]}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                enableArcLinkLabels={!isVerySmall}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextOffset={6}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabel={(e) => `${e.data.label}`}
                arcLinkLabelsOffset={0}
                arcLinkLabelsDiagonalLength={12}
                arcLinkLabelsStraightLength={8}
                arcLinkLabelsThickness={1}
                arcLinkLabelsColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor="#FFFFFF"
                arcLabel={function (e) { return `${e.value}%` }}
                animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                tooltip={({ datum }) => (
                    <Card
                        sx={{
                            background: "white",
                            padding: "4px 8px",
                            border: "1px solid #ccc",
                            borderRadius: 1,
                        }}
                    >
                        <Box
                            py="3px"
                            display="flex"
                            alignItems="center"
                        >
                            {datum.data.label} {datum.data.value}%
                        </Box>
                    </Card>
                )}
                defs={PatternDefinitions}
                fill={WasteChartPatterns}
            />

            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    top: MARGIN.top + 100,
                    right: MARGIN.right + 100,
                    bottom: MARGIN.bottom + 100,
                    left: MARGIN.left + 100,
                    // This is important to preserve the chart interactivity
                    pointerEvents: "none"
                }}
            >
                <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                    {selected ? t("sorting_error", { ns: Namespaces.stats, count: selected ? nbUnsortableBatches : nbBatches }) : t("sortings_received", { ns: Namespaces.stats, count: selected ? nbUnsortableBatches : nbBatches })}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    bottom: MARGIN.bottom + 270,
                    left: MARGIN.left + 220,
                    width: 180,
                    justifyContent: "flex-end",
                    alignItems: "self-end",
                }}
            >
                <ToggleButton
                    value="Tous les déchets/Erreurs de tri"
                    selected={selected}
                    onChange={() => {
                        setSelected(!selected);
                    }}
                    title="Tous les déchets/Erreurs de tri"
                >
                    {selected ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    <label style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 5, paddingTop: 3 }} >{selected ? t("display_normal_stats", { ns: Namespaces.actions }) : t("display_filtered_stats", { ns: Namespaces.actions })}</label>
                </ToggleButton>
            </Box>
        </Box>
    );
}

export default connector(WastePieChart);