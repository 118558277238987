import { StoreInterface } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Container, Grid, GridProps, Box, Typography, TypographyProps, styled } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import SectionLoader from "components/_include/SectionLoader";
import NameAndSelectHeader from "../NameAndSelectHeader";
import SortingStatsFilters from "../CoconStatsFilters";
import BatchesCountChart from "components/Statistics/Charts/BatchesCountChart";
import CO2EquivalenceCard from "components/Statistics/Card/CO2EquivalenceCard";
import SortingQualityChart from "components/Statistics/Charts/SortingQualityChart";
import CO2SavedChart from "components/Statistics/Charts/CO2SavedChart";
import ChartPaperWrapper from "components/Statistics/ChartPaperWrapper";
import ChartTitle from "components/Statistics/Charts/ChartTitle";
import FillRatesChart from "components/Statistics/Charts/FillRatesChart";

const mapStateToProps = (state: StoreInterface) => ({
    selectedCocon: state.CoconReducer.selectedCocon.data,
    coconLoading: state.CoconReducer.selectedCocon.loading,
    timeUnit: state.StatsReducer.data.timeUnit,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type SortingStatsProps = ConnectedProps<typeof connector> & {

}

function SortingStatsPage(props: SortingStatsProps) {

    const { t } = useTranslation([Namespaces.stats, Namespaces.titles,])

    const { coconLoading, timeUnit, } = props;

    const navigate = useNavigate();

    const handleCoconChange = (coconId: string) => {
        navigate(coconId);
    }

    return (
        coconLoading ? (
            <SectionLoader />
        ) : (
            <Container>
                <NameAndSelectHeader />

                <SortingStatsFilters />

                <Grid container sx={{ height: "90%", }} spacing={4}>
                    <ChartPaperWrapper item lg={6} xs={12}>
                        <ChartTitle>
                            {t("number_of_batches_per", {
                                ns: Namespaces.stats,
                                context: timeUnit,
                            })}
                        </ChartTitle>
                        <BatchesCountChart />
                    </ChartPaperWrapper>

                    <ChartPaperWrapper item lg={6} xs={12}>
                        <ChartTitle>
                            {t("sorting_quality", { ns: Namespaces.stats })}
                        </ChartTitle>
                        <SortingQualityChart />
                    </ChartPaperWrapper>

                    <ChartPaperWrapper item lg={6} xs={12}>
                        <ChartTitle>
                            {t("kg_co2_saved_per", {
                                ns: Namespaces.stats,
                                context: timeUnit,
                            })}
                        </ChartTitle>
                        <CO2SavedChart />
                    </ChartPaperWrapper>

                    <ChartPaperWrapper item lg={6} xs={12}>
                        <ChartTitle>
                            {t("bins_fill_rates", {
                                ns: Namespaces.stats,
                            })}
                        </ChartTitle>
                        <FillRatesChart />
                    </ChartPaperWrapper>

                    <Grid item xs={12} sx={{ mb: 2 }}>
                        {/* <ChartPaperWrapper item lg={6} xs={12}> */}
                        <CO2EquivalenceCard />
                        {/* </ChartPaperWrapper> */}
                    </Grid>
                </Grid>
            </Container>
        )

    )
};

export default connector(SortingStatsPage);