import { Box } from "@mui/material";
import StatsAction from "actions/stats";
import DatesSelect from "components/_include/Filters/DatesSelect";
import { DATE_SELECT_TYPES, DEFAULT_DATE_SELECT_TYPE, getDatesFromDateSelectType, getDatesFromSearchParams } from "helpers/dates";
import { usePrevious } from "helpers/refs";
import { listSortableWasteTypes } from "helpers/trash";
import { Namespaces } from "locales/translations";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useMatch, useParams, useSearchParams } from "react-router-dom";
import { StoreInterface } from "store/reducers";

const mapStateToProps = (state: StoreInterface) => ({
    
});

const mapDispatchToProps = (dispatch: any) => ({
    loadCoconStats: (coconId: string, from: moment.Moment, to: moment.Moment,) => dispatch(StatsAction.getCoconStats(coconId, from, to)),
    loadClusterStats: (clusterId: string, from: moment.Moment, to: moment.Moment,) => dispatch(StatsAction.getClusterStats(clusterId, from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type FiltersProps = ConnectedProps<typeof connector> & {

}

function CoconStatsFilters(props: FiltersProps) {
    const { loadCoconStats,loadClusterStats } = props;

    const { coconId, clusterId } = useParams();

    const { t } = useTranslation([Namespaces.wastes]);

    const [searchParams, setSearchParams] = useSearchParams();
    const { range, from, to } = getDatesFromSearchParams(searchParams);
    
    useEffect(() => { // load stats when URL search params or cocon ID change
        if (coconId && from && to) {
            loadCoconStats(coconId, from, to);
        } else if (clusterId && from && to){
            loadClusterStats(clusterId, from, to)
        }
    }, [from, to, coconId, clusterId]);
  
    const coconIsWastePage = useMatch("cocons/:coconId/wastes");
    
    const clusterIsWastePage = useMatch("clusters/:clusterId/wastes");

    useEffect(() => { // set default URL search params
        let updateNeeded = false;
        if (!from || !to || !range) {
            updateNeeded = true;
            const dates = getDatesFromDateSelectType(DEFAULT_DATE_SELECT_TYPE, undefined, from, to);
    
            searchParams.set("range", DEFAULT_DATE_SELECT_TYPE);
            searchParams.set("from", dates.start.format("YYYY-MM-DD"));
            searchParams.set("to", dates.end.format("YYYY-MM-DD"));
        }
        if ((coconIsWastePage || clusterIsWastePage) && !searchParams.has("waste")) {
            updateNeeded = true;
            const defaultWaste = listSortableWasteTypes(t)[0];
            searchParams.set("waste", defaultWaste);
        }

        if (updateNeeded) {
            setSearchParams(searchParams, { replace: true });
        }
    }, [searchParams, coconIsWastePage, clusterIsWastePage]);

    return (
        <Box
            width={420}
            mx="auto"
            mb={2}
            >
            <DatesSelect />
        </Box>
    );
}

export default connector(CoconStatsFilters);