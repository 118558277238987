import { Action, CodeError } from "actions/actions";
import { Actions, Types } from "constants/actions";
import Reward, { RewardFilter, RewardsCategories, RewardsFilters } from "models/Shop/Reward";

export interface RewardsContextInterface {
    rewards: {
        data: Reward[],
        filtered: {
            data: Reward[],
            filters: RewardsFilters,
            loading: boolean,
            totalCount: number;
        },
        loading: boolean,
        error: CodeError | null,
        requestId: number | null,
        next?: string;
    },
    filters: RewardsFilters,
    selectedReward: {
        data: Reward | null;
        loading: boolean;
        error: CodeError | null;
    },
}

const initialState = {
    rewards: {
        data: [],
        filtered: {
            data: [],
            filters: {
                [RewardFilter.CATEGORY]: Object.values(RewardsCategories),
                [RewardFilter.LOCATION]: {
                    selected: false,
                },
                [RewardFilter.FAVORITES]: false,
            },
            loading: false,
            totalCount: 0,
        },
        loading: false,
        error: null,
        requestId: null,
    },
    filters: {
        [RewardFilter.CATEGORY]: Object.values(RewardsCategories),
        [RewardFilter.LOCATION]: {
            selected: false,
        },
        [RewardFilter.FAVORITES]: false,
    },
    selectedReward: {
        data: null,
        loading: false,
        error: null,
    },
    purchases: {
        data: [],
        loading: false,
        error: null,
    },
    pastPurchases: {
        data: [],
        loading: false,
        error: null,
        loaded: false,
    },
    selectedPurchase: {
        data: null,
        loading: false,
        error: null,
    },
};

const RewardReducer = (state: RewardsContextInterface = initialState, action: Action) => {
    let rewards: Reward[];

    switch (action.type) {
        case Types.BEGIN:
            switch (action.action) {
                case Actions.LOAD_REWARDS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            data: [],
                            filtered: {
                                data: [],
                                filters: action.payload.filters,
                                totalCount: 0,
                            },
                            loading: true,
                            error: null,
                            requestId: action.payload.requestId,
                            next: undefined,
                        },
                        filters: action.payload.filters,
                    };

                // case Actions.LOAD_MORE_REWARDS:
                //     return {
                //         ...state,
                //         rewards: {
                //             ...state.rewards,
                //             loading: true,
                //         },
                //     };

                case Actions.PREVIEW_REWARDS_WITH_FILTERS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            filtered: {
                                data: [],
                                filters: action.payload.filters,
                                loading: true,
                                totalCount: 0,
                            },
                            requestId: action.payload.requestId,
                        },
                    };

                case Actions.SUGGEST_REWARD:
                    return {
                        ...state,
                        selectedReward: {
                            ...state.selectedReward,
                            loading: true,
                            error: null,
                        },
                    };
            }
            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.LOAD_REWARDS:
                    if (action.payload.requestId !== state.rewards.requestId) return state; // not the latest request
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            data: action.payload.data,
                            filtered: {
                                ...state.rewards.filtered,
                                data: action.payload.data,
                                totalCount: action.payload.totalCount,
                            },
                            loading: false,
                            requestId: null,
                            next: action.payload.next,
                        },
                    };

                case Actions.LOAD_MORE_REWARDS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            // loading: false,
                            data: [...state.rewards.data, ...action.payload.data],
                            next: action.payload.next,
                        },
                    };

                case Actions.PREVIEW_REWARDS_WITH_FILTERS:
                    if (action.payload.requestId !== state.rewards.requestId) return state; // not the latest request
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            filtered: {
                                ...state.rewards.filtered,
                                data: action.payload.data,
                                loading: false,
                                totalCount: action.payload.totalCount,
                            },
                            requestId: null,
                            next: action.payload.next,
                        },
                    };

                case Actions.APPLY_FILTERS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            data: state.rewards.filtered.data,
                        },
                        filters: state.rewards.filtered.filters,
                    };

                case Actions.RESET_FILTERS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            filtered: {
                                ...state.rewards.filtered,
                                data: state.rewards.data,
                                filters: state.filters,
                            },
                            next: undefined,
                        },
                    };

                case Actions.SUGGEST_REWARD:
                    return {
                        ...state,
                        selectedReward: {
                            ...state.selectedReward,
                            loading: false,
                        },
                    };
            }
            break;

        case Types.FAIL:
            switch (action.action) {
                case Actions.LOAD_REWARDS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            loading: false,
                            error: action.payload,
                            requestId: null,
                        },
                    };

                case Actions.LOAD_MORE_REWARDS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            loading: false,
                            error: action.payload,
                        },
                    };

                case Actions.PREVIEW_REWARDS_WITH_FILTERS:
                    return {
                        ...state,
                        rewards: {
                            ...state.rewards,
                            loading: false,
                            error: action.payload,
                            requestId: null,
                        },
                    };

                case Actions.SUGGEST_REWARD:
                    return {
                        ...state,
                        selectedReward: {
                            ...state.selectedReward,
                            loading: false,
                            error: action.payload,
                        },
                    };
            }
            break;

    }

    return state;
}

export default RewardReducer;