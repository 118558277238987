import { CodeError, Action } from "actions/actions";
import { Actions, Types } from "constants/actions";

export enum WasteReportStatus {
    FILLING, // user is filling up the report
    SENDING, // report is being sent to server
    SENT,    // report has been successfully sent
}

export interface WasteReportContextInterface {
    status: WasteReportStatus,
    error: CodeError | null,
}

const initialState = {
    status: WasteReportStatus.FILLING,
    error: null,
};

export default (state: WasteReportContextInterface = initialState, action: Action) => {
    switch (action.type) {
        case Types.BEGIN:
            switch (action.action) {
                case Actions.SEND_WASTE_REPORT:
                    return {
                        ...state,
                        status: WasteReportStatus.SENDING,
                        error: null,
                    };
            }
            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.SEND_WASTE_REPORT:
                    return {
                        ...state,
                        status: WasteReportStatus.SENT,
                    };
            }
            break;

        case Types.FAIL:
            switch (action.action) {
                case Actions.SEND_WASTE_REPORT:
                    return {
                        ...state,
                        status: WasteReportStatus.FILLING,
                        error: action.payload
                    };
            }

            break;
    }

    return state;
}