import { Action, CodeError } from "actions/actions";
import { Actions, Types } from "constants/actions";



export interface CommunicationMediaContextInterface {
    partnerLogo: string;
    joinCode: string;
}

const initialState: CommunicationMediaContextInterface = {
    partnerLogo: "",
    joinCode: ""
};

function CommunicationMediaReducer(state = initialState, action: Action): CommunicationMediaContextInterface {
    switch (action.type) {
        case Types.SUCCESS:
            switch (action.action) {
                case Actions.SET_PARTNER_LOGO:
                    return {
                        ...state,
                        partnerLogo: action.payload,
                    };
                case Actions.SET_JOIN_CODE:
                    return {
                        ...state,
                        joinCode: action.payload,
                    }
            }
        default:
            return state;
    }
}

export default CommunicationMediaReducer;