import { Action, CodeError } from "actions/actions";
import Cocon from "models/Cocons/Cocon";
import { Actions, Types } from "constants/actions";
import CoconIssue, { IssueStatus } from "models/Cocons/CoconIssue";
import CoconsCluster from "models/Cocons/CoconsCluster";
import _ from "lodash";

export interface CoconContextInterface {
    cocons: {
        data: Cocon[],
        loading: boolean,
        error: CodeError | null,
    },
    clusters: {
        data: CoconsCluster[],
        loading: boolean,
        error: CodeError | null,
    },
    selectedCluster: {
        data : CoconsCluster | null,
        loading: boolean,
        error: CodeError | null,
    },
    selectedCocon: {
        data: Cocon | null,
        loading: boolean,
        error: CodeError | null,
    },
    coconIssues: {
        data: CoconIssue[],
        updating: string[], // IDs of the issues updating
        loading: boolean,
        error: CodeError | null,
    },
}

const initialState = {
    cocons: {
        data: [],
        loading: false,
        error: null,
    },
    clusters: {
        data: [],
        loading: false,
        error: null,
    },
    selectedCluster : {
        data:null,
        loading: false,
        error: null,
    },
    selectedCocon: {
        data: null,
        loading: false,
        error: null,
    },
    coconIssues: {
        data: [],
        updating: [],
        loading: false,
        error: null,
    },
};

export default (state: CoconContextInterface = initialState, action: Action) => {
    switch (action.type) {
        case Types.BEGIN:
            switch (action.action) {
                case Actions.LOAD_COCONS_CLUSTERS:
                    return {
                        ...state,
                        clusters: {
                            ...state.clusters,
                            loading: true,
                            error: null,
                        },
                        cocons: {
                            ...state.cocons,
                            loading: true,
                            error: null,
                        },
                    };
                case Actions.LOAD_CLUSTER :
                    return {
                        ...state,
                        selectedCluster: {
                            ...state.selectedCluster,
                            loading: true,
                            error: null,
                        }
                    };
                case Actions.LOAD_COCON:
                    return {
                        ...state,
                        selectedCocon: {
                            ...state.selectedCocon,
                            loading: true,
                            error: null,
                        },
                    };

                case Actions.LOAD_COCON_ISSUES:
                    return {
                        ...state,
                        coconIssues: {
                            ...state.coconIssues,
                            loading: true,
                            error: null,
                        },
                    };

                case Actions.UPDATE_COCON_ISSUE:
                    return {
                        ...state,
                        coconIssues: {
                            ...state.coconIssues,
                            updating: [...state.coconIssues.updating, action.payload.id],
                        },
                    };
            }
            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.LOAD_COCONS_CLUSTERS:
                    let coconsClusters: CoconsCluster[] = action.payload.map((cluster: CoconsCluster) => {
                        cluster.cocons.sort(compareCocons); // only sort by address
                        return cluster;
                    });
                    coconsClusters.sort(compareCoconsClusters); // sort clusters by managedBy, then address

                    return {
                        ...state,
                        clusters: {
                            ...state.clusters,
                            loading: false,
                            data: coconsClusters,
                        },
                        cocons: {
                            ...state.cocons,
                            loading: false,
                            data: _.flatten(_.map(coconsClusters, 'cocons')).sort(compareCocons), // sort clusters by managed by, then by cluster ID, then by address
                        },
                    };

                case Actions.LOAD_COCON:
                    return {
                        ...state,
                        selectedCluster:  {
                            ...state.selectedCluster,
                            data: null,
                        },
                        selectedCocon: {
                            ...state.selectedCocon,
                            loading: false,
                            data: action.payload,
                        },
                    };
                case Actions.LOAD_CLUSTER:
                    return {
                        ...state,
                        selectedCluster:  {
                            ...state.selectedCluster,
                            loading: false,
                            data: action.payload,
                        },
                        selectedCocon: {
                            ...state.selectedCocon,
                            data: null,
                        }
                    };

                case Actions.LOAD_COCON_ISSUES:
                    return {
                        ...state,
                        coconIssues: {
                            ...state.coconIssues,
                            loading: false,
                            data: action.payload,
                        },
                    };

                case Actions.UPDATE_COCON_ISSUE:
                    let cocons = state.cocons.data;
                    if (action.payload.status === IssueStatus.FIXED) {
                        // decrement number of issues on Cocon
                        cocons = cocons.map(cocon => {
                            if (cocon.id !== action.payload.cocon.id) return cocon;
                            cocon.pendingIssues -= 1;
                            return cocon;
                        })
                    }
                    return {
                        ...state,
                        cocons: {
                            ...state.cocons,

                        },
                        coconIssues: {
                            ...state.coconIssues,
                            updating: state.coconIssues.updating.filter(id => id !== action.payload.id),
                            data: state.coconIssues.data.map(issue => issue.id !== action.payload.id ? issue : action.payload),
                        },
                    };
            }
            break;

        case Types.FAIL:
            switch (action.action) {
                case Actions.LOAD_COCONS_CLUSTERS:
                    return {
                        ...state,
                        clusters: {
                            ...state.clusters,
                            loading: false,
                            error: action.payload,
                        },
                        cocons: {
                            ...state.cocons,
                            loading: false,
                        },
                    };

                case Actions.LOAD_CLUSTER:
                    return {
                        ...state,
                        selectedCluster: {
                            ...state.selectedCluster,
                            loading: false,
                            error: action.payload,
                        }
                    }

                case Actions.LOAD_COCON:
                    return {
                        ...state,
                        selectedCocon: {
                            ...state.selectedCocon,
                            loading: false,
                            error: action.payload,
                        },
                    };

                case Actions.LOAD_COCON_ISSUES:
                    return {
                        ...state,
                        coconIssues: {
                            ...state.coconIssues,
                            loading: false,
                            error: action.payload,
                        },
                    };

                case Actions.UPDATE_COCON_ISSUE:
                    return {
                        ...state,
                        coconIssues: {
                            ...state.coconIssues,
                            updating: state.coconIssues.updating.filter(id => id !== action.payload.id),
                            error: action.payload.error,
                        },
                    };
            }

            break;
    }

    return state;
}

const compareCoconsClusters = (cluster1: CoconsCluster, cluster2: CoconsCluster) => {
    if (cluster1.managedBy.id !== cluster2.managedBy.id) { // sort by managedBy
        return cluster1.managedBy.name.localeCompare(cluster2.managedBy.name);
    }
    else { // sort by address
        return cluster1.displayAddress.localeCompare(cluster2.displayAddress, undefined, { numeric: true, sensitivity: 'base' });
    }
}

const compareCocons = (cocon1: Cocon, cocon2: Cocon) => {
    if (cocon1.clusterId !== cocon2.clusterId) { // sort by clusterId
        return cocon1.clusterId.localeCompare(cocon2.clusterId);
    }
    else { // sort by address
        return cocon1.address.localeCompare(cocon2.address, undefined, { numeric: true, sensitivity: 'base' });
    }
}