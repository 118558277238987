import { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import LoginPage from './Login/LoginPage';
import ListCoconsPage from './Cocons/ListCoconsPage';
import CompleteProfilePage from './Users/Profile/CompleteProfilePage';
import VerifyEmailPage from './Users/Profile/VerifyEmailPage';
import WasteReportPage from './WasteReport/WasteReportPage';
import ResetPasswordPage from './Users/Profile/ResetPasswordPage';
import CommunicationMediaPage from './CommunicationMedia/CommunicationMediaPage';
import { StoreInterface } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';
import ShopPage from './Shop/ShopPage';
import SuggestRewardPage from './Shop/AddReward/SuggestRewardPage';
import CoconIssuesList from './Cocons/CoconIssues/CoconIssuesList';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from './Layout/Layout';
import LoggedOutLayout from './Layout/LoggedOutLayout';
import SingleCoconLayout from './SingleCocon/SingleCoconLayout';
import SortingStatsPage from './SingleCocon/Sorting/SortingStatsPage';
import WastesStatsPage from './SingleCocon/Wastes/WastesStatsPage';
import UsersStatsPage from './SingleCocon/Users/UsersStatsPage';
import SummaryPage from './SingleCocon/Summary/SummaryPage';


const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PageProps = ConnectedProps<typeof connector> & {

}

function Pages(props: PageProps) {
    const { } = props;

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname === "/") { // default page is "cocons"
            navigate("/cocons");
        }
    }, [pathname])

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/cocons'>
                    <Route index element={<ListCoconsPage />} />
                    <Route element={<SingleCoconLayout />}>
                        <Route path=':coconId' element={<SummaryPage />} />
                        <Route path=':coconId/sorting' element={<SortingStatsPage />} />
                        <Route path=':coconId/wastes' element={<WastesStatsPage />} />
                        <Route path=':coconId/users' element={<UsersStatsPage />} />
                        <Route path=":coconId/waste-report" element={<WasteReportPage />} />
                        <Route path=":coconId/issues" element={<CoconIssuesList />} />
                    </Route>
                </Route>
                <Route path='/clusters'>
                    <Route element={<SingleCoconLayout />}>
                        <Route path=':clusterId' element={<SummaryPage />} />
                        <Route path=':clusterId/sorting' element={<SortingStatsPage />} />
                        <Route path=':clusterId/wastes' element={<WastesStatsPage />} />
                        <Route path=':clusterId/users' element={<UsersStatsPage />} />
                        <Route path=":clusterId/waste-report" element={<WasteReportPage />} />
                        <Route path=":clusterId/issues" element={<CoconIssuesList />} />
                    </Route>
                </Route>
                <Route path="clusters/:clusterId/issues" element={<CoconIssuesList />} />
                <Route path='/shop' >
                    <Route index element={<ShopPage />} />
                    <Route path='suggest' element={<SuggestRewardPage />} />
                </Route>

                <Route path='/communication' element={<CommunicationMediaPage />} />
            </Route>

            <Route element={<LoggedOutLayout />}>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify-email" element={<VerifyEmailPage />} />
                <Route path="/complete-profile" element={<CompleteProfilePage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
            </Route>
        </Routes>
    );
}

export default connector(Pages);