import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Buffer } from "buffer";

export function isLocalhost() {
    return process.env.FIRESTORE_EMULATOR_HOST?.match("localhost");
}

export type LocalFile = {
    name: string;
    data: Buffer;
}

// Create a new client
const storage = getStorage();

export enum StorageType {
    PARTNERS = "partners",
    TEMPLATES = "templates",
    REPORTS = "reports",
}

const getBucketURL = (name: StorageType) => {
    return `gs://${name}_${process.env.REACT_APP_PROJECT_ID}`;
};

async function downloadFile(bucketName: StorageType, filename: string) {
    const bucketURL = getBucketURL(bucketName);
    const fileRef = ref(storage, `${bucketURL}/${filename}`);
    const downloadURL = await getDownloadURL(fileRef);
    const response = await fetch(downloadURL);
    const buffer = await response.arrayBuffer();
    return Buffer.from(buffer);
}

async function getUrl(bucketName: StorageType, filename: string): Promise<string> {
    const bucketURL = getBucketURL(bucketName);
    const fileRef = ref(storage, `${bucketURL}/${filename}`);
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
}

const Storage = {
    downloadFile,
    getUrl,
};

export default Storage;
