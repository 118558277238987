import { Typography, TypographyProps } from "@mui/material";

export default (props: TypographyProps) => (
    <Typography
        variant="h4"
        sx={{
            ml: 2,
            mb: 2,
        }}
        {...props}
    />
)