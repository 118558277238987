import { Actions, Types } from "constants/actions";
import { UNAUTHORIZED_ERROR } from "constants/api_errors";
import { auth } from "../firebase";

export type Action = {
    action: Actions,
    type: Types,
    payload?: any,
};

export class CodeError extends Error {
    code: number;

    constructor(code: number, message: string) {
        super(message);
        this.code = code;
    }
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit): Promise<any> {
    // retrieve API token
    const user = auth.currentUser;
    let token = await user?.getIdToken();
    if (!token) {
        throw UNAUTHORIZED_ERROR;
    }
    else {
        let headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            ...init?.headers,
        };

        try {
            const res = await fetch(input, {
                ...init,
                headers: headers,
            });
            return await handleAPIResponse(res);
        }
        catch (e) {
            // do common error handling here
            throw e;
        };
    }
}

/**
 * Format body of the response to be readable.
 * Throw an Error to be caught and handled in reducers upon API errors (e.g. 400, 404...)
 * @param res The Response of the request to the API
 */
export const handleAPIResponse = async (res: Response) => {
    try {
        const json = await res.json();
        if (!res.ok) throw new CodeError(res.status, json); // failed request
        return json;                                        // successful request
    }
    catch (e) { // failed parsing json
        const error = e as CodeError;
        console.error(error); // print the error in console
        throw error;
    }
};