import { Box, Card, CardContent, Theme, Typography, } from "@mui/material";
import { ResponsiveStyleValue, SxProps } from "@mui/system";
import SectionLoader from "components/_include/SectionLoader";
import { Namespaces } from "locales/translations";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { DATE_SELECT_TYPES } from "helpers/dates";
import moment from "moment";
import i18next from "i18next";

type CardTemplateProps = {
    title: string;
    loading: boolean;
    currentValue: number;
    previousValue?: number;
    formatValue?: (value: number) => string;
    labelColor?: "light" | "dark",
    color: ((theme: Theme) => ResponsiveStyleValue<any>) | string;
    range?: DATE_SELECT_TYPES;
    from?: moment.Moment;
}

const inlineIconStyling: SxProps = {
    fontSize: "1.3rem",
    ml: .5,
    mb: "-.3rem",
};


function StatCardTemplate(props: CardTemplateProps) {
    const { title, loading, currentValue, previousValue, formatValue, color, labelColor,range, from } = props;

    const labelsColor = labelColor === "dark" ? "rgba(0,0,0,0.8)" : "#fff";

    const selectedRange = {
        today : i18next.t("compared_to.today", { ns: Namespaces.stats }),
        yesterday: i18next.t("compared_to.yesterday", { ns: Namespaces.stats }),
        this_week : i18next.t("compared_to.this_week", { ns: Namespaces.stats }),
        last_week : i18next.t("compared_to.last_week", { ns: Namespaces.stats }),
        last_two_weeks : i18next.t("compared_to.last_two_weeks", { ns: Namespaces.stats }),
        this_month: i18next.t("compared_to.this_month", { ns: Namespaces.stats }),
        last_month : i18next.t("compared_to.last_month", { ns: Namespaces.stats , from : from?.format("Do MMM")}),
        last_three_months: i18next.t("compared_to.last_three_months", { ns: Namespaces.stats, from: from?.format("Do MMM")}),
        this_year : i18next.t("compared_to.this_year", { ns: Namespaces.stats }),
    }

    const renderInfos = (range : DATE_SELECT_TYPES) => {
        switch (range) {
            case DATE_SELECT_TYPES.TODAY : 
                return selectedRange.today;
            case DATE_SELECT_TYPES.YESTERDAY :
                return selectedRange.yesterday;
            case DATE_SELECT_TYPES.THIS_WEEK : 
                return selectedRange.this_week;
            case DATE_SELECT_TYPES.LAST_WEEK :
                return selectedRange.last_week;
            case DATE_SELECT_TYPES.LAST_TWO_WEEKS :
                return selectedRange.last_two_weeks;
            case DATE_SELECT_TYPES.THIS_MONTH :
                return selectedRange.this_month;
            case DATE_SELECT_TYPES.LAST_MONTH :
                return selectedRange.last_month;
            case DATE_SELECT_TYPES.LAST_THREE_MONTHS :
                return selectedRange.last_three_months;
            case DATE_SELECT_TYPES.THIS_YEAR :
                return selectedRange.this_year;
        }
    }

    const evolutionPercent: number | null = previousValue ? (Math.round(currentValue * 100 / previousValue) - 100) : null;

    return (
        <Card
            sx={{
                backgroundColor: color,
                width: "100%",
                minHeight: (theme) => theme.spacing(24),
                display: "flex"
            }}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                        color: labelsColor,
                    }}
                >
                    {title}
                </Typography>
                {loading ? (
                    <SectionLoader />
                ) : (
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                    >

                        <Typography
                            lineHeight={1}
                            fontWeight={400}
                            fontSize="6rem"
                            sx={{
                                color: labelsColor,
                            }}
                        >
                            {formatValue ? formatValue(currentValue) : currentValue}
                        </Typography>

                        {evolutionPercent !== null && (
                            <Typography
                                variant="body2"
                                fontSize="1.1rem"
                                sx={{
                                    ml: 2,
                                    color: labelsColor,
                                    maxWidth: (theme) => theme.spacing(24),
                                }}
                            >
                                <span>
                                    {`${evolutionPercent > 0 ? "+" : "-"} ${Math.abs(evolutionPercent)}%`}
                                    {evolutionPercent > 0 ? (<TrendingUpIcon sx={inlineIconStyling} />) : (<TrendingDownIcon sx={inlineIconStyling} />)}
                                </span>
                                <br />
                                <span>
                                    {range ? renderInfos(range) : ""}
                                </span>
                            </Typography>
                        )}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default StatCardTemplate;