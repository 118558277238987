import moment from "moment";
import _ from "lodash";
import { Locales } from "locales/translations";
import { User as FirebaseUser } from "firebase/auth";

export enum UserRoles {
    BUILDING_MANAGER = "building_manager",
    CONCIERGE = "concierge",
    ADMIN = "admin",
}

export type UserClaims = {
    locale: Locales;
    role: UserRoles;
    cocons?: string[];
}

export type DecodedIdToken = UserClaims & {
    displayName: string,
    userID: string,
}

export type NewUserData = UserClaims & {
    email?: string;
    displayName: string;
}

export type UserData = UserClaims & {
    ID: string;
    email?: string;
    displayName: string;
    creationTime?: string;
    dateJoined: number; // milliseconds timestamp
}

export default class User {
    ID: string;
    email?: string;
    role: UserRoles;
    locale: Locales;     // user's preferred language
    dateJoined: moment.Moment;
    cocons?: string[]; // IDs of managed cocons
    displayName: string;

    public constructor(data: UserData) {
        this.ID = data.ID;
        this.email = data.email;
        this.cocons = data.cocons;
        this.role = data.role;
        this.locale = data.locale;
        this.dateJoined = moment(data.dateJoined, 'x');
        this.displayName = data.displayName;
    }

    public static async fromFirebaseUser(firebaseUser: FirebaseUser): Promise<User> {
        const idToken = await firebaseUser.getIdTokenResult();
        const { claims } = idToken;
        return new User({
            ID: firebaseUser.uid,
            displayName: firebaseUser.displayName || "",
            email: firebaseUser.email || undefined,
            cocons: Array.isArray(claims.cocons) ? claims.cocons : [],
            locale: (claims.locale || Locales.FRENCH) as Locales,
            role: (claims.role || UserRoles.BUILDING_MANAGER) as UserRoles,
            dateJoined: Number(moment(firebaseUser.metadata.creationTime).format('x')),
        });
    }

    public data(fields?: undefined): UserData;
    public data(fields: string[]): Partial<UserData>;
    public data(fields?: string[]) {
        let data: UserData = {
            ID: this.ID,
            email: this.email,
            displayName: this.displayName,
            cocons: this.cocons,
            role: this.role,
            locale: this.locale,
            dateJoined: Number(this.dateJoined.format('x')),
        };

        return fields ? _.pick(data, fields) : data;
    }
}