import urls from "constants/urls";
import { fetchAPI } from "./actions";
import Cocon, { CoconData } from "models/Cocons/Cocon";
import { Actions, Types } from "constants/actions";
import queryString from 'query-string';
import { QueryFilters } from "../constants/types";
import { Dispatch } from "store/store";
import CoconIssue, { CoconIssueData, IssueCoconData, IssueStatus } from "models/Cocons/CoconIssue";
import CoconsCluster, { CoconsClusterData } from "models/Cocons/CoconsCluster";

const coconsEndpoint = `${urls.API.v3}/cocon`;
const coconsClustersEndpoint = `${urls.API.v3}/cocon/cluster`;

const listCoconsClusters = (filters: QueryFilters) => {
    return (dispatch: any) => {
        const action = Actions.LOAD_COCONS_CLUSTERS;
        dispatch({ type: Types.BEGIN, action: action });
        return fetchAPI(`${coconsClustersEndpoint}?${queryString.stringify(filters)}`, {
            method: 'GET',
        })
            .then((clustersData: CoconsClusterData[]) => {
                const clusters = clustersData.map(clusterData => {
                    return new CoconsCluster(clusterData);
                });
                dispatch({ type: Types.SUCCESS, action: action, payload: clusters });
            })
            .catch(error => {
                dispatch({ type: Types.FAIL, action: action, payload: error });
                console.debug("Failed loading cocons:", error);
            });
    };
}

const retrieveCocon = (coconId: string) => {
    return (dispatch: any) => {
        const action = Actions.LOAD_COCON;
        dispatch({ type: Types.BEGIN, action: action });
        return fetchAPI(`${coconsEndpoint}/${coconId}`, {
            method: 'GET',
        })
            .then((coconData: CoconData) => {
                const cocon = new Cocon(coconData);
                dispatch({ type: Types.SUCCESS, action: action, payload: cocon });
            })
            .catch(error => {
                dispatch({ type: Types.FAIL, action: action, payload: error });
                console.debug("Failed loading cocons:", error);
            });
    };
}

const retrieveCluster = (clusterId: string) => {
    return (dispatch: any) => {
        const action = Actions.LOAD_CLUSTER;
        dispatch({ type: Types.BEGIN, action: action });
        return fetchAPI(`${coconsClustersEndpoint}/${clusterId}`, {
            method: 'GET',
        })
            .then((clusterData: CoconsClusterData) => {
                const cluster = new CoconsCluster(clusterData);
                dispatch({ type: Types.SUCCESS, action: action, payload: cluster });
            })
            .catch(error => {
                dispatch({ type: Types.FAIL, action: action, payload: error });
                console.debug("Failed loading cluster:", error);
            });
    };
}

const sendWasteReport = (coconId: string, reportData: FormData) => {
    return (dispatch: any) => {
        const action = Actions.SEND_WASTE_REPORT;
        dispatch({ type: Types.BEGIN, action: action });

        return fetchAPI(`${coconsEndpoint}/${coconId}/waste-report`, {
            method: 'POST',
            headers: {},
            body: reportData,
        })
            .then(() => {
                dispatch({ type: Types.SUCCESS, action: action });
            })
            .catch(error => {
                dispatch({ type: Types.FAIL, action: action, payload: error });
                console.debug("Failed sending report:", error);
            });
    };
};


const loadCoconIssues = (coconId: string) => async (dispatch: Dispatch) => {
    const action = Actions.LOAD_COCON_ISSUES;
    dispatch({ type: Types.BEGIN, action: action });

    try {
        const issuesData: CoconIssueData[] = await fetchAPI(`${coconsEndpoint}/${coconId}/issues`, {
            method: 'GET',
        });

        const issues = issuesData.map(issueData => new CoconIssue(issueData));
        return dispatch({ type: Types.SUCCESS, action: action, payload: issues });
    }
    catch (error) {
        console.error("Failed loading cocon issues", error);
        return dispatch({ type: Types.FAIL, action: action, payload: error });
    }
};

const loadClusterIssues = (clusterId: string) => async (dispatch: Dispatch) => {
    const action = Actions.LOAD_COCON_ISSUES;
    dispatch({ type: Types.BEGIN, action: action });

    try {
        const issuesData: CoconIssueData[] = await fetchAPI(`${coconsClustersEndpoint}/${clusterId}/issues`, {
            method: 'GET',
        });

        const issues = issuesData.map(issueData => new CoconIssue(issueData));
        return dispatch({ type: Types.SUCCESS, action: action, payload: issues });
    }
    catch (error) {
        console.error("Failed loading cocon issues", error);
        return dispatch({ type: Types.FAIL, action: action, payload: error });
    }
};

const updateIssueStatus = (cocon: IssueCoconData, issueId: string, status: IssueStatus.FIXING | IssueStatus.FIXED) => async (dispatch: Dispatch) => {
    const action = Actions.UPDATE_COCON_ISSUE;
    dispatch({ type: Types.BEGIN, action: action, payload: { id: issueId } });

    const endpoint = `${coconsEndpoint}/${cocon.id ? cocon.id : `cluster/${cocon.clusterId}`}/issues/${issueId}`;

    try {
        const issueData: CoconIssueData = await fetchAPI(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: status }),
        });

        const issue = new CoconIssue(issueData);
        return dispatch({ type: Types.SUCCESS, action: action, payload: issue });
    }
    catch (error) {
        console.error("Failed updating cocon issue", error);
        return dispatch({ type: Types.FAIL, action: action, payload: { error: error, id: issueId } });
    }
};

const CoconAction = {
    listCoconsClusters,
    retrieveCocon,
    retrieveCluster,
    sendWasteReport,
    loadCoconIssues,
    loadClusterIssues,
    updateIssueStatus,
};

export default CoconAction;