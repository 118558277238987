import React, { } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Button, Card, CardMedia, Link } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import { CommunicationMedia, generatePDF } from 'helpers/communication_media';
import { Box } from '@mui/material';

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);


type CommunicationMediaCardProps = ConnectedProps<typeof connector> & {
    media: CommunicationMedia;
}

function CommunicationMediaCard(props: CommunicationMediaCardProps) {

    const { media } = props;


    return (
        <Card elevation={10}>
            <CardMedia
                image={media.images[0].url}
                sx={{
                    height: (theme) => theme.spacing(30),
                    position: "relative",
                    transition: (theme) => theme.transitions.create(
                        ['scale'],
                        { duration: theme.transitions.duration.standard }
                    ),
                    "&:hover": {
                        scale: 1.1,
                    }
                }}
            >
                <Link
                    href="#"
                    // target="_blank"
                    onClick={() => generatePDF(media)}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            color="white"
                            variant="outlined"
                            sx={{
                                wordBreak: "break-word",
                                width: "50%",
                                color: "white.main",
                                background: "rgba(255, 255, 255, 0.2)",
                                border: "2px solid #FFFFFF",
                                px: 4,
                                py: 3,
                                fontSize: "1.5rem",
                                "&:hover": {
                                    border: "2px solid #FFFFFF",
                                },
                                "& .MuiButton-label": {
                                    textTransform: "uppercase",
                                },
                            }}
                        >
                            {media.label}
                        </Button>
                    </Box>
                </Link>
            </CardMedia>
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationMediaCard);