import { ResponsiveLine } from '@nivo/line';
import { Box, Card, useTheme, Typography, useMediaQuery } from '@mui/material';
import { ChartsIDs, chartsTheme } from 'constants/charts';
import { StoreInterface } from 'store/reducers';
import { LineChartPointInterface, TimeUnit } from 'store/reducers/stats';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import ChartLoader from './ChartLoader';
import { TrashTypes } from 'constants/trash';
import { useSearchParams } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    data: state.StatsReducer.wastes,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ChartProps = ConnectedProps<typeof connector> & {

};

function SingleWasteEvolutionLineChart(props: ChartProps) {
    let { data, timeUnit, } = props;

    const { t } = useTranslation([Namespaces.stats]);

    const theme = useTheme();

    // responsiveness
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isVerySmall = useMediaQuery(theme.breakpoints.down("xs"));

    const [searchParams] = useSearchParams();
    const wasteType: TrashTypes | null = searchParams.get("waste") as TrashTypes | null;

    const chartData: LineChartPointInterface = {
        id: ChartsIDs.WASTE_EVOLUTION,
        data: wasteType ? data.map(momentStat => ({
            x: momentStat[ChartsIDs.DATES],
            y: momentStat[wasteType],
        })) : []
    };

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <ChartLoader />
            <ResponsiveLine
                data={[chartData]}
                margin={{ top: 8, right: 16, bottom: 120, left: isVerySmall ? 28 : 72 }}
                colors={(line: any) => line.id === ChartsIDs.CO2_SAVED ? theme.palette.secondary.main : theme.palette.primary.main}
                curve='monotoneX'
                enablePoints={true}
                lineWidth={2}
                enableArea={true}
                areaBlendMode="normal"
                areaOpacity={.2}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                theme={chartsTheme(isSmall)}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // orient: 'bottom',
                    tickSize: 8,
                    tickPadding: isVerySmall ? 4 : 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    legend: isSmall ? "" : t(timeUnit, { ns: Namespaces.commons }),
                    legendOffset: 92,
                    legendPosition: 'middle',
                    format: (value: number | string | Date) =>
                        t("date", {
                            ns: Namespaces.stats,
                            context: timeUnit,
                            date: moment(value),
                        }).toString()
                }}
                axisLeft={{
                    // orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                pointSize={6}
                useMesh={true}
                enablePointLabel={false}
                enableGridY={true}
                tooltip={({ point }) => {
                    console.debug("point", point);
                    const date: string | number | Date = point.data.x;
                    const value = Number(point.data.y);
                    return (
                        <Card style={{ paddingTop: 4, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, borderRadius: 2 }}>
                            <Typography variant="caption">
                                {t("date_full", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: date,
                                })}
                            </Typography>
                            <Typography variant="body1">
                                {t(`${wasteType}.count`, { ns: Namespaces.wastes, count: value })}
                            </Typography>
                        </Card>
                    );
                }}
            />
        </Box>
    );
}

export default connect(mapStateToProps)(SingleWasteEvolutionLineChart);