import { useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from 'store/reducers';
import { Box, Container, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Namespaces } from 'locales/translations';
import SectionLoader from '../../_include/SectionLoader';
import CoconAction from 'actions/cocon';
import CoconIssue from 'models/Cocons/CoconIssue';
import { Dispatch } from 'store/store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CoconIssueRow from './CoconIssueRow';
import { Typography } from '@mui/material';
import Animation from 'components/_include/Animation';

const mapStateToProps = (state: StoreInterface) => ({
    issues: state.CoconReducer.coconIssues.data,
    issuesLoading: state.CoconReducer.coconIssues.loading,
    issuesError: state.CoconReducer.coconIssues.error,
    updating: state.CoconReducer.coconIssues.updating,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadCoconIssues: (coconId: string) => dispatch(CoconAction.loadCoconIssues(coconId)),
    loadClusterIssues: (clusterId: string) => dispatch(CoconAction.loadClusterIssues(clusterId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CoconIssuesListProps = ConnectedProps<typeof connector> & {
};

function CoconIssuesList(props: CoconIssuesListProps) {

    const { issues, issuesLoading, updating, loadCoconIssues, loadClusterIssues } = props;

    const { t } = useTranslation([Namespaces.issues]);

    const urlParams = useParams();

    const paramCoconId = urlParams.coconId;
    const paramClusterId = urlParams.clusterId;

    useEffect(() => {
        if (paramCoconId) {
            loadCoconIssues(paramCoconId);
        }
    }, [paramCoconId]);

    useEffect(() => {
        if (paramClusterId) {
            loadClusterIssues(paramClusterId);
        }
    }, [paramClusterId]);

    return (
        <Container>
            {
                issuesLoading ? (
                    <SectionLoader />
                ) : (
                    issues.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{
                                    backgroundColor: 'background.paper',
                                    "& th": {
                                        backgroundColor: 'secondary.main',
                                    }
                                }}>
                                    <TableRow>
                                        <TableCell>{t("picture", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("type", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("description", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("reported_on", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("status", { ns: Namespaces.issues })}</TableCell>
                                        <TableCell>{t("actions", { ns: Namespaces.issues })}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {issues.map((issue: CoconIssue, index: number) => (
                                        <CoconIssueRow
                                            key={index}
                                            issue={issue}
                                            loading={updating.includes(issue.id)}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box
                            sx={{
                                marginTop: 6,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                flexGrow: 1,
                            }}>
                            <Typography
                                variant="h2"
                                textAlign="center"
                            >
                                {t("all_good", { ns: Namespaces.issues, count: paramCoconId ? 1 : 2 })}
                            </Typography>

                            <Animation
                                data={require("animations/cocon_thumb_up.json")}
                                width={200}
                                height={200}
                                bounce={true}
                            />
                        </Box>
                    )
                )}
        </Container>
    )
}

export default connector(CoconIssuesList);