import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';
import SectionLoader from 'components/_include/SectionLoader';
import { Box } from '@mui/material';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
});

const connector = connect(mapStateToProps);

type ChartLoaderProps = ConnectedProps<typeof connector> & {

}

function ChartLoader(props: ChartLoaderProps) {

    const { loading } = props;

    if (!loading) return (null);

    return (
        <Box
            sx={{
                background: "rgba(255,255,255,.5)",
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 99,
            }}
        >
            <SectionLoader />
        </Box>
    );
}

export default connector(ChartLoader);