import moment from 'moment';

export function getCurrentTimestamp() {
    return Number(moment().format('x'));
}

interface DisplayDateOptions {
    day?: boolean;
    month?: boolean;
    year?: boolean;
    time?: boolean;
}

export type DatesInterval = {
    start: moment.Moment | null;
    end: moment.Moment | null;
};

export enum DATE_SELECT_TYPES {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this_week",
    LAST_WEEK = "last_week",
    LAST_TWO_WEEKS = "last_two_weeks",
    THIS_MONTH = "this_month",
    LAST_MONTH = "last_month",
    LAST_THREE_MONTHS = "last_three_months",
    THIS_YEAR = "this_year",
    SINCE_INSTALLATION = "since_installation",
    CUSTOM = "custom",
}

export const DEFAULT_DATE_SELECT_TYPE = DATE_SELECT_TYPES.LAST_THREE_MONTHS;

export type DatesSelect = {
    range?: DATE_SELECT_TYPES;
    from?: moment.Moment;
    to?: moment.Moment;
}

export function getDatesFromSearchParams(searchParams: URLSearchParams): DatesSelect {
    const dateSelectParam = searchParams.get("range");
    const dateSelectType = dateSelectParam ? dateSelectParam as DATE_SELECT_TYPES : undefined;
    const startDateParam = searchParams.get("from");
    const startDate = startDateParam ? moment(startDateParam) : undefined;
    const endDateParam = searchParams.get("to");
    const endDate = endDateParam ? moment(endDateParam) : undefined;

    return {
        range: dateSelectType,
        from: startDate,
        to: endDate,
    };
}

export function getDatesFromDateSelectType(type: DATE_SELECT_TYPES, dateDeployed?: moment.Moment, startDate?: moment.Moment, endDate?: moment.Moment) : { start: moment.Moment, end: moment.Moment } {
    switch (type) {
        case DATE_SELECT_TYPES.TODAY:
            return {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
            };

        case DATE_SELECT_TYPES.YESTERDAY:
            return {
                start: moment().subtract(1, "day").startOf("day"),
                end: moment().subtract(1, "day").endOf("day"),
            };

        case DATE_SELECT_TYPES.THIS_WEEK:
            return {
                start: moment().startOf("week"),
                end: moment().endOf("week"),
            };

        case DATE_SELECT_TYPES.LAST_WEEK:
            return {
                start: moment().subtract(1, "week").startOf("week"),
                end: moment().subtract(1, "week").endOf("week"),
        };

        case DATE_SELECT_TYPES.LAST_TWO_WEEKS:
            return {
                start: moment().subtract(1, "week").startOf("week"),
                end: moment().endOf("week"),
        };

        case DATE_SELECT_TYPES.THIS_MONTH:
            return {
                start: moment().startOf("month"),
                end: moment().endOf("month"),
        };

        case DATE_SELECT_TYPES.LAST_MONTH:
            return {
                start: moment().subtract(1, "month").startOf("month"),
                end: moment().subtract(1, "month").endOf("month"),
        };

        case DATE_SELECT_TYPES.LAST_THREE_MONTHS:
            return {
                start: moment().subtract(3, "month").endOf("week"),
                end: moment().endOf("week"),
            };

        case DATE_SELECT_TYPES.THIS_YEAR:
            return {
                start: moment().startOf("year"),
                end: moment().endOf("year"),
            };
        
        case DATE_SELECT_TYPES.SINCE_INSTALLATION:
            if (dateDeployed) {
                return {
                    start: dateDeployed.startOf("day"),
                    end: moment().endOf("day"),
                };
            }
            break;

        case DATE_SELECT_TYPES.CUSTOM:
            if (startDate && endDate) {
                return {
                    start: startDate,
                    end: endDate,
                }
            }
            break;
    }

    return {
        start: moment().subtract(3, "month").endOf("week"),
        end: moment().endOf("week"),
    };
}