import React from 'react';
import { CircularProgress, Button, Box, } from '@mui/material';
import theme from 'constants/theme';
import { Link } from 'react-router-dom';

interface ActionButtonProps {
    onClick?: React.MouseEventHandler;
    children: any;
    color: "inherit" | "primary" | "secondary" | "gradient" | undefined;
    disabled?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    style?: any;
    icon?: React.ReactNode;
    to?: string;
}

function ActionButton(props: ActionButtonProps) {

    const { children, color, disabled, loading, style, icon, type, to, onClick } = props;

    const buttonDisabled = disabled || loading;

    return (
        <Box
            sx={{
                ...style,
                position: "relative"
            }}
        >
            {to ? // link
                <Link
                    to={to}
                    >
                    <Button
                        onClick={onClick || undefined}
                        variant="contained"
                        disabled={buttonDisabled}
                        type={type}
                        startIcon={icon}
                        sx={{
                            flex: 1,
                            "&:hover": {
                                backgroundPosition: -100,
                            },
                            ...(color === "gradient" && !buttonDisabled ? {
                                color: "white.main",
                                backgroundColor: theme.palette.primary.main,
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
                                backgroundSize: `calc(100% + 100px)`,
                                transition: `background-position .5s`,
                                // "&:hover": { backgroundPosition: -100 },
                            } : { // mui color
                                color: color === "gradient" ? undefined : color
                            })
                        }}
                    >
                        {children}
                    </Button>
                </Link>
                : // simple button
                <Button
                    onClick={onClick || undefined}
                    variant="contained"
                    disabled={buttonDisabled}
                    type={type}
                    startIcon={icon}
                    sx={{
                        flex: 1,
                        "&:hover": {
                            backgroundPosition: -100,
                        },
                        ...(color === "gradient" && !buttonDisabled ? {
                            color: "white.main",
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
                            backgroundSize: `calc(100% + 100px)`,
                            transition: `background-position .5s`,
                        } : { // mui color
                            color: color === "gradient" ? undefined : color
                        }),
                    }}
                >
                    {children}
                </Button>
            }
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -1.5,
                        marginLeft: -1.5,
                    }}
                />
            )}
        </Box>
    );
}

export default ActionButton;