import { Actions, Types } from "constants/actions";
import { Action } from "actions/actions";
import { Namespaces } from "../../locales/translations";
import { BAD_REQUEST_ERROR, FIREBASE_WRONG_PASSWORD, UNAUTHORIZED_ERROR } from "constants/api_errors";
import i18next from "i18next";
import { AlertColor } from "@mui/material";

export interface ActionResultInterface {
    variant: AlertColor;
    message: string;
}

const initialState = {
    variant: "info" as "info",
    message: "",
};

const tContext = { ns: Namespaces.snacks };

/**
 * Used to display snackbars and give feedback to user after an action
 * Do not enter the cases when there is a redirect, pass the `actionResult` message in the localStorage instead
 */
const action_result = (state: ActionResultInterface = initialState, action: Action) => {
    switch (action.type) {
        case Types.BEGIN:

            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.RESET_ACTION_RESULT:
                    return initialState;
                
                case Actions.SUGGEST_REWARD:
                    return {
                        variant: "success",
                        message: i18next.t("suggest_reward.success", { ns: Namespaces.snacks })
                    }
            }
            break;

        case Types.FAIL:
            switch (action.action) {
                case Actions.SIGN_UP:
                    return {
                        variant: "error",
                        message: "Oups, quelque chose n'a pas fonctionné... Réessaye un peu plus tard!"
                    };

                case Actions.SIGN_IN:
                    if (action.payload?.code === BAD_REQUEST_ERROR.code || action.payload?.code === FIREBASE_WRONG_PASSWORD) {
                        return {
                            variant: "error",
                            message: "L'adresse email et le mot de passe ne correspondent pas."
                        };
                    }
                    else {
                        return {
                            variant: "error",
                            message: i18next.t("no_internet", tContext),
                        };
                    }

                case Actions.SIGN_IN_FROM_INVITE:
                    if (action.payload?.code === BAD_REQUEST_ERROR.code || action.payload?.code === FIREBASE_WRONG_PASSWORD) {
                        return {
                            variant: "error",
                            message: i18next.t("login.invitation.error", tContext),
                        };
                    }
                    else {
                        return {
                            variant: "error",
                            message: i18next.t("no_internet", tContext),
                        };
                    }

                default:
                    if (action.payload && action.payload.code === UNAUTHORIZED_ERROR.code) {
                        return {
                            variant: "info",
                            message: i18next.t("session_expired", tContext),
                        };
                    }
                    else if (action.payload.message) { // display error messsage in snackbar
                        return {
                            variant: "error",
                            message: action.payload.message,
                        };
                    }
            }
            break;
    }

    return state;
}

export default action_result;