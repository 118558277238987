import { TrashTypes, NOT_ACCEPTED_TRASH } from "constants/trash";
import { Namespaces } from "locales/translations";
import _ from "lodash";
import { TFunction } from "i18next";

export function listSortableWasteTypes(t: TFunction<Namespaces[]>) {
    return Object.values(TrashTypes)
        .filter(wasteType => !NOT_ACCEPTED_TRASH.includes(wasteType))
        .sort((waste1, waste2) => {
            return t(`${waste1}.label`, { ns: Namespaces.wastes }) > t(`${waste2}.label`, { ns: Namespaces.wastes }) ? 1 : -1
        });
}

export function listNonSortableWasteTypes(t: TFunction<Namespaces[]>) {
    return Object.values(TrashTypes)
        .filter(wasteType => NOT_ACCEPTED_TRASH.includes(wasteType))
        .sort((waste1, waste2) => {
            if (waste1 === TrashTypes.OTHER) return 1; // put "Autres"/"Others" at the end
            if (waste2 === TrashTypes.OTHER) return -1;
            return t(`${waste1}.label`, { ns: Namespaces.wastes }).localeCompare(t(`${waste2}.label`, { ns: Namespaces.wastes }));
        });
}

export type WastesValues = {
    [trashType in TrashTypes]: number;
}

export type TrashCountInterface = WastesValues & {
    TOTAL: number;
};

export const initTrashCount: () => TrashCountInterface = () => ({
    ..._.reduce(Object.values(TrashTypes), (acc, val) => {
        acc[val] = 0;
        return acc;
    }, {} as Partial<WastesValues>) as WastesValues,
    TOTAL: 0,
});