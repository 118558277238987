import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from 'store/reducers';
import { Container } from '@mui/material';
import SectionLoader from '../_include/SectionLoader';
import CoconsCluster from 'models/Cocons/CoconsCluster';
import CoconsClusterSection from './CoconsClusterSection';

const mapStateToProps = (state: StoreInterface) => ({
    coconsClusters: state.CoconReducer.clusters.data,
    coconsLoading: state.CoconReducer.clusters.loading,
    coconsError: state.CoconReducer.clusters.error,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ListCoconsPageProps = ConnectedProps<typeof connector> & {

}


function ListCoconsPage(props: ListCoconsPageProps) {

    const { coconsClusters, coconsLoading } = props;

    return (
        <Container>
            {
                coconsLoading ?
                    <SectionLoader />
                    :
                    coconsClusters.map((cluster: CoconsCluster, index: number) => (
                        <CoconsClusterSection
                            cluster={cluster}
                            key={index}
                            />   
                    ))
            }
        </Container>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCoconsPage);