import moment from "moment";
import Partner, { PartnerData } from "./Partner";
import Store, { StoreData } from "./Store";

export default class Reward {
    id: string;
    name: string;
    description: string;
    imageURL: string;
    partner: Partner;
    category: string;
    tags?: string[];
    cost: number; // cost in Ficha points
    realPrice?: number; // real cost in euros
    promoCode?: string;
    stores: Store[];
    quantity?: number;
    referralLink?: string; // referral link where partner tracks redirects from ficha app

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.category = data.category;
        this.tags = data.tags !== undefined ? data.tags : [];
        this.imageURL = data.imageURL;
        this.cost = data.cost;
        this.realPrice = data.realPrice;
        this.partner = new Partner(data.partner);
        this.promoCode = data.promoCode;
        this.stores = data.stores ? data.stores.map((storeData: StoreData) => { return new Store(storeData); }) : [];
        this.quantity = data.quantity;
        this.referralLink = data.referralLink;
    }

    data(fields?: string[]): RewardData {
        let data: any = {
            id: this.id,
            name: this.name,
            description: this.description,
            category: this.category,
            tags: this.tags,
            imageURL: this.imageURL,
            cost: this.cost,
            realPrice: this.realPrice,
            partner: this.partner.data(),
            stores: this.stores.map(store => { return store.data() }),
            quantity: this.quantity,
            referralLink: this.referralLink,
            promoCode: this.promoCode,
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    };

    getCategoryIconName = () => {
        switch (this.category) {
            case RewardsCategories.E_COMMERCE:
                return "computer"

            case RewardsCategories.PHYSICAL:
                return "pin-drop"

            case RewardsCategories.SENT_BY_FICHA:
                return "mail"
        }
        return "";
    };
}

export interface RewardData {
    id: string;
    name: string;
    description: string;
    category: string;
    tags: string[];
    imageURL: string;
    cost: number;
    realPrice?: number;
    partner: PartnerData;
    promoCode?: string;
    stores?: StoreData[];
    quantity?: number;
    referralLink?: string;
}

export enum RewardsCategories {
    E_COMMERCE = 'e-commerce',
    PHYSICAL = 'physical',
    SENT_BY_FICHA = 'sent_by_ficha',
}

export enum RewardFilter {
    CATEGORY = "category",
    COST_MIN = "costMin",
    COST_MAX = "costMax",
    AFFORDABLE = "affordable",
    NEW = "new",
    LOCATION = "within_km",
    FAVORITES = "favorites",
}

export interface RewardsFilters {
    [RewardFilter.CATEGORY]: RewardsCategories[];
    [RewardFilter.COST_MIN]?: number;
    [RewardFilter.COST_MAX]?: number;
    [RewardFilter.AFFORDABLE]?: boolean;
    [RewardFilter.NEW]?: boolean;
    [RewardFilter.LOCATION]: {
        selected: boolean;
        latitude?: number;
        longitude?: number;
        radius?: number;
    };
    [RewardFilter.FAVORITES]: boolean;
}

export interface RewardFormData {
    partner: string;
    description: string;
    quantity: number | null;
    suggestedCost: number;
    realPrice: number;
    exclusiveCoconsClusters?: string[];
    image: File | null;
}