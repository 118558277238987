import { red, pink, green, amber, orange, deepOrange, purple, brown, grey, indigo, blue, cyan, teal, yellow, lime, deepPurple, blueGrey } from "@mui/material/colors";
import { Theme as ChartsTheme } from "@nivo/core";
import { TrashTypes } from "./trash";

export enum ChartsIDs {
    DATES = 'dates',
    BATCHES_COUNT = 'batches_count',
    USERS_COUNT = 'users_count',
    CO2_SAVED = 'co2_saved',
    CUMULATIVE_CO2_SAVED = 'cumulative_co2_saved',
    WELL_SORTED_TRASH = 'well_sorted',
    BADLY_SORTED_TRASH = 'badly_sorted',
    WASTE_EVOLUTION = 'waste_evolution',
}

export const chartsTheme: (isSmall: boolean) => ChartsTheme = (isSmall) => ({
    axis: {
        ticks: {
            text: {
                fontSize: isSmall ? ".7rem" : ".8rem"
            }
        }
    },
});

export const PatternDefinitions = [
    {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true
    },
    {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: 0,
        lineWidth: 2,
        spacing: 8
    },
    {
        id: 'stripes',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
    },
];

export const WasteChartPatterns = [
    {
        match: { id: TrashTypes.GLASS },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.OTHER },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.MASK },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.COLLECTION_BAG },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.ELECTRONIC_WASTE },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.FOOD_LEFTOVERS },
        id: 'stripes',
    },
    {
        match: { id: TrashTypes.NESTED_ITEMS },
        id: 'stripes',
    }
];

export const WastesChartColors = {
    [TrashTypes.LIGHT_PET_BOTTLE]: red[500],
    [TrashTypes.DARK_PET_BOTTLE]: pink[500],
    [TrashTypes.OPAQUE_PET_BOTTLE]: purple[500],
    [TrashTypes.LIQUID_FOOD_PACKAGING]: deepPurple[500],
    [TrashTypes.PLASTIC_POT]: indigo[500],
    [TrashTypes.PLASTIC_FILM]: blue[500],
    [TrashTypes.BROWN_CARDBOARD]: cyan[500],
    [TrashTypes.PRINTED_CARDBOARD]: blueGrey[500],
    [TrashTypes.GROSS_STORE_PAPER]: teal[500],
    [TrashTypes.PAPER]: green[500],
    [TrashTypes.METAL]: lime[500],
    [TrashTypes.GLASS]: yellow[500],
    [TrashTypes.MASK]: amber[500],
    [TrashTypes.COLLECTION_BAG]: orange[500],
    [TrashTypes.NESTED_ITEMS]: deepOrange[500],
    [TrashTypes.FOOD_LEFTOVERS]: brown[500],
    [TrashTypes.ELECTRONIC_WASTE]: grey[300],
    [TrashTypes.OTHER]: grey[700],
}