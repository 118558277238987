import {  } from '@mui/material';
import IconValueCardTemplate from 'components/_include/Layout/IconValueCardTemplate';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
    rewardsList: state.StatsReducer.data.purchases,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type OrderedRewardsCardProps = ConnectedProps<typeof connector> & {

}

type ItemOccurrences = {
    [key: string]: number;
}

function OrderedRewardsCard(props: OrderedRewardsCardProps) {
    const { rewardsList, loading } = props;

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats]);

    // Get the names of each purchased reward
    let namesList : Array<string> = [] ;
    rewardsList.forEach(rewards => {
       namesList.push(`${rewards.reward.name} (${rewards.reward.partner.name})`);
    });

    // Calculate the occurences of each reward name
    const itemOccurrences = namesList.reduce((occurrences: ItemOccurrences, item) => {
        occurrences[item] = (occurrences[item] || 0) + 1;
        occurrences[item] = (occurrences[item])
        return occurrences;
      }, {});
    
    // Render each reward in a list item
    const itemList = Object.entries(itemOccurrences).map(([item, count]) => (
        <li key={item} style={{padding : 7}}>
          <b>{t("product", { ns: Namespaces.glossary })} :</b> {item} <br></br> <b>{t("quantity", { ns: Namespaces.glossary })} :</b> {count} 
        </li>
      ));
    
    return (
        <IconValueCardTemplate
            text={t("purchased_rewards", { ns: Namespaces.stats, count:rewardsList.length})}
            Icon={CardGiftcardIcon}
            loading={loading}
            value={rewardsList.length}
            color={(theme) => theme.palette.secondary.main}
            swipe={true}
            namesList={itemList}
            />
    );
}

export default connector(OrderedRewardsCard);