import { Coordinates } from "helpers/geo";
import Partner, { PartnerData } from "./Partner";
import Reward, { RewardData } from "./Reward";

export interface StoreData {
    id?: string;
    name: string;
    partner?: PartnerData;
    address: string;
    coordinates: Coordinates;
    rewards: Array<{
        reward: RewardData;
        quantity: number;
    }>;
}

export default class Store {
    id?: string;
    name: string;
    partner?: Partner;
    address: string;
    coordinates: Coordinates;
    rewards: Array<{
        reward: Reward;
        quantity: number;
    }>;

    constructor(data: StoreData) {
        this.id = data.id;
        this.name = data.name;
        this.partner = data.partner ? new Partner(data.partner) : undefined;
        this.address = data.address;
        this.coordinates = data.coordinates;
        this.rewards = data.rewards !== undefined ?
            data.rewards.map((product) => {
                return {
                    reward: new Reward({
                        ...product.reward,
                        partner: data.partner
                    }),
                    quantity: product.quantity,
                }
            })
            : [];
    }

    data(fields?: string[]): StoreData {
        let data: any = {
            id: this.id,
            name: this.name,
            partner: this.partner?.data(),
            address: this.address,
            coordinates: {
                latitude: this.coordinates.latitude,
                longitude: this.coordinates.longitude,
            },
            rewards: this.rewards.map(product => {
                return {
                    reward: product.reward.data(),
                    quantity: product.quantity,
                };
            }),
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    };
}
