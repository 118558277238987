import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from "store/reducers";
import { Box, Divider, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Dispatch } from "store/store";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { NOT_ACCEPTED_TRASH, TrashTypes } from "constants/trash";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { listNonSortableWasteTypes, listSortableWasteTypes } from "helpers/trash";

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type WasteSelectProps = ConnectedProps<typeof connector> & {

}

function WasteSelect(props: WasteSelectProps) {
    const { loading, } = props;

    const { t } = useTranslation([Namespaces.stats, Namespaces.wastes]);

    const sortableWasteTypes = listSortableWasteTypes(t);
    const nonSortableWasteTypes = listNonSortableWasteTypes(t);

    const [searchParams, setSearchParams] = useSearchParams();
    const wasteTypeParam: TrashTypes | null = searchParams.get("waste") as TrashTypes | null;

    const wasteType = wasteTypeParam || sortableWasteTypes[0];

    const handleWasteTypeChanged = (type: TrashTypes) => {
        // update URL search params
        searchParams.set("waste", type);
        setSearchParams(searchParams, { replace: true} );
    };

    return (
        <FormControl
            variant="outlined"
            disabled={loading}
            sx={{
                textAlign: "center",
                margin: 0,
                width: "100%",
                maxWidth: "none",
            }}
        >
            <InputLabel id="waste-select-label">
                {t("trash_type", { ns: Namespaces.stats })}
            </InputLabel>
            <Select
                labelId="waste-select-label"
                label={t("trash_type", { ns: Namespaces.stats })}
                value={wasteType}
                onChange={(e) => handleWasteTypeChanged(e.target.value as TrashTypes)}
                variant="outlined"
                renderValue={(type) => t(`${type}.label`, { ns: Namespaces.wastes })}
            >
                {sortableWasteTypes.map(type => (
                    <MenuItem
                        key={type}
                        value={type}
                        sx={{
                            color: theme => theme.palette.success.light,
                        }}
                    >
                        <ListItemText>
                            {t(`${type}.label`, { ns: Namespaces.wastes })}
                        </ListItemText>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                    </MenuItem>
                ))}
                <Divider key="divider" />
                {nonSortableWasteTypes.map(type => (
                    <MenuItem
                        key={type}
                        value={type}
                        sx={{
                            color: theme => theme.palette.error.light,
                        }}
                    >
                        <ListItemText>
                            {t(`${type}.label`, { ns: Namespaces.wastes })}
                        </ListItemText>
                        <ListItemIcon>
                            <ErrorOutlineIcon fontSize="small" />
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    );
}

export default connector(WasteSelect);