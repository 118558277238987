import { Actions, Types } from "constants/actions";
import { Action, CodeError } from "actions/actions";
import moment from "moment";
import { ChartsIDs } from 'constants/charts';
import { NOT_ACCEPTED_TRASH, TrashTypes } from "constants/trash";
import CoconIssue, { IssueStatus } from "models/Cocons/CoconIssue";
import i18next from "i18next";
import { Namespaces } from "locales/translations";
import _ from "lodash";
import { initTrashCount, TrashCountInterface, WastesValues } from "helpers/trash";
import { RewardData } from "models/Shop/Reward";
import User, { UserData } from "models/User";

// export interface BatchesByUserInterface {
//     [userPin: string]: {
//         [date: string]: number,
//     },
// };

export type TimeUnit = "day" | "week" | "month";

type ActiveUsersStat = {
    count: number,
    withMistakes: number,
};

/**
 * Structure of the items sent as the `batches` field in the response from the stats API endpoint.
 * Each item corresponds to a given date, and items have dates at regular interval, in order to be displayed in charts.
 */
export interface MomentStats {
    date: number;
    /** Number of batches between this date and the next one. */
    batches: number;
    /** Counts of different users that threw at least 1 batch, with errors and regardless, between this date and the next one. */
    activeUsers: ActiveUsersStat;
    /** Amount of CO2 saved between this date and the next one. */
    co2_saved: number;
    /** Recorded fill rates (1 for each batch) between this date and the next one. */
    fillRates: number[];
    /** Average quality of the content of the bin between this date and the next one. */
    quality: number;
    timeUnit: TimeUnit;
    /** Counts of instances of each class of waste between this date and the next one. */
    wastes: TrashCountInterface;
}

export interface IssuesInterface {
    [IssueStatus.REPORTED]: number,
    [IssueStatus.FIXING]: number,
    [IssueStatus.FIXED]: number,
};

type UserPurchaseData = {
    lastName: string,
    firstName: string,
    address: Map<string,string>,
    pin: number,
    locale: string,
    email: string,
}

export enum PurchaseStatus {
    PURCHASED = 'purchased',
    SENT = 'sent',
    RECEIVED = 'received',
}


export type RewardsList = {
    id:string,
    reward: RewardData,
    status: string,
    timeline:Record<PurchaseStatus,string>,
    user: UserPurchaseData,
}

/**
 * Structure of the full object sent as a response from the stats API endpoint.
 */
export type StatsInterface = {
    batches: MomentStats[];
    trashes: TrashCountInterface;
    timeUnit: TimeUnit;
    issues: IssuesInterface;
    purchases: Array<RewardsList>;
};

export interface BatchesChartBarInterface {
    [ChartsIDs.DATES]: Date;
    [ChartsIDs.BATCHES_COUNT]: number;
}

export interface UsersChartBarInterface {
    [ChartsIDs.DATES]: Date;
    [ChartsIDs.USERS_COUNT]: number;
}

export type WastesChartBarInterface = {
    [ChartsIDs.DATES]: Date;
} & WastesValues;

export type DatedValue = {
    x: Date;
    y: number;
};

export interface LineChartPointInterface {
    id: string;
    data: Array<DatedValue>;
}

export interface PieChartSliceInterface {
    id: string | number,
    value: number,
    label: string,
    [key: string]: string | number
}

export type WeeklySummary = {
    batchesCount: {
        current: number;
        previous: number;
    };
    activeUsers: {
        current: number;
        previous: number;
    };
    sortingQuality: {
        current: number;
        previous: number;
    };
    errors: { type: TrashTypes, count: number }[];
    issues: CoconIssue[];
}

export interface StatsContextInterface {
    data: StatsInterface;
    loading: boolean;
    error: CodeError | null;
    batches: BatchesChartBarInterface[];
    wastes: WastesChartBarInterface[];
    activeUsers: UsersChartBarInterface[];
    mistakenUsers: UsersChartBarInterface[];
    co2_saved: LineChartPointInterface[];
    fillRates: DatedValue[];
    quality: LineChartPointInterface[];
    trashes: PieChartSliceInterface[];
    unsortableTrash: PieChartSliceInterface[];
    totalTrashes: number;
    totalBatches: number;
    totalUnsortableBatches: number;
    coconWeeklySummary: WeeklySummary;
}

const initialState: StatsContextInterface = {
    data: {
        batches: [],
        trashes: initTrashCount(),
        timeUnit: "week" as TimeUnit,
        issues: {
            [IssueStatus.REPORTED]: 0,
            [IssueStatus.FIXED]: 0,
            [IssueStatus.FIXING]: 0,
        },
        purchases: []
    },
    loading: false,
    error: null,
    batches: [],
    activeUsers: [],
    mistakenUsers: [],
    wastes: [],
    co2_saved: [],
    fillRates: [],
    quality: [],
    trashes: [],
    unsortableTrash: [],
    totalTrashes: 0,
    totalBatches: 0,
    totalUnsortableBatches:0,
    coconWeeklySummary: {
        batchesCount: {
            current: 0,
            previous: 0,
        },
        activeUsers: {
            current: 0,
            previous: 0,
        },
        sortingQuality: {
            current: 0,
            previous: 0,
        },
        errors: [],
        issues: [],
    }
};

function StatsReducer(state: StatsContextInterface = initialState, action: Action): StatsContextInterface {

    let stats: StatsInterface;

    switch (action.type) {
        case Types.BEGIN:
            switch (action.action) {
                case Actions.LOAD_STATS:
                    return {
                        ...state,
                        loading: true,
                        error: null,
                    };

                case Actions.LOAD_COCON_SUMMARY:
                    return {
                        ...state,
                        loading: true,
                        error: null,
                    };

                case Actions.LOAD_CLUSTER_SUMMARY:
                    return {
                        ...state,
                        loading: true, 
                        error: null,
                    };
            }
            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.LOAD_STATS:
                    stats = action.payload;
                    let nbBatches = 0;
                    stats.batches.forEach(momentBatches => {
                        nbBatches += momentBatches.batches;
                    });
                    let nbUnsortableWaste = 0;
                    stats.batches.forEach(momentBatches => {
                        if (momentBatches.quality != 1) {
                           if (momentBatches.wastes.collection_bag != 0 || momentBatches.wastes.electronic_waste != 0 || momentBatches.wastes.food_leftovers != 0 || momentBatches.wastes.glass !=0 || momentBatches.wastes.mask!=0){
                            nbUnsortableWaste +=  momentBatches.wastes.collection_bag + momentBatches.wastes.electronic_waste + momentBatches.wastes.food_leftovers + momentBatches.wastes.glass + momentBatches.wastes.mask ;
                           }
                        }
                    })


                    return {
                        ...state,
                        loading: false,
                        data: action.payload,
                        ...formatMomentStatsForDislay(stats.batches),
                        trashes: formatTrashesStatsForDisplay(stats.trashes),
                        unsortableTrash: filterUnsortableTrash(stats.trashes),
                        totalTrashes: stats.trashes.TOTAL,
                        totalBatches: nbBatches,
                        totalUnsortableBatches: nbUnsortableWaste,
                    };

                case Actions.LOAD_COCON_SUMMARY:
                    return {
                        ...state,
                        loading: false,
                        coconWeeklySummary: action.payload,
                    };
                case Actions.LOAD_CLUSTER_SUMMARY:
                    return {
                        ...state,
                        loading: false,
                        coconWeeklySummary: action.payload,
                    }
            }
            break;

        case Types.FAIL:
            switch (action.action) {
                case Actions.LOAD_STATS:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };

                case Actions.LOAD_COCON_SUMMARY:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };
                
                case Actions.LOAD_CLUSTER_SUMMARY:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    }
            }
            break;
    }

    return state;
}

function formatMomentStatsForDislay(momentStats: MomentStats[]) {
    let batchesStats: BatchesChartBarInterface[] = [];
    let activeUsersStats: UsersChartBarInterface[] = [];
    let mistakenUsersStats: UsersChartBarInterface[] = [];
    let wastesStats: WastesChartBarInterface[] = [];
    let momentCO2Data = Array<DatedValue>();
    let totalCO2Data = Array<DatedValue>();
    let totalCO2 = 0;
    let fillRates = Array<DatedValue>();
    let momentWellSorted = Array<DatedValue>();

    momentStats.forEach(momentBatches => {
        const date = moment(momentBatches.date, 'x').toDate();
        // batches
        batchesStats.push({
            [ChartsIDs.DATES]: date,
            [ChartsIDs.BATCHES_COUNT]: momentBatches.batches,
        });
        // users
        activeUsersStats.push({
            [ChartsIDs.DATES]: date,
            [ChartsIDs.USERS_COUNT]: momentBatches.activeUsers.count,
        });
        mistakenUsersStats.push({
            [ChartsIDs.DATES]: date,
            [ChartsIDs.USERS_COUNT]: momentBatches.activeUsers.withMistakes,
        });
        // wastes
        wastesStats.push({
            [ChartsIDs.DATES]: date,
            ..._.omit(momentBatches.wastes, "TOTAL"),
        });
        // CO2
        const momentCO2 = Math.round(momentBatches.co2_saved * 100) / 100;
        totalCO2 += momentCO2;
        momentCO2Data.push({
            x: date,
            y: momentCO2,
        });
        totalCO2Data.push({
            x: date,
            y: totalCO2,
        });
        // fill rates
        momentBatches.fillRates.forEach(fillRate => {
            fillRates.push({
                x: date,
                y: fillRate,
            });
        })
        // quality
        const wellSorted = Math.round(momentBatches.quality * 100);
        momentWellSorted.push({
            x: date,
            y: wellSorted,
        });
    });

    return {
        batches: batchesStats,
        activeUsers: activeUsersStats,
        mistakenUsers: mistakenUsersStats,
        wastes: wastesStats,
        co2_saved: [
            {
                id: ChartsIDs.CO2_SAVED,
                data: momentCO2Data,
            },
            {
                id: ChartsIDs.CUMULATIVE_CO2_SAVED,
                data: totalCO2Data,
            },
        ],
        fillRates,
        quality: [
            {
                id: ChartsIDs.WELL_SORTED_TRASH,
                data: momentWellSorted,
            },
        ],
    };
}

function formatTrashesStatsForDisplay(trashes: TrashCountInterface): PieChartSliceInterface[] {
    const totalTrashes = trashes.TOTAL;
    return Object.keys(trashes)
        .filter(trashType => { return trashType !== "TOTAL" })
        .filter(trashType => trashes[trashType as TrashTypes] > 0)
        .map(trashType => {
            const trash = trashType as TrashTypes;
            const trashName = i18next.t(`${trash}.label`, { ns: Namespaces.wastes });
            return {
                id: trashType,
                label: trashName,
                value: totalTrashes > 0 ? Math.round(trashes[trash] / totalTrashes * 100) : 0,
            }
        });
}

function filterUnsortableTrash(trashes: TrashCountInterface) : PieChartSliceInterface[]{
    const totalUnsorableTrash = trashes.collection_bag + trashes.electronic_waste + trashes.food_leftovers + trashes.glass + trashes.mask;
    return Object.keys(trashes)
        .filter(trashType => { return trashType !== "TOTAL" })
        .filter(trashType => trashes[trashType as TrashTypes] > 0)
        .map(trashType => {
            const trash = trashType as TrashTypes;
            const trashName = i18next.t(`${trash}.label`, { ns: Namespaces.wastes });
            return {
                id: trashType,
                label: trashName,
                value: totalUnsorableTrash > 0 ? Math.round(trashes[trash] / totalUnsorableTrash * 100) : 0,
            }
        })
        .filter((trash)=> NOT_ACCEPTED_TRASH.includes(trash.id as TrashTypes));
    
}

export default StatsReducer;