import { Box, Card, CardContent, Grid, SvgIconTypeMap, Theme, Typography, Paper, Slide } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import SectionLoader from "components/_include/SectionLoader";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

type CardTemplateProps = {
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    text: string;
    loading: boolean;
    value: number;
    labelColor?: "light" | "dark",
    color: ((theme: Theme) => ResponsiveStyleValue<any>) | string;
    swipe: boolean;
    namesList?: JSX.Element[];
}

function IconValueCardTemplate(props: CardTemplateProps) {
    const [checked, setChecked] = React.useState(false);
    const containerRef = React.useRef(null);
    const [isHovering, setIsHovering] = React.useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const { text, Icon, loading, value, color, labelColor, swipe, namesList } = props;
    const labelsColor = labelColor === "dark" ? "rgba(0,0,0,0.8)" : "#fff";

    return (
        <Box
            ref={containerRef}
            overflow="hidden"
            px={1}
        >
            <div onClick={handleChange}>
                <Card
                    style={{ cursor: isHovering ? 'pointer' : 'default' }}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    elevation={isHovering ? 5 : 4 }
                    sx={{
                        backgroundColor: isHovering ? color : color,
                        width: "100%",
                        minHeight: (theme) => theme.spacing(24),
                        display: "flex",
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            flexGrow: 1,
                        }}
                    >
                        {loading ? (
                            <SectionLoader />
                        ) : (
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Icon
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            maxHeight: (theme) => theme.spacing(12),
                                            color: labelsColor,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography
                                                lineHeight={1}
                                                fontWeight={400}
                                                fontSize="6rem"
                                                sx={{
                                                    color: labelsColor,
                                                }}
                                            >
                                                {value}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                fontSize="1.1rem"
                                                sx={{
                                                    color: labelsColor,
                                                    ml: .5,
                                                }}
                                            >
                                                {text}
                                            </Typography>
                                        </Grid>
                                        {swipe === true && checked ?
                                            <Grid item xs={12} top={15}>
                                                <ArrowDropUpIcon sx={{
                                                    maxHeight: (theme) => theme.spacing(5),
                                                    color: labelsColor,
                                                }}
                                                >
                                                </ArrowDropUpIcon>
                                            </Grid> :
                                            <Grid item xs={12} top={15}>
                                                <ArrowDropDownIcon sx={{
                                                    maxHeight: (theme) => theme.spacing(5),
                                                    color: labelsColor,
                                                }}
                                                >
                                                </ArrowDropDownIcon>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </div>
            <Slide direction="down" in={checked} container={containerRef.current}>
                <Paper 
                    sx={{ 
                        position: "relative",
                        width: "100%",
                        zIndex: 1,
                    }} 
                    elevation={4} 
                    >
                    <Box component="ul" sx={{ padding: 2, marginLeft: 4 }}>
                        {namesList}
                    </Box>
                </Paper>
            </Slide>
        </Box>
    );
}

export default IconValueCardTemplate;