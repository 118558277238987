import moment from "moment";
import User, { UserData } from "../User";
import Cocon, { CoconData } from "./Cocon";
import _ from "lodash";

export enum IssueType {
    BULKY_ITEM = "bulky_item",
    ILLEGAL_DUMP = "illegal_dump",
    ISSUE_ON_COCON = "issue_on_cocon",
    SUGGESTION = "suggestion",
}

export enum IssueStatus {
    REPORTED = "reported",
    FIXING = "fixing",
    FIXED = "fixed",
}

export type IssueCoconData = {
    clusterId: string;
    id?: string;
    address: string;
};

export interface CoconIssueData {
    id: string;
    cocon: IssueCoconData;
    reportedBy: UserData;
    type: IssueType;
    description: string;
    status: IssueStatus;
    date: number; // ms timestamp
    pictureURL?: string;
}

export default class CoconIssue {
    id: string;
    cocon: IssueCoconData;
    reportedBy: User;
    type: IssueType;
    description: string;
    status: IssueStatus;
    date: moment.Moment;
    pictureURL?: string;

    constructor(data: CoconIssueData) {
        this.id = data.id;
        this.cocon = data.cocon;
        this.reportedBy = new User(data.reportedBy);
        this.type = data.type;
        this.description = data.description;
        this.status = data.status;
        this.date = moment(data.date);
        this.pictureURL = data.pictureURL;
    }

    public data(fields?: undefined): CoconIssueData;
    public data(fields: string[]): Partial<CoconIssueData>;
    public data(fields?: string[]) {
        const data: CoconIssueData = {
            id: this.id,
            cocon: this.cocon,
            reportedBy: this.reportedBy.data(),
            type: this.type,
            description: this.description,
            status: this.status,
            date: Number(this.date.format('x')),
            pictureURL: this.pictureURL,
        };

        const sanitizedData = _.omitBy(data, _.isUndefined);

        return fields ? _.pick(sanitizedData, fields) : sanitizedData;
    }
}