import { useState, FormEvent } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { Button, TextField, Typography, Theme, Box, Alert } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import UserAction from 'actions/user';
import ActionButton from 'components/_include/ActionButton';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { Namespaces } from '../../../locales/translations';
import { usePrevious } from 'helpers/refs';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: StoreInterface) => ({
    user: state.UserReducer.user,
    userLoading: state.UserReducer.loading,
    userError: state.UserReducer.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    completeProfile: (token: string, addressData: AddressData) => dispatch(UserAction.completeProfile(token, addressData)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CompleteProfilePageProps = ConnectedProps<typeof connector> & {

}

interface AddressDataInputs {
    street_address: {
        value: string,
        error: string | null
    },
    address_complement: {
        value: string,
        error: string | null
    },
    city: {
        value: string,
        error: string | null
    },
    postcode: {
        value: string,
        error: string | null
    },
}

interface AddressData {
    street_address: string,
    address_complement: string,
    city: string,
    postcode: string,
}

function CompleteProfilePage(props: CompleteProfilePageProps) {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("t");

    const { userLoading, userError } = props;
    const wasLoading = usePrevious(userLoading);

    const { t } = useTranslation([Namespaces.actions, Namespaces.snacks, ])

    const [inputs, setInputs] = useState<AddressDataInputs>({
        street_address: {
            value: "",
            error: null
        },
        address_complement: {
            value: "",
            error: null
        },
        city: {
            value: "",
            error: null
        },
        postcode: {
            value: "",
            error: null
        },
    });

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        if (!token) return;

        let { street_address, address_complement, city, postcode } = inputs;
        let error = false;

        if (!street_address.value) {
            error = true;
            street_address.error = "";
        }

        if (!city.value) {
            error = true;
            city.error = "";
        }

        if (!postcode.value) {
            error = true;
            postcode.error = "";
        }

        if (error) {
            setInputs({
                street_address: street_address,
                address_complement: address_complement,
                city: city,
                postcode: postcode,
            });
        }
        else {
            props.completeProfile(token.toString(), {
                street_address: street_address.value,
                address_complement: address_complement.value,
                city: city.value,
                postcode: postcode.value,
            });
        }
    }

    const { street_address, address_complement, city, postcode } = inputs;
    const formIsValid = street_address.value.length && city.value.length && postcode.value.length;

    if (!token) {
        return (
            <Box>
                <Alert severity="error" >
                    <Typography variant="body1" >
                        {t("invalid_link", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
                <BackToWebsiteLink />
            </Box>
        );
    }

    if (wasLoading && !userLoading && !userError) { // request completed successfully
        return (
            <Box>
                <Alert severity="success" >
                    <Typography variant="body1" >
                        {t("complete_profile.success", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h1" sx={{ mt: 0 }}>
                Complète ton profil
            </Typography>
            <form
                style={{ marginTop: 40 }}
                autoComplete="on"
                method="post"
                action="#"
            >
                {userError && (
                    <Alert severity="error" >
                        <Typography variant="body1" >
                            {t("unknown_error", { ns: Namespaces.snacks })}
                        </Typography>
                    </Alert>
                )}

                <TextField
                    id="street_address"
                    label="Adresse (numéro, voie...)"
                    value={street_address.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('street_address', event.target.value) }
                    }}
                    error={Boolean(street_address.error)}
                    helperText={street_address.error}
                    required
                />

                <br />

                <TextField
                    id="address_complement"
                    label="Étage, appartement..."
                    value={address_complement.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('address_complement', event.target.value) }
                    }}
                    error={Boolean(address_complement.error)}
                    helperText={address_complement.error}
                />

                <TextField
                    id="postcode"
                    label="Code postal"
                    value={postcode.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('postcode', event.target.value) }
                    }}
                    error={Boolean(postcode.error)}
                    helperText={postcode.error}
                    required
                />

                <TextField
                    id="city"
                    label="Ville"
                    value={city.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('city', event.target.value) }
                    }}
                    error={Boolean(city.error)}
                    helperText={city.error}
                    required
                />

                <Box textAlign="center">
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={userLoading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {t("update", { ns: Namespaces.actions })}
                    </ActionButton>
                    {/* <Divider className={classes.buttonsDivider} />
                    <Button
                        className={`${classes.actionButton} ${classes.registerButton}`}
                        color="primary"
                        variant="outlined"
                        href="/register/" >
                        Créer un compte
                    </Button> */}
                </Box>
            </form>
        </Box>
    )
}

export default connector(CompleteProfilePage);