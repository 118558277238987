import { connect } from 'react-redux';
import { Box, Button, Card, CardActions, CardContent, Divider, Paper, Typography, useTheme } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import Cocon from 'models/Cocons/Cocon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import CoconsCluster from 'models/Cocons/CoconsCluster';

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

interface CoconCardProps {
    cocon: Cocon;
    cocons_cluster: CoconsCluster;
}

function CoconCard(props: CoconCardProps) {

    const { cocon, cocons_cluster } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.glossary, Namespaces.issues]);

    const theme = useTheme();

    return (
        <Card>
            <Link
                to={cocon.id}
                style={{
                    padding: theme.spacing(4),
                    paddingRight: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    color: "inherit",
                    textDecoration: "inherit",
                    cursor: "pointer",
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        maxWidth: "calc(100% - 24px)",
                    }}
                    >
                    <Typography
                        noWrap
                        fontWeight={500}
                        >
                        {cocon.getAddress()}
                    </Typography>
                    {cocon.unitsCount && (
                        <Typography>
                            {t("units_count", { ns: Namespaces.glossary, count: cocon.unitsCount })}
                        </Typography>
                    )}
                    <Typography>
                        {t("batches_count", { ns: Namespaces.glossary, count: cocon.batchesCount })}
                    </Typography>
                    <Typography 
                        variant="subtitle2"
                        mt={1}
                        fontStyle="italic"
                        >
                        {t("managed_by", { ns: Namespaces.glossary, name: cocons_cluster.managedBy.name })}
                    </Typography>
                </Box>
                <ChevronRightIcon />
            </Link>
            {cocon.pendingIssues > 0 && (
                <div>
                    <Divider
                        light
                    />
                    <Link
                        to={`${cocon.id}/issues`}
                        style={{
                            padding: theme.spacing(1),
                            cursor: "pointer",
                            display: "flex",
                            textDecoration: "inherit",
                        }}>
                        <Button color="error">
                            {t("pending_issue", { ns: Namespaces.issues, count: cocon.pendingIssues })}
                        </Button>
                    </Link>
                </div>
            )}
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CoconCard);