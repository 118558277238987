import { Divider, DividerProps, } from "@mui/material";

type ItemsDividerProps = DividerProps & {
    spacing: number;
    verticalInset: number;
}

export default (props: ItemsDividerProps) => {
    const { verticalInset, spacing, ...others } = props;
    return (
        <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
                ml: (theme) => `calc(${theme.spacing(spacing)} - 1px)`,
                mr: -spacing,
                mb: verticalInset,
                mt: verticalInset + spacing * 2,
            }}
            {...others}
    />
    )
}   