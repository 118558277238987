import StatCardTemplate from 'components/_include/Layout/StatCardTemplate';
import { getDatesFromSearchParams } from 'helpers/dates';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { StoreInterface } from 'store/reducers';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
    quality: state.StatsReducer.coconWeeklySummary.sortingQuality,
});

const connector = connect(mapStateToProps);

type WeeklyQualityCardProps = ConnectedProps<typeof connector> & {

}

function WeeklyQualityCard(props: WeeklyQualityCardProps) {
    const { quality, loading } = props;

    const [searchParams] = useSearchParams();
    const { range, from } = getDatesFromSearchParams(searchParams);

    const { t } = useTranslation([Namespaces.stats]);

    return (
        <StatCardTemplate
            title={t("summary.sorting_quality", { ns: Namespaces.stats })}
            loading={loading}
            currentValue={quality.current}
            previousValue={quality.previous}
            formatValue={(value) => `${Math.floor(value * 100)}%`}
            color={(theme) => theme.palette.primary.main}
            range={range}
            from={from}
            />
    );
}

export default connector(WeeklyQualityCard);