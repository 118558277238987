import { StoreInterface } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Container, Grid, Box, } from '@mui/material';
import SectionLoader from "components/_include/SectionLoader";
import NameAndSelectHeader from "../NameAndSelectHeader";
import CoconStatsFilters from "../CoconStatsFilters";
import WastePieChart from "components/Statistics/Charts/WastePieChart";
import ChartPaperWrapper from "components/Statistics/ChartPaperWrapper";
import WastesEvolutionBarChart from "components/Statistics/Charts/WastesEvolutionBarChart";
import ChartTitle from "components/Statistics/Charts/ChartTitle";
import SingleWasteEvolutionLineChart from "components/Statistics/Charts/SingleWasteEvolutionLineChart";
import WasteSelect from "components/_include/Filters/WasteSelect";
import React, { useState } from 'react';

const mapStateToProps = (state: StoreInterface) => ({
    coconLoading: state.CoconReducer.selectedCocon.loading,
});

const mapDispatchToProps = () => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type WastesStatsProps = ConnectedProps<typeof connector> & {

}

function WastesStatsPage(props: WastesStatsProps) {

    const { t } = useTranslation([Namespaces.stats, Namespaces.titles,])

    const [chartTitleKey, setChartTitleKey] = useState<string>("trashes_count_evolution");

    function handleChildButtonClick() {
        if (chartTitleKey === "trashes_count_evolution") {
            setChartTitleKey("trashes_error_evolution");
        } else {
            setChartTitleKey("trashes_count_evolution");
        }
    }
    const { coconLoading, } = props;

    return (
        coconLoading ? (
            <SectionLoader />
        ) : (
            <Container>
                <NameAndSelectHeader />

                <CoconStatsFilters />

                <Grid container sx={{ height: "90%", }} spacing={4}>
                    <ChartPaperWrapper>
                        <ChartTitle>
                            {t("trashes_types", { ns: Namespaces.stats })}
                        </ChartTitle>
                        <WastePieChart />
                    </ChartPaperWrapper>

                    <ChartPaperWrapper>
                        <ChartTitle>
                            {t(chartTitleKey, { ns: Namespaces.stats })}
                        </ChartTitle>
                        <WastesEvolutionBarChart onButtonClick={handleChildButtonClick} />
                    </ChartPaperWrapper>

                    <ChartPaperWrapper
                        lg={12}
                        PaperProps={{
                            sx: {
                                height: theme => theme.spacing(60),
                            }
                        }}>
                        <Box display="flex" mb={2}>
                            <Box flexGrow={1}>
                                <ChartTitle>
                                    {t("trashes_count_evolution", { ns: Namespaces.stats })}
                                </ChartTitle>
                            </Box>
                            <Box width={300}>
                                <WasteSelect />
                            </Box>
                        </Box>
                        <SingleWasteEvolutionLineChart />
                    </ChartPaperWrapper>
                </Grid>
            </Container>
        )

    )
};

export default connector(WastesStatsPage);