import { createStore, applyMiddleware } from "redux";
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';
import { Action } from "actions/actions";

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export type Dispatch = React.Dispatch<Action>;

export default store;