import { fetchAPI } from 'actions/actions';
import Reward, { RewardData, RewardFilter, RewardsCategories, RewardsFilters } from 'models/Shop/Reward';
import { Actions, Types } from 'constants/actions';
import queryString from 'query-string';
import moment from 'moment';
import store from '../store/store';
import urls from 'constants/urls';
import { QueryFilters } from 'constants/types';

interface RewardsList {
  results: RewardData[];
  count: number;
  next?: string;
}

const listRewards = (filters: RewardsFilters, previewFilters: boolean) => {
  return (dispatch: any) => {
    const action = previewFilters ? Actions.PREVIEW_REWARDS_WITH_FILTERS : Actions.LOAD_REWARDS;
    const requestId = Date.now();
    dispatch({ action: action, type: Types.BEGIN, payload: { requestId: requestId, filters: filters } });

    const queryFilters: QueryFilters = {
      quantity: [">", 0],
      orderBy: "random",
      limit: 24 // multiple of 2 and 3 for perfect grid display
    };
    Object.entries(filters).forEach(([filter, value]) => {
      if (value !== undefined) {
        switch (filter as RewardFilter) {
          case RewardFilter.AFFORDABLE: 
            //ignore
            break;

          case RewardFilter.NEW:
            if (value == true) queryFilters.dateAdded = [">=", Number(moment().subtract('30', 'days').format('x'))];
            break;

          case RewardFilter.LOCATION:
            if (filters[RewardFilter.LOCATION].selected && filters[RewardFilter.CATEGORY].indexOf(RewardsCategories.PHYSICAL) >= 0) { 
              // only take distance into account if physical rewards selected 
              queryFilters.latitude = value.latitude;
              queryFilters.longitude = value.longitude;
              queryFilters.radius = value.radius;
            }
            break;

          case RewardFilter.FAVORITES:
            if (value) queryFilters[filter] = value;
            break;

          default:
            queryFilters[filter] = value;
            break;
        }
      }
    });

    const url = `${urls.API.v4}/reward?${queryString.stringify(queryFilters)}`;

    console.debug("loading rewards", url);

    return fetchAPI(url, {
      method: 'GET',
    })
      .then((body: RewardsList) => {
        let rewards = body.results.map(rewardData => {
          return new Reward(rewardData);
        });

        // shuffleArray(rewards);

        dispatch({ action: action, type: Types.SUCCESS, payload: { data: rewards, totalCount: body.count, requestId: requestId, next: body.next } });
        return rewards;
      })
      .catch(error => {
        dispatch({ action: action, type: Types.FAIL, payload: error });
        console.debug("Failed loading rewards:", error);
        return [];
      });
  };
}

const loadMoreRewards = (queryFilters: string) => {
  return (dispatch: any) => {
    const action = Actions.LOAD_MORE_REWARDS;
    const requestId = Date.now();
    dispatch({ action: action, type: Types.BEGIN, payload: { requestId: requestId, } });

    const url = `${urls.API.v4}/reward${queryFilters}`;

    console.debug("loading more rewards", url);

    return fetchAPI(url, {
      method: 'GET',
    })
      .then((body: RewardsList) => {
        let rewards = body.results.map(rewardData => {
          return new Reward(rewardData);
        });

        dispatch({ action: action, type: Types.SUCCESS, payload: { data: rewards, requestId: requestId, next: body.next } });
        return rewards;
      })
      .catch(error => {
        dispatch({ action: action, type: Types.FAIL, payload: error });
        console.debug("Failed loading rewards:", error);
        return [];
      });
  };
}

const reloadRewards = () => (dispatch: any) => {
  const { filters } = store.getState().RewardReducer;

  dispatch(listRewards(filters, false));
};

const applyFilters = () => (dispatch: any) => {
  dispatch({ action: Actions.APPLY_FILTERS, type: Types.SUCCESS });
};

const resetFilters = () => (dispatch: any) => {
  dispatch({ action: Actions.RESET_FILTERS, type: Types.SUCCESS });
};

const suggestReward = (rewardData: FormData) => {
  return (dispatch: any) => {
      const action = Actions.SUGGEST_REWARD;
      dispatch({ type: Types.BEGIN, action: action });
      return fetchAPI(`${urls.API.v3}/reward/suggest`, {
          method: 'POST',
          body: rewardData,
      })
          .then((body: RewardData) => {
              dispatch({ type: Types.SUCCESS, action: action });
          })
          .catch(error => {
              dispatch({ type: Types.FAIL, action: action, payload: error });
          });
  }
}

const RewardsAction = {
  listRewards,
  loadMoreRewards,
  reloadRewards,
  applyFilters,
  resetFilters,
  suggestReward,
};

export default RewardsAction;