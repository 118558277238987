import moment from "moment";
import _ from "lodash";
import Cocon, { CoconData, CoconOwner } from "./Cocon";


export interface CoconsClusterData {
    id: string;
    displayAddress: string;
    usersCount: number;
    batchesCount: number;
    pendingIssues: number;
    dateDeployed?: number; // milliseconds timestamp
    joinCode: string;
    cocons: CoconData[];
    managedBy: CoconOwner;
}

export default class CoconsCluster {
    id: string;
    displayAddress: string;
    usersCount: number;
    batchesCount: number;
    pendingIssues: number;
    dateDeployed?: moment.Moment; // milliseconds timestamp
    joinCode: string;
    cocons: Cocon[];
    managedBy: CoconOwner;

    constructor(data: CoconsClusterData) {
        this.id = data.id;
        this.displayAddress = data.displayAddress;
        this.usersCount = data.usersCount;
        this.batchesCount = data.batchesCount;
        this.pendingIssues = data.pendingIssues;
        if (data.dateDeployed) {
            this.dateDeployed = moment(data.dateDeployed, 'x');
        }
        this.joinCode = data.joinCode;
        this.cocons = data.cocons.map(coconData => new Cocon(coconData));
        this.managedBy = data.managedBy;
    }
}
