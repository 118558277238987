import { Box, Typography } from "@mui/material";

import { Namespaces } from "locales/translations";
import React from "react";
import { useTranslation } from "react-i18next";

interface RewardCostChipProps {
    cost: number;
}

function RewardCostChip(props: RewardCostChipProps) {

    const { cost } = props;

    const { t } = useTranslation([Namespaces.glossary]);

    return (
        <Box sx={{
            float: "right",
            backgroundColor: 'primary.main',
            borderRadius: (theme) => theme.spacing(1),
            px: 2,
            py: .5,
            marginRight: 3,
            marginTop: -2,
        }}>
            <Typography color="white.main">
                {t("point", { ns: Namespaces.glossary, count: cost })}
            </Typography>
        </Box>
    );
}

export default RewardCostChip;