import React, { useEffect, useMemo, useState } from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
import { COMMUNICATION_MEDIA_LIST, CommunicationMedia, CommunicationMediaType, generateTemplateMedia } from 'helpers/communication_media';
import { format, Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import CommunicationMediaCard from './CommunicationMediaCard';
import { StoreInterface } from 'store/reducers';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import SectionLoader from 'components/_include/SectionLoader';
import { CONTACTS } from 'constants/contacts';
import Animation from 'components/_include/Animation';
import ContactsCompanySection from 'components/Contacts/ContactsCompanySection';
import { Box } from '@mui/material';
import DatesAndCoconsSelect from 'components/Statistics/DatesAndCoconSelect';
import { ChartsIDs } from 'constants/charts';
import { CO2EquivalentEmissions } from 'constants/emissions';
import urls from 'constants/urls';
import i18next from 'i18next';
import { useSearchParams } from 'react-router-dom';
import storage, { StorageType } from '../../storage';
import CommunicationMediaWrapper from './CommunicationMediaWrapper';
import CommunicationMediaAction from 'actions/communication_media';
import { set } from 'lodash';

const mapStateToProps = (state: StoreInterface) => ({
    statsReducer: state.StatsReducer,
    cocon: state.CoconReducer.selectedCocon.data,
    cluster: state.CoconReducer.selectedCluster.data,
    clusters: state.CoconReducer.clusters.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    // loadStats: (coconId: string) => dispatch(StatsAction.getCoconStats(coconId, {})),
    setPartnerLogo: (partnerLogo: string) => dispatch(CommunicationMediaAction.setPartnerLogo(partnerLogo)),
    setJoinCode: (joinCode: string) => dispatch(CommunicationMediaAction.setJoinCode(joinCode)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CommunicationMediaPageProps = ConnectedProps<typeof connector> & {

}

function CommunicationMediaPage(props: CommunicationMediaPageProps) {

    const { statsReducer, cocon, cluster, clusters, setPartnerLogo, setJoinCode } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.explanations, Namespaces.titles,]);

    const [searchParams, setSearchParams] = useSearchParams();


    const joinCode = cocon?.joinCode || cluster?.joinCode || "";
    setJoinCode(joinCode);


    const selectedCoconsCluster = clusters.find((cluster) => cocon?.clusterId === cluster.id);

    const partnerLogoURL = cluster?.managedBy.imageURL || selectedCoconsCluster?.managedBy.imageURL;

    const [partnerLogo, setLogo] = useState<string>();

    const [mostFrequentError, setMostFrequentError] = useState<string>();

    useEffect(() => {
        (async () => {
            if (partnerLogoURL) {
                const buffer = await storage.downloadFile(StorageType.PARTNERS, `Normalized_Partners_Logos/${partnerLogoURL}`);
                setLogo(buffer.toString("base64"));
            }
            if (partnerLogo)
                setPartnerLogo(partnerLogo);
        })();
    }, [searchParams, partnerLogo]);


    const handleCoconChange = (coconId: string) => {
        searchParams.set("coconId", coconId);
        setSearchParams(searchParams);
    };

    const handleClusterChange = (clusterId: string) => {
        searchParams.set("clusterId", clusterId);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        (async () => {
            const errorFileBuffer = await storage.downloadFile(StorageType.REPORTS, `mask.png`)
            if (errorFileBuffer) {
                setMostFrequentError(errorFileBuffer.toString("base64"));
            }
        })();
    });

    return (
        <Container>
            <Typography
                variant="subtitle1"
                sx={{
                    marginTop: 2,
                    marginBottom: 4,
                }}
            >
                {t("communication_media", { ns: Namespaces.explanations })}
            </Typography>
            <div>
                <DatesAndCoconsSelect
                    onClusterSelect={handleClusterChange}
                    onCoconSelect={handleCoconChange}
                />

                <Grid
                    container
                    spacing={4}
                >
                    {COMMUNICATION_MEDIA_LIST.map(mediaType => (
                        <Grid
                            item
                            key={mediaType}
                            sm={6}
                            xs={12}
                        >
                            <CommunicationMediaWrapper
                                type={mediaType}
                            />
                        </Grid>
                    ))}

                    {statsReducer.loading && (
                        <SectionLoader
                            background
                        />
                    )}
                </Grid>

                <Divider light sx={{ my: 6 }} />

                <div>
                    {Object.entries(CONTACTS).map(([company, contacts]) => (
                        <ContactsCompanySection
                            key={company}
                            company={company}
                            contacts={contacts}
                        />
                    ))}

                    <Box
                        sx={{
                            marginTop: 6,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Animation
                            data={require("animations/waving_jason.json")}
                            width={200}
                            height={200}
                            bounce={true}
                            speed={0.75}
                        />
                    </Box>
                </div>
            </div>
        </Container>
    );
}

export default connector(CommunicationMediaPage);