import { ChartsIDs } from "constants/charts";
import { CO2EquivalentEmissions } from "constants/emissions";
import { CommunicationMedia, CommunicationMediaType, generateTemplateMedia, generateUnsortableWasteMedia } from "helpers/communication_media";
import { ConnectedProps, connect } from "react-redux";
import { StoreInterface } from "store/reducers";
import CommunicationMediaCard from "./CommunicationMediaCard";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);


type FoodLeftoversWrapperType = ConnectedProps<typeof connector> & {
}

function FoodLeftoversWrapper(props: FoodLeftoversWrapperType) {



    const [communicationMediaList, setCommunicationMediaList] = useState<CommunicationMedia>();

    useEffect(() => {
        (async () => {
            const communicationMedia = await generateUnsortableWasteMedia(CommunicationMediaType.MASK, "Restes alimentaires", "food_leftovers.png")
            setCommunicationMediaList(communicationMedia);
        })();
    },[]);

    if (communicationMediaList) {
        return <CommunicationMediaCard media={communicationMediaList} />;
    }
    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodLeftoversWrapper);