import User from "models/User";
import { Action, CodeError } from "actions/actions";
import { Types, Actions } from "constants/actions";
import { UNAUTHORIZED_ERROR } from "constants/api_errors";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export interface UserContextInterface {
    user: User | null,
    loading: boolean,
    error: CodeError | null,
};

const initialState: UserContextInterface = {
    user: null,
    loading: false,
    error: null,
};

export default (state: UserContextInterface = initialState, action: Action) => {
    switch (action.type) {
        case Types.BEGIN:
            switch (action.action) {
                case Actions.SIGN_IN:
                case Actions.SIGN_IN_FROM_INVITE:
                    return {
                        ...state,
                        loading: true,
                        error: false,
                    };

                case Actions.COMPLETE_PROFILE:
                    return {
                        ...state,
                        error: null,
                        loading: true,
                    };
            }
            break;

        case Types.SUCCESS:
            switch (action.action) {
                case Actions.SET_USER:
                    return {
                        ...state,
                        loading: false,
                        user: action.payload,
                    };

                case Actions.LOG_OUT:
                    return {
                        ...state,
                        user: null,
                    };

                case Actions.RESET_USER_ERROR:
                    return {
                        ...state,
                        error: null,
                    };

                case Actions.COMPLETE_PROFILE:
                    return {
                        ...state,
                        loading: false,
                    };
            }
            break;

        case Types.FAIL:

            let updatedState = {...state};

            if (action.payload && action.payload.code === UNAUTHORIZED_ERROR.code) {
                signOut(auth);

                updatedState = {
                    ...updatedState,
                    user: null,
                };
            }

            switch (action.action) {
                case Actions.SIGN_IN:
                case Actions.SIGN_IN_FROM_INVITE:
                    updatedState = {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };

                case Actions.COMPLETE_PROFILE:
                    updatedState = {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };
            }

            return updatedState;
    }

    return state;
}