import { List, Drawer as MuiDrawer, DrawerProps } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';
import SectionLoader from 'components/_include/SectionLoader';
import { useSearchParams } from 'react-router-dom';
import DrawerListItem from './DrawerListItem';
import { useParams } from 'react-router-dom';

const getCategories = (coconOrClusterId?: string, prefix?:string) => {
    if (!coconOrClusterId) return [];

    return [
        {
            label: 'summary',
            icon: <HomeIcon />,
            active: true,
            link: `/${prefix}/${coconOrClusterId}`,
        },
        {
            label: 'sorting',
            icon: <DeleteSweepIcon />,
            active: true,
            link: `/${prefix}/${coconOrClusterId}/sorting`,
        },
        {
            label: 'wastes',
            icon: <CategoryIcon />,
            active: true,
            link: `/${prefix}/${coconOrClusterId}/wastes`,
        },
        {
            label: 'users',
            icon: <PeopleIcon />,
            active: true,
            link: `/${prefix}/${coconOrClusterId}/users`,
        },
        {
            label: 'issues',
            icon: <FlagIcon />,
            active: true,
            link: `/${prefix}/${coconOrClusterId}/issues`,
        },
    ];
};

const mapStateToProps = (state: StoreInterface) => ({
    user: state.UserReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    // loadProfile: () => dispatch(UserAction.getProfile()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DrawersProps = ConnectedProps<typeof connector> & DrawerProps & {
    onClose?: () => void;
}

function Drawer(props: DrawersProps) {
    const { user, onClose, ...other } = props;

    // const searchParams = useSearchParams();
    // const coconId = searchParams.coconId;
    
    const { coconId,clusterId } = useParams();


    let check : boolean;
    
    let categories;

    if (coconId){
        categories = getCategories(coconId,"cocons");
     } else{
        categories = getCategories(clusterId,"clusters");
     } 
       

    return (
        <MuiDrawer
            {...other}
            variant="permanent"
            PaperProps={{
                ...other.PaperProps,
                sx: {
                    ...other.PaperProps?.sx,
                    boxSizing: 'border-box',
                    paddingTop: 1,
                },
            }}
        >
            {categories.length > 0 ? (
                <List disablePadding>
                    {categories.map(({ label, icon, link }) => (
                        <DrawerListItem
                            label={label}
                            icon={icon}
                            link={link}
                            key={label}
                            />
                    ))}
                </List>
            ) : (
                <div style={{ flexGrow: 1 }}>
                    <SectionLoader />
                </div>
            )}
        </MuiDrawer>
    );
}

export default connector(Drawer);
