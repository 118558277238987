import { StoreInterface } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Container, Grid, GridProps, Box, Typography } from '@mui/material';
import SectionLoader from "components/_include/SectionLoader";
import StatsCardsGrid from "./CardsGrid/StatsCardsGrid";
import StatsTextsGrid from "./TextsGrid/StatsTextsGrid";
import NameAndSelectHeader from "../NameAndSelectHeader";
import StatsAction from "actions/stats";
import { useMatch, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import SortingStatsFilters from "../CoconStatsFilters";
import { DATE_SELECT_TYPES, DEFAULT_DATE_SELECT_TYPE, getDatesFromDateSelectType, getDatesFromSearchParams } from "helpers/dates";
import { listSortableWasteTypes } from "helpers/trash";




const mapStateToProps = (state: StoreInterface) => ({
    coconLoading: state.CoconReducer.selectedCocon.loading,
    clusterLoading: state.CoconReducer.selectedCluster.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadWeeklySummary: (coconId: string, from: moment.Moment, to: moment.Moment,) => dispatch(StatsAction.getCoconSummary(coconId, from, to)),
    loadedClusterSummary : (clusterId: string, from: moment.Moment, to: moment.Moment,) => dispatch(StatsAction.getClusterSummary(clusterId, from, to))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CoconSummaryProps = ConnectedProps<typeof connector> & {

}

function CoconSummaryPage(props: CoconSummaryProps) {

    const { t } = useTranslation([Namespaces.stats, Namespaces.titles,])

    const { clusterLoading,coconLoading, loadWeeklySummary,loadedClusterSummary } = props;

    const { coconId, clusterId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const { range, from, to } = getDatesFromSearchParams(searchParams);

    useEffect(() => {
        if (coconId && from && to) {
            loadWeeklySummary(coconId,from,to);
        }
        else if (clusterId && from && to){
           loadedClusterSummary(clusterId, from, to);
        }
    }, [coconId,clusterId,from,to]);

    
    useEffect(() => { // set default URL search params
        let updateNeeded = false;
        if (!from || !to || !range) {
            updateNeeded = true;
            const dates = getDatesFromDateSelectType(DEFAULT_DATE_SELECT_TYPE, undefined, from, to);
    
            searchParams.set("range", DEFAULT_DATE_SELECT_TYPE);
            searchParams.set("from", dates.start.format("YYYY-MM-DD"));
            searchParams.set("to", dates.end.format("YYYY-MM-DD"));
        }
    }, [searchParams]);


    return (
        (coconLoading || clusterLoading) ? (
            <SectionLoader />
        ) : (
            <Container>
                <NameAndSelectHeader />
                
                <SortingStatsFilters />

                <StatsCardsGrid />

                <StatsTextsGrid />
            </Container>
        )

    )
};

export default connector(CoconSummaryPage);