export enum TrashTypes {
    // sortable waste
    LIGHT_PET_BOTTLE = "light_PET_bottle",
    DARK_PET_BOTTLE = "dark_PET_bottle",
    OPAQUE_PET_BOTTLE = "opaque_PET_bottle",
    LIQUID_FOOD_PACKAGING = "liquid_food_packaging",
    PLASTIC_POT = "plastic_pot",
    PLASTIC_FILM = "plastic_film",
    BROWN_CARDBOARD = "brown_cardboard",
    PRINTED_CARDBOARD = "printed_cardboard",
    GROSS_STORE_PAPER = "gross_store_paper",
    PAPER = "paper",
    METAL = "metal",
    
    // sorting mistakes
    GLASS = "glass",
    MASK = "mask",
    COLLECTION_BAG = "collection_bag",
    NESTED_ITEMS = "nested_items",
    FOOD_LEFTOVERS = "food_leftovers",
    ELECTRONIC_WASTE = "electronic_waste",
    OTHER = "other",
}

export const NOT_ACCEPTED_TRASH = [
    TrashTypes.FOOD_LEFTOVERS,
    TrashTypes.MASK,
    TrashTypes.COLLECTION_BAG,
    TrashTypes.GLASS,
    TrashTypes.ELECTRONIC_WASTE,
    TrashTypes.NESTED_ITEMS,
    TrashTypes.OTHER,
];