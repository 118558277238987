import urls from '../constants/urls';
import { Actions, Types } from '../constants/actions';
import User from 'models/User';
import { CodeError, handleAPIResponse } from './actions';
import { BAD_REQUEST_ERROR, CONFLICT_ERROR, UNAUTHORIZED_ERROR, } from '../constants/api_errors';
import { User as FirebaseUser, signInWithEmailAndPassword, signInWithCustomToken, signOut, } from 'firebase/auth';
import { auth } from "../firebase";

const login = (email: string, password: string) => {
  return (dispatch: any) => {

    const action = Actions.SIGN_IN;
    dispatch({ type: Types.BEGIN, action: action });

    // login through Firebase to get a token to access the API
    signInWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        const user = userCredential.user;
        if (!user) throw { code: 400, body: "Invalid credentials", };
        const idToken = await userCredential.user?.getIdToken();
        dispatch({ type: Types.SUCCESS, action: action, payload: user });
      })
      .catch((error) => {
        dispatch({ type: Types.FAIL, action: action, payload: error });
      });
  };
};

const signInWithToken = (inviteToken: string) => async (dispatch: any) => {
  const action = Actions.SIGN_IN_FROM_INVITE;
  dispatch({ action: action, type: Types.BEGIN });

  const SIGN_IN_URL = `${urls.API.v4}/user/sign-in`;

  // validate the pin/password with the server
  return fetch(`${SIGN_IN_URL}?token=${inviteToken}`, {
    method: 'GET',
  })
    .then(handleAPIResponse)
    .then((res: { token: string }) => {

      const token = res.token; // authentication token returned by API to authenticate with Firebase

      signInWithCustomToken(auth, token)
        .then(async (userCredential) => {
          const user = userCredential.user;

          if (!user) { // token not accepted
            await signOut(auth);
            throw new CodeError(500, `Petit problème technique: merci de contacter un administrateur Ficha.`);
          }

          // login successful
          dispatch({ action: action, type: Types.SUCCESS, payload: user });
        });
    })
    .catch(e => {
      console.error("error", e);
      dispatch({ action: action, type: Types.FAIL, payload: e });
    });
};

const dispatchLogOut = () => async (dispatch: any) => {
  await signOut(auth)
    .then(() => {
      dispatch({ type: Types.SUCCESS, action: Actions.LOG_OUT });
    });
};

const verifyEmailAddress = (token: string) => {
  return (dispatch: any) => {
    const action = Actions.COMPLETE_PROFILE;
    dispatch({ type: Types.BEGIN, action: action });

    return fetch(`${urls.API.v3}/user/verify-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': token,
      },
    })
      .then(handleAPIResponse)
      .then(body => {
        dispatch({ type: Types.SUCCESS, action: action, });
        return true;
      })
      .catch(e => {
        // don't dispatch 401 error which would redirect to login page
        const error = new CodeError(
          e.code === UNAUTHORIZED_ERROR.code ? BAD_REQUEST_ERROR.code : CONFLICT_ERROR.code,
          e.code === UNAUTHORIZED_ERROR.code ? "invalid_link" : "verify_email.already_done",
        );
        dispatch({ type: Types.FAIL, action: action, payload: error, });
        return false;
      });
  }
}

const completeProfile = (token: string, addressData: any) => {
  return (dispatch: any) => {
    const action = Actions.COMPLETE_PROFILE;
    dispatch({ type: Types.BEGIN, action: action });

    return fetch(`${urls.API.v3}/user/complete-profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': token,
      },
      body: JSON.stringify(addressData),
    })
      .then(body => {
        dispatch({ type: Types.SUCCESS, action: action, });
      })
      .catch(error => {
        dispatch({ type: Types.FAIL, action: action, payload: error, });
        console.debug("Failed completing profile:", error);
      });
  }
}

const resetPassword = (token: string, email: string, password: string) => {
  return (dispatch: any) => {
    const action = Actions.UPDATE_PASSWORD;
    dispatch({ type: Types.BEGIN, action: action });

    return fetch(`${urls.API.v3}/user/update-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': token,
      },
      body: JSON.stringify({
        password: password,
      }),
    })
      .then(body => {
        dispatch({ type: Types.SUCCESS, action: action, });
      })
      .catch(error => {
        dispatch({ type: Types.FAIL, action: action, payload: error, });
        console.debug("Failed completing profile:", error);
      });
  }
}

const setUser = (firebaseUser: FirebaseUser | null) => (dispatch: any) => {
  if (firebaseUser) {
    return User
      .fromFirebaseUser(firebaseUser)
      .then(user => {
        dispatch({ action: Actions.SET_USER, type: Types.SUCCESS, payload: user });
      });
  }
  else {
    dispatch({ action: Actions.SET_USER, type: Types.SUCCESS, payload: null });
    return;
  }
}

const UserAction = {
  // createUser,
  login,
  signInWithToken,
  dispatchLogOut,
  verifyEmailAddress,
  completeProfile,
  resetPassword,
  setUser,
};

export default UserAction;