import { CommunicationMediaType } from "helpers/communication_media";
import CommunicationMediaCard from "./CommunicationMediaCard";
import CarCommunicationMediaWrapper from "./CarCommunicationMediaWrapper";
import TShirtCommunicationMediaWrapper from "./TShirtCommunicationMediaWrapper";
import PhoneCommunicationMediaWrapper from "./PhoneCommunicationMediaWrapper";
import ComputerCommunicationMediaWrapper from "./ComputerCommunicationMediaWrapper";
import Co2CommunicationMediaWrapper from "./Co2CommunicationMediaWrapper";
import { ConnectedProps, connect } from "react-redux";
import { StoreInterface } from "store/reducers";
import { useEffect, useState } from "react";
import storage, { StorageType } from "storage";
import MaskWrapper from "./MaskWrapper";
import GlassWrapper from "./GlassWrapper";
import FoodLeftoversWrapper from "./FoodLeftoversWrapper";
import ElectronicWasteWrapper from "./ElectronicWasteWrapper";
import CollectionBagWrapper from "./CollectionBagWrapper";



const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CommunicationMediaWrapperProps = ConnectedProps<typeof connector> & {
    type: CommunicationMediaType;
}


function CommunicationMediaWrapper(props: CommunicationMediaWrapperProps) {

    const { type } = props;


    let Wrapper;

    switch (type) {
        case CommunicationMediaType.KLM_CAR_EQUIVALENT: Wrapper = CarCommunicationMediaWrapper;
            break;
        case CommunicationMediaType.TSHIRT_PRODUCTION_EQUIVALENT: Wrapper = TShirtCommunicationMediaWrapper;
            break;
        case CommunicationMediaType.PHONE_PRODUCTION_EQUIVALENT: Wrapper = PhoneCommunicationMediaWrapper;
            break;
        case CommunicationMediaType.COMPUTER_PRODUCTION_EQUIVALENT: Wrapper = ComputerCommunicationMediaWrapper;
            break;
        case CommunicationMediaType.CO2_SAVED: Wrapper = Co2CommunicationMediaWrapper;
            break;
        case CommunicationMediaType.MASK: Wrapper = MaskWrapper;
            break;
        case CommunicationMediaType.GLASS: Wrapper = GlassWrapper;
            break;
        case CommunicationMediaType.FOOD_LEFTOVERS: Wrapper = FoodLeftoversWrapper;
            break;
        case CommunicationMediaType.ELECTRONIC_WASTE: Wrapper = ElectronicWasteWrapper;
            break;
        case CommunicationMediaType.COLLECTION_BAG: Wrapper = CollectionBagWrapper;
            break;
    }

    return (
        Wrapper ? <Wrapper /> : null
    )
}

export default CommunicationMediaWrapper;