import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from "store/reducers";
import { useEffect } from "react";
import { Divider, FormControl, InputLabel, ListSubheader, MenuItem, Select, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { getURLParam } from "helpers/urls";
import CoconAction from "actions/cocon";
import { useParams } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading || state.CoconReducer.cocons.loading,
    availableCocons: state.UserReducer.user?.cocons || [],
    cocons: state.CoconReducer.cocons.data,
    selectedCocon: state.CoconReducer.selectedCocon.data,
    selectedCluster: state.CoconReducer.selectedCluster.data,
    clusters: state.CoconReducer.clusters.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadCocon: (coconId: string) => dispatch(CoconAction.retrieveCocon(coconId)),
    loadCluster: (clusterId: string)=> dispatch(CoconAction.retrieveCluster(clusterId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CoconSelectProps = ConnectedProps<typeof connector> & {
    onCoconChange: (coconId: string)=> void;
    onClusterChange: (clusterId: string) => void;
}

function CoconSelect(props: CoconSelectProps) {
    const { loading, availableCocons, selectedCocon,selectedCluster, cocons, loadCocon,loadCluster, clusters, onCoconChange,onClusterChange} = props;

    const { t } = useTranslation([Namespaces.glossary]);


    const params = useParams();

    const handleCoconSelected = (coconId: string) => {
        loadCocon(coconId);

        onCoconChange(coconId)
    };


    const handleClusterSelected = (clusterId: string)=> {
        loadCluster(clusterId);

        onClusterChange(clusterId);
    }



    const coconId = params.coconId || getURLParam("coconId") || selectedCocon?.id || "";

    const clusterId = params.clusterId || getURLParam("clusterId") || selectedCluster?.id || "";

    useEffect(() => {
        if (coconId && coconId !== selectedCocon?.id) {
            handleCoconSelected(coconId);
        } else if (clusterId && clusterId != selectedCluster?.id) {
            handleClusterSelected(clusterId);
        }
    }, [coconId,clusterId]);


    const clustersIds: string[] = clusters.map(cluster => cluster.id);
    const coconsIds: string[] = cocons.map(cocon => cocon.id);

    let address = "";
    let currentClusterId = "";

    return (
        <FormControl
            variant="outlined"
            disabled={loading}
            sx={{
                margin: 0,
                maxWidth: (theme) => theme.spacing(100),
                width: "100%",
            }}
            >
            <InputLabel id={selectedCluster ? "cluster-select-label" : "cocon-select-label"}>
                {selectedCluster ? t("cluster", { ns: Namespaces.glossary }) : t("cocon", {ns : Namespaces.glossary})}
            </InputLabel><Select
                color="primary"
                labelId={selectedCluster ? "cluster-select-label" : "cocon-select-label"}
                label={selectedCluster ? t("cluster", { ns: Namespaces.glossary }) : t("cocon", {ns : Namespaces.glossary})}
                value={selectedCluster ? clusterId : coconId}
                onChange={(e) => {
                    if (clustersIds.includes(e.target.value)){
                        handleClusterSelected(e.target.value as string)
                    }else if (coconsIds.includes(e.target.value)){
                        handleCoconSelected(e.target.value as string)
                    }}}
                MenuProps={{
                    MenuListProps: {
                        disablePadding: true,
                    }
                }}
            >
                    {clusters.map(cluster => {
                        let elements = [];
                        if (cluster?.displayAddress !== address) {
                            address = cluster!.displayAddress;
                            currentClusterId = cluster?.id;
                            elements.push(
                                <Divider
                                    key={cluster?.managedBy.id + "-top"} />
                            );
                            elements.push(
                                <MenuItem
                                    key={cluster?.id}
                                    value={cluster?.id}
                                    sx={{
                                        bgcolor: "#eeeeee",
                                        py: 1.5,
                                        "+ .MuiDivider-root": {
                                            my: 0,
                                        }
                                    }}
                                >
                                    {`${cluster?.managedBy.name} - ${cluster?.displayAddress}`}
                                </MenuItem>
                            ) ;
                            elements.push(
                                <Divider
                                    key={cluster?.managedBy.id + "-bottom"} />
                            );
                        }
                        else if (cluster.id !== currentClusterId) {
                            currentClusterId = cluster.id;
                            elements.push(
                                <Divider
                                    key={currentClusterId} />
                            );
                        }
                        cluster.cocons.map((cocon) => {
                            elements.push(
  
                                <MenuItem
                                    key={cocon.id}
                                    value={cocon.id}
                                    sx={{
                                        py: 1.5,
                                        "+ .MuiDivider-root": {
                                            my: 0,
                                        }
                                    }}
                                >
                                    {cocon.getAddress()}
                                </MenuItem> 
                            );
                        });
                        return elements;
                    })}
                </Select>
        </FormControl>
    );
}

export default connector(CoconSelect);