import { User as FirebaseUser } from "firebase/auth";
import { connect, ConnectedProps } from 'react-redux';
import { Box, CssBaseline } from '@mui/material';
import NavigationBar from '../Navigation/NavigationBar/NavigationBar';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { StoreInterface } from "store/reducers";
import UserAction from "actions/user";
import { onAuthStateChanged } from "firebase/auth";
import { QueryFilters } from "constants/types";
import CoconAction from 'actions/cocon';
import { auth } from "../../firebase";

const mapStateToProps = (state: StoreInterface) => ({
    user: state.UserReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    setUser: (user: FirebaseUser | null) => dispatch(UserAction.setUser(user)),
    loadCoconsList: (filters: QueryFilters) => dispatch(CoconAction.listCoconsClusters(filters)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LayoutProps = ConnectedProps<typeof connector> & {
    
}

function Layout(props: LayoutProps) {
    const { user, setUser, loadCoconsList } = props;
    
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // check if user is logged in
    onAuthStateChanged(auth, firebaseUser => {
        if (!!firebaseUser !== !!user) { // authentication state has changed
            setUser(firebaseUser); // populate user to store
            loadCoconsList([]);
        }
        if (!firebaseUser) { // user needs to log in
            navigate(`/login/?next=${pathname}`);
        }
    });

    if (!user) return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            minHeight: '100vh',
        }}>
            <CssBaseline />
            <NavigationBar />
            <Outlet />
        </Box>
    );
}

export default connector(Layout);