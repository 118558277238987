import { Button, Card, CardActions, CardMedia, } from '@mui/material';
import { useMemo } from 'react';

type ImageInputProps = {
    image: File | null;
    disabled: boolean;
    onImageSelected: (image: File) => void;
    label: string;
    changeLabel: string;
}

function ImageInput(props: ImageInputProps) {
    const { image, disabled, label, changeLabel, onImageSelected } = props;

    const handleImageSelected = (input: HTMLInputElement) => {
        const files = input.files;
        if (files && files?.length > 0) {
            onImageSelected(files[0]);
        }
    }

    const imageURL = useMemo(() => {
        return image ? URL.createObjectURL(image) : "";
    }, [image]);

    if (!image) { // no image selected yet, simple button
        return (
            <Button
                variant="contained"
                component="label"
                color="primary"
                disabled={disabled}
            >
                {label}
                <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => { handleImageSelected(event.target) }}
                />
            </Button>
        );
    }

    // image selected, display thumbnail
    return (
        <Card>
            <CardMedia
                image={imageURL}
                sx={{
                    height: 0,
                    paddingTop: '100%', // square images
                }}
            >
            </CardMedia>
            <CardActions>
                <Button
                    fullWidth
                    color="primary"
                    component="label"
                    disabled={disabled}
                >
                    {changeLabel}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => { handleImageSelected(event.target) }}
                    />
                </Button>
            </CardActions>
        </Card>
    );
}

export default ImageInput;