import { Grid, GridProps } from "@mui/material";
import ItemsDivider from "./ItemsDivider";
import CommonErrorsText from "./CommonErrorsText";
import ReportedIssuesText from "./ReportedIssuesText";
import CollectionsDaysText from "./CollectionsDaysText";

const ItemWrapper = (props: GridProps) => (
    <Grid
        item
        xs={12}
        sm={6}
        {...props}
    />
);

const GRID_SPACING = 8;

export default function StatsTextsGrid() {
    return (
        <Grid
            container
            spacing={GRID_SPACING}
            alignItems="center"
            sx={{
                flexGrow: 1,
                maxHeight: (theme) => theme.spacing(40),
            }}
        >
            {/* <ItemWrapper>
                <CollectionsDaysText />
            </ItemWrapper>

            <ItemsDivider 
                spacing={GRID_SPACING / 2}
                verticalInset={6}
            /> */}

            <ItemWrapper>
                <CommonErrorsText />
            </ItemWrapper>

            <ItemsDivider 
                spacing={GRID_SPACING / 2}
                verticalInset={6}
            />

            <ItemWrapper>
                <ReportedIssuesText />
            </ItemWrapper>
        </Grid>
    );
}
