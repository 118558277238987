export enum Types {
    BEGIN,
    SUCCESS,
    FAIL,
};

export enum Actions {
    // Authentication
    SIGN_UP,
    SIGN_IN,
    SIGN_IN_FROM_INVITE,
    REFRESH_TOKEN,
    LOAD_TOKEN,

    // Users
    SET_USER,
    LOG_OUT,
    COMPLETE_PROFILE,
    UPDATE_PASSWORD,

    // Cocons
    LOAD_STATS,
    LOAD_CLUSTER_SUMMARY,
    LOAD_COCON_SUMMARY,
    LOAD_COCONS_CLUSTERS,
    LOAD_COCON,
    LOAD_CLUSTER,
    LOAD_COCON_ISSUES,
    UPDATE_COCON_ISSUE,
    RESET_COCON_VALUE,

    // Rewards
    APPLY_FILTERS,
    RESET_FILTERS,
    LOAD_MORE_REWARDS,
    PREVIEW_REWARDS_WITH_FILTERS,
    LOAD_REWARDS,
    SUGGEST_REWARD,

    // Waste report
    SEND_WASTE_REPORT,

    // Other
    // SET_DATES_SELECT,
    RESET_ACTION_RESULT,
    RESET_USER_ERROR,

    // Communication Media
    SET_PARTNER_LOGO,
    SET_JOIN_CODE,
};