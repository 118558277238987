import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import CoconSelect from "../_include/Filters/CoconSelect";
import DatesSelect from "../_include/Filters/DatesSelect";
import RefreshStatsButton from "./RefreshStatsButton";
import { useNavigate } from "react-router-dom";
import CoconAction from "actions/cocon";
import StatsAction from "actions/stats";
import { StoreInterface } from "store/reducers";
import moment from "moment";
import { DATE_SELECT_TYPES, getDatesFromDateSelectType, getDatesFromSearchParams } from "helpers/dates";
import { usePrevious } from "helpers/refs";
import { useSearchParams } from "react-router-dom";

const mapStateToProps = (state: StoreInterface) => ({
    availableCocons: state.UserReducer.user?.cocons || [],
    coconLoading: state.CoconReducer.selectedCocon.loading,
    clusterLoading: state.CoconReducer.selectedCluster.loading,
    selectedCocon: state.CoconReducer.selectedCocon.data,
    selectedCluster: state.CoconReducer.selectedCluster.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadCoconStats: (coconId: string, from: moment.Moment, to: moment.Moment,) => dispatch(StatsAction.getCoconStats(coconId, from, to)),
    loadClusterStats : (clusterId: string, from: moment.Moment, to: moment.Moment)=> dispatch(StatsAction.getClusterStats(clusterId,from, to)),
    loadCocon: (coconId: string) => dispatch(CoconAction.retrieveCocon(coconId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DatesAndCoconsSelectProps = ConnectedProps<typeof connector> & {
    onCoconSelect: (coconId: string) => void;
    onClusterSelect: (clusterId: string) => void;
}

function DatesAndCoconsSelect(props: DatesAndCoconsSelectProps) {
    const { onCoconSelect,onClusterSelect, coconLoading, clusterLoading,selectedCocon, selectedCluster, loadCoconStats, loadClusterStats, loadCocon, } = props;

    const [searchParams] = useSearchParams();
    const { from, to } = getDatesFromSearchParams(searchParams);

    const handleCoconSelected = (coconId: string) => {
        onCoconSelect(coconId);
        // loadCocon(coconId);
    }
    const handleClusterSelected = (clusterId : string)=> {
        onClusterSelect(clusterId);
    }

    const wasLoadingCocon = usePrevious(coconLoading);
    const wasLoadingCluster = usePrevious(clusterLoading);
    useEffect(() => {
        if (wasLoadingCocon && !coconLoading && selectedCocon && from && to) {
            loadCoconStats(selectedCocon.id, from, to,);
        } else if (wasLoadingCluster && !clusterLoading && selectedCluster && from && to){
            loadClusterStats(selectedCluster.id, from, to);
        }
    }, [coconLoading, clusterLoading]);

    return (
        <Grid
            container
            alignItems="flex-start"
            sx={{
                marginTop: 2,
                marginBottom: 4,
            }}
        >
            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    width: "100%",
            
                }}
            >
            {<CoconSelect
                onCoconChange={(coconId) => handleCoconSelected(coconId)}
                onClusterChange={(clusterId) => handleClusterSelected(clusterId)}
            />}
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    width: "100%",
                }}
            >
                {(selectedCocon || selectedCluster) ? <DatesSelect /> : <></>}
            </Grid>
        </Grid>
    )
}

export default connector(DatesAndCoconsSelect);