import urls from "constants/urls";
import { fetchAPI } from "./actions";
import { Actions, Types } from "constants/actions";
import moment from "moment";
import { Dispatch } from "store/store";
import { StatsContextInterface, WeeklySummary } from "store/reducers/stats";
import CoconIssue, { CoconIssueData } from "models/Cocons/CoconIssue";

const getCoconStats = (coconId: string, from: moment.Moment, to: moment.Moment) => (dispatch: any) => {
    const action = Actions.LOAD_STATS;

    dispatch({ type: Types.BEGIN, action: action, payload: {} });
    
    return fetchAPI(`${urls.API.v3}/cocon/${coconId}/statistics?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((stats: StatsContextInterface) => {
            console.debug("Loaded stats:", stats);
            dispatch({ type: Types.SUCCESS, action: action, payload: stats });
            return stats;
        })
        .catch(error => {
            dispatch({ type: Types.FAIL, action: action, payload: error });
            console.debug("Failed loading stats:", error);
            return [];
        });
}

const getClusterStats = (clusterId: string, from: moment.Moment, to: moment.Moment) => (dispatch: any) => {
    const action = Actions.LOAD_STATS;

    dispatch({ type: Types.BEGIN, action: action, payload: {} });
    
    return fetchAPI(`${urls.API.v3}/cocon/cluster/${clusterId}/statistics?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((stats: StatsContextInterface) => {
            console.debug("Loaded stats:", stats);
            dispatch({ type: Types.SUCCESS, action: action, payload: stats });
            return stats;
        })
        .catch(error => {
            dispatch({ type: Types.FAIL, action: action, payload: error });
            console.debug("Failed loading stats:", error);
            return [];
        });
}

type WeeklySummaryData = Omit<WeeklySummary, "issues"> & {
    issues: CoconIssueData[];
}

const getCoconSummary = (coconId: string, from: moment.Moment, to: moment.Moment) => (dispatch: Dispatch) => {
    const action = Actions.LOAD_COCON_SUMMARY;
    dispatch({ action: action, type: Types.BEGIN });

    return fetchAPI(`${urls.API.v3}/cocon/${coconId}/weekly-summary?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`)
        .then((summaryData: WeeklySummaryData) => {
            const summary: WeeklySummary = { 
                ...summaryData, 
                issues: summaryData.issues.map(issueData => new CoconIssue(issueData)),
            };
            dispatch({ type: Types.SUCCESS, action: action, payload: summary });
        })
        .catch(error => {
            dispatch({ type: Types.FAIL, action: action, payload: error });
            console.debug("Failed loading stats:", error);
        });
}

const getClusterSummary = (clusterId: string, from: moment.Moment, to: moment.Moment)=> (dispatch: Dispatch) => {
    const action = Actions.LOAD_CLUSTER_SUMMARY;
    dispatch({action: action, type: Types.BEGIN });

    return fetchAPI(`${urls.API.v3}/cocon/cluster/${clusterId}/summary?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`)
        .then((summaryData : WeeklySummaryData)=> {
            const summary : WeeklySummary = {
                ...summaryData,
                issues: summaryData.issues.map(issueData => new CoconIssue(issueData)),
            };
            dispatch ({type: Types.SUCCESS, action: action, payload: summary});
        })
        .catch(error => {
            dispatch({type: Types.FAIL, action:action, payload:error});
            console.debug("Failed loading stats:", error);
        });
}

const StatsAction = {
    getCoconStats,
    getClusterStats,
    getCoconSummary,
    getClusterSummary,
};

export default StatsAction;