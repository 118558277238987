import { combineReducers } from 'redux';
import ActionResultReducer, { ActionResultInterface } from './actions_result';
import UserReducer, { UserContextInterface } from './user';
import StatsReducer, { StatsContextInterface } from './stats';
import CoconReducer, { CoconContextInterface } from './cocon';
import WasteReportReducer, { WasteReportContextInterface } from './waste_report';
import RewardReducer, { RewardsContextInterface } from './reward';
import CommunicationMediaReducer, { CommunicationMediaContextInterface } from './communication_media';

export interface StoreInterface {
    ActionResultReducer: ActionResultInterface;
    CoconReducer: CoconContextInterface;
    RewardReducer: RewardsContextInterface;
    StatsReducer: StatsContextInterface;
    UserReducer: UserContextInterface;
    WasteReportReducer: WasteReportContextInterface;
    CommunicationMediaReducer: CommunicationMediaContextInterface;
};

export default combineReducers({
    ActionResultReducer,
    CoconReducer,
    RewardReducer,
    StatsReducer,
    UserReducer,
    WasteReportReducer,
    CommunicationMediaReducer,
})