import { useState, FormEvent, useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from 'store/reducers';
import { Box, TextField, Button, InputLabel, FormControl, OutlinedInput, InputAdornment, useMediaQuery, Theme, Checkbox, ListItemText } from '@mui/material';
import ActionButton from 'components/_include/ActionButton';
import { TextInput, FileInput, NullableNumberInput, StringsArrayInput } from 'helpers/forms';
import { RewardFormData } from 'models/Shop/Reward';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import Animation from 'components/_include/Animation';
import theme from 'constants/theme';
import { usePrevious } from 'helpers/refs';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import CoconAction from 'actions/cocon';


const mapStateToProps = (state: StoreInterface) => ({
    clusters: state.CoconReducer.clusters.data,
    loading: state.RewardReducer.selectedReward.loading,
    loadingcluster: state.StatsReducer.loading || state.CoconReducer.cocons.loading,
    error: state.RewardReducer.selectedReward.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadCocon: (coconId: string) => dispatch(CoconAction.retrieveCocon(coconId)),
    loadCluster: (clusterId: string)=> dispatch(CoconAction.retrieveCluster(clusterId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type RewardFormProps = ConnectedProps<typeof connector> & {
    rewardData: RewardFormData;
    submitForm: (data: FormData) => void;
}

function RewardForm(props: RewardFormProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.forms, Namespaces.glossary]);

    const { rewardData, loading, error, clusters, loadingcluster, submitForm, } = props;

    const navigate = useNavigate();

    const initialInputs = {
        partner: {
            value: rewardData.partner,
            error: null,
        },
        description: {
            value: rewardData.description,
            error: null,
        },
        quantity: {
            value: rewardData.quantity,
            error: null,
        },
        suggestedCost: {
            value: rewardData.suggestedCost,
            error: null,
        },
        realPrice: {
            value: rewardData.realPrice,
            error: null,
        },
        image: {
            file: rewardData.image,
            error: null,
        },
        exclusiveCoconsClusters: {
            value: rewardData.exclusiveCoconsClusters,
            error: null,
        },
    };

    const [inputs, setInputs] = useState<{
        partner: TextInput;
        description: TextInput;
        quantity: NullableNumberInput;
        suggestedCost: NullableNumberInput;
        realPrice: NullableNumberInput;
        image: FileInput;
        exclusiveCoconsClusters: StringsArrayInput;
    }>(initialInputs);

    const wasLoading = usePrevious(loading);
    useEffect(() => {
        // after reward successfully saved
        if (wasLoading && !loading && !error) {
            navigate(`/shop`);
        }
    }, [loading, error]);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string | number | boolean | unknown) => {
        // props.resetUserError();

        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleOptionChange = (name: string, value: string[]) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handleImageSelected = (input: HTMLInputElement) => {
        if (input.files && input.files.length > 0) {
            setInputs({
                ...inputs,
                image: {
                    file: input.files[0],
                    error: null,
                },
            });
        }
        else {
            setInputs({
                ...inputs,
                image: {
                    file: null,
                    error: null,
                },
            });
        }
    }

    let { partner, description, quantity, suggestedCost, realPrice, image } = inputs;

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let error = false;

        if (!partner.value) {
            error = true;
            partner.error = "";
        }

        if (!description.value) {
            error = true;
            description.error = "";
        }

        if (error) {
            setInputs({
                ...inputs,
                partner: partner,
                description: description,
            });
        }
        else {
            let rewardData = new FormData();
            rewardData.append('partner', partner.value);
            rewardData.append('description', description.value);
            if (quantity.value || quantity.value === 0) {
                rewardData.append('quantity', quantity.value.toString());
            }
            else {
                rewardData.append('quantity', "999999"); // infinite quantity
            }
            if (suggestedCost.value) {
                rewardData.append('cost', suggestedCost.value.toString());
            }
            if (realPrice.value) {
                rewardData.append('realPrice', realPrice.value.toString());
            }
            if ((inputs.exclusiveCoconsClusters.value?.length ?? 0) > 0) {
                rewardData.append('exclusiveCoconsClusters', JSON.stringify(inputs.exclusiveCoconsClusters.value));
            }
            rewardData.append('image', image.file as Blob);

            // logFormData(rewardData);
            submitForm(rewardData);
        }
    }

    const getPlaceholder = (field: string) => {
        return t(`reward.${field}`, { ns: Namespaces.forms });
    }

    const formIsValid = partner.value !== "" && description.value !== "";

    return (
        <form
            method="post"
            action="#"
            onSubmit={(event) => handleSubmitPressed(event)} >
            <Box>
                <TextField
                    required
                    id="partner-select"
                    label={getPlaceholder("partner")}
                    value={partner.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('partner', event.target.value) }
                    }}
                    error={Boolean(partner.error)}
                    helperText={partner.error}
                />
            </Box>

            <Box>
                <TextField
                    required
                    id="description"
                    margin="normal"
                    multiline
                    rows={3}
                    value={description.value}
                    variant="outlined"
                    label={getPlaceholder("description.label")}
                    placeholder={getPlaceholder("description.placeholder")}
                    InputProps={{
                        onChange: (event) => handleInputChange('description', event.target.value),
                    }}
                    error={Boolean(description.error)}
                    helperText={description.error}
                    sx={{
                        maxWidth: "initial",
                    }}
                />
            </Box>

            <Box>
                <TextField
                    id="quantity-input"
                    sx={{ maxWidth: 200, }}
                    label={getPlaceholder("quantity.label")}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    placeholder={getPlaceholder("quantity.placeholder")}
                    onChange={(event) => handleInputChange('quantity', event.target.value)}
                    value={quantity.value && quantity.value >= 0 ? quantity.value : ""}
                />
            </Box>

            <Box>
                <TextField 
                    label={getPlaceholder("cost.label")}
                    value={suggestedCost.value && suggestedCost.value > 0 ? suggestedCost.value : ""}
                    inputProps={{ 
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                    }} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {getPlaceholder("cost.pts")}
                            </InputAdornment>
                        )
                    }}
                    sx={{ maxWidth: 200 }}
                    onChange={(event) => handleInputChange('suggestedCost', event.target.value)}
                    error={Boolean(suggestedCost.error)}
                    />
            </Box>

            <Box>
                <TextField 
                    label={getPlaceholder("real_price.label")}
                    value={realPrice.value && realPrice.value > 0 ? realPrice.value : ""}
                    inputProps={{ 
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                    }} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {getPlaceholder("real_price.euros")}
                            </InputAdornment>
                        )
                    }}
                    sx={{ maxWidth: 200 }}
                    onChange={(event) => handleInputChange('realPrice', event.target.value)}
                    error={Boolean(realPrice.error)}
                    />
            </Box>

            <Box>
                <FormControl variant="outlined" fullWidth sx={{ mb: "1.5rem" }}>
                    <InputLabel id="cocon-cluster-id-label">{getPlaceholder("exclusiveCoconsClustersIds.label")}</InputLabel>
                    <Select
                        labelId="cocon-cluster-id-label"
                        id="cocon-cluster-id"
                        multiple
                        value={inputs.exclusiveCoconsClusters.value}
                        onChange={(event) => handleOptionChange('exclusiveCoconsClusters', event.target.value as string[])}
                        label={getPlaceholder("exclusiveCoconsClustersIds.label")}
                        renderValue={(selected) => {
                            // Find the corresponding addresses for the selected IDs
                            const selectedAddresses = clusters.filter(cluster => selected.includes(cluster.id)).map(cluster => cluster.displayAddress);
                            return selectedAddresses.join(', ');
                        }}
                    >
                       {loadingcluster ? (
                            <MenuItem value="" disabled>
                               {getPlaceholder("loading_cocons")}
                            </MenuItem>
                        ) : (
                            clusters.map((cluster) => (
                                <MenuItem key={cluster.id} value={cluster.id} >
                                    <Checkbox checked={inputs?.exclusiveCoconsClusters?.value?.includes(cluster.id)} />
                                    <ListItemText primary={cluster.displayAddress} />
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            </Box>           
            <Box>
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
                        backgroundSize: `calc(100% + 100px)`,
                        transition: `background-position .5s`,
                        "&:hover": { backgroundPosition: -100 },
                    }}
                >
                    {image.file ?
                        image.file.name
                        :
                        getPlaceholder("image.select")
                    }
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => { handleImageSelected(event.target) }}
                    />
                </Button>
            </Box>

            <Box
                sx={{
                    textAlign: "center",
                    mt: 4,
                }}
                >
                <ActionButton
                    color="gradient"
                    disabled={!formIsValid}
                    loading={loading}
                    type="submit"
                >
                    {t("submit", { ns: Namespaces.actions })}
                </ActionButton>


                {/* {useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) && (
                    <Box sx={{
                        position: "absolute",
                        right: -20,
                        bottom: (theme) => theme.spacing(-4),
                    }}>
                        <Animation
                            data={require("animations/waving_fatia.json")}
                            width={theme.spacing(50)}
                            height={theme.spacing(50)}
                            loop
                        />
                    </Box>
                )} */}
            </Box>
        </form >
    )
}

export default connector(RewardForm);