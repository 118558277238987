import { ResponsiveBar } from '@nivo/bar';
import { Box, Typography, ToggleButton, useMediaQuery, useTheme, Card } from '@mui/material';
import { ChartsIDs, PatternDefinitions, WasteChartPatterns, WastesChartColors } from 'constants/charts';
import { Namespaces } from 'locales/translations';
import { chartsTheme } from 'constants/charts';
import { useTranslation } from 'react-i18next';
import { StoreInterface } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';
import ChartLoader from './ChartLoader';
import { TrashTypes } from 'constants/trash';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as React from 'react';
import { PieChartSliceInterface } from 'store/reducers/stats';
import { size } from 'lodash';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    data: state.StatsReducer.wastes,
    unsortableTrash: state.StatsReducer.unsortableTrash,
});



const connector = connect(mapStateToProps);

type WastesEvolutionBarChartProps = ConnectedProps<typeof connector> & {
    onButtonClick: () => void;
};

function WastesEvolutionBarChart(props: WastesEvolutionBarChartProps) {
    const { data, timeUnit, unsortableTrash, onButtonClick } = props;

    const { t } = useTranslation([Namespaces.stats, Namespaces.wastes]);

    function handleButtonClick() {
        onButtonClick();
    }

    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isVerySmall = useMediaQuery(theme.breakpoints.down('xs'));

    const dates = data.map(weeklyBatches => {
        return weeklyBatches[ChartsIDs.DATES];
    });

    const [selected, setSelected] = React.useState(false);

    let waste: PieChartSliceInterface[];


    const chartData = data.map(momentStat => {
        return {
            ...momentStat,
            [ChartsIDs.DATES]: t(
                "date",
                {
                    ns: Namespaces.stats,
                    context: timeUnit,
                    date: momentStat[ChartsIDs.DATES],
                }
            ),
        };
    });

    const unosrtableWasteData = chartData
        .map(({ dates, collection_bag, electronic_waste, food_leftovers, glass, mask }) => ({ dates, collection_bag, electronic_waste, food_leftovers, glass, mask }));

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <ChartLoader />
            <ResponsiveBar
                data={selected ? unosrtableWasteData : chartData}
                keys={Object.values(TrashTypes)}
                indexBy={`${[ChartsIDs.DATES]}`}
                margin={{ top: 8, right: 0, bottom: 100, left: isVerySmall ? 0 : 68 }}
                padding={0.3}
                colors={({ id }) => WastesChartColors[id as TrashTypes]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                theme={chartsTheme(isSmall)}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // orient: 'bottom',
                    tickSize: 8,
                    tickPadding: isVerySmall ? 4 : 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    ...(isSmall ? {} : {
                        legend: t(timeUnit, { ns: Namespaces.commons }),
                        legendOffset: 96,
                        legendPosition: 'middle'
                    })

                }}
                axisLeft={isSmall ? null : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    ...(isSmall ? {} : {
                        legend: t("number_of_trashes", { ns: Namespaces.stats }),
                        legendPosition: 'middle',
                        legendOffset: -48,
                    }),
                }}
                gridYValues={5}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="white"
                animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                defs={PatternDefinitions}
                fill={WasteChartPatterns}
                tooltip={({ id: wasteType, index, value, color }) => {
                    return (
                        <Card>
                            <Typography variant="caption" sx={{ padding: 2 }}>
                                {t("date_full", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: dates[index],
                                })}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: color,
                                    padding: 1
                                }}>
                                {t(`${wasteType.toString()}.count`, { ns: Namespaces.wastes, count: value })}
                            </Typography>
                        </Card>
                    );
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    left: 350,
                    bottom: 310,
                    width: 180,
                    justifyContent: "flex-end",
                    alignItems: "self-end",
                }}
            >
                <ToggleButton
                    value="Tous les déchets/Erreurs de tri"
                    selected={selected}
                    onChange={() => {
                        setSelected(!selected);
                    }}
                    onClick={handleButtonClick}
                    title="Tous les déchets/Erreurs de tri"
                >
                    {selected ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    <label style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 5, paddingTop: 3 }} >{selected ? t("display_normal_stats", { ns: Namespaces.actions }) : t("display_filtered_stats", { ns: Namespaces.actions })}</label>
                </ToggleButton>
            </Box>
        </Box>
    );
}

export default connector(WastesEvolutionBarChart);