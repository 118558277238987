import { Divider, Typography, useTheme } from '@mui/material';

interface TextOverDividerProps {
    text: string;
}

function TextOverDivider(props: TextOverDividerProps) {

    const { text } = props;

    return (
        <div 
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}
        >
            <Divider
                light
                style={{
                    width: "100%",
                    position: "absolute"
                }}
            />
            <Typography
                sx={{
                    zIndex: 2,
                    px: 2,
                    backgroundColor: (theme) => theme.palette.background.default,
                    color: (theme) => theme.palette.text.disabled,
                    textTransform: "uppercase"
                }}
            >
                {text}
            </Typography>
        </div>
    );
}


export default TextOverDivider;