import { ResponsiveLine } from '@nivo/line';
import { Box, Card, useTheme, Typography, useMediaQuery } from '@mui/material';
import { ChartsIDs, chartsTheme } from 'constants/charts';
import { StoreInterface } from 'store/reducers';
import { LineChartPointInterface, TimeUnit } from 'store/reducers/stats';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { connect } from 'react-redux';
import ChartLoader from './ChartLoader';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    data: state.StatsReducer.co2_saved,
});

interface CO2SavedProps {
    data: LineChartPointInterface[];
    timeUnit: TimeUnit;
};

function CO2SavedChart(props: CO2SavedProps) {
    let { data, timeUnit, } = props;

    const { t } = useTranslation([Namespaces.stats]);

    const theme = useTheme();

    // responsiveness
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isVerySmall = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
            >
            <ChartLoader />
            <ResponsiveLine
                data={data}
                margin={{ top: 40, right: 16, bottom: 100, left: isVerySmall ? 28 : 64 }}
                colors={(line: any) => line.id === ChartsIDs.CO2_SAVED ? theme.palette.secondary.main : theme.palette.primary.main }
                curve='monotoneX'
                enablePoints={true}
                lineWidth={2}
                enableArea={true}
                areaBlendMode="normal"
                areaOpacity={.2}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                theme={chartsTheme(isSmall)}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // orient: 'bottom',
                    tickSize: 8,
                    tickPadding: isVerySmall ? 4 : 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    legend: isSmall ? "" : t(timeUnit, { ns: Namespaces.commons }),
                    legendOffset: 92,
                    legendPosition: 'middle',
                    format: (value: number | string | Date) =>
                        t("date", {
                            ns: Namespaces.stats,
                            context: timeUnit,
                            date: moment(value),
                        }).toString()
                }}
                axisLeft={{
                    // orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                pointSize={6}
                useMesh={true}
                legends={[
                    {
                        data: [
                            {
                                id: ChartsIDs.CO2_SAVED,
                                label: t("kg_co2_saved_legend", { ns: Namespaces.stats }).toString(),
                                fill: theme.palette.secondary.main,
                                color: theme.palette.secondary.main,
                            },
                            {
                                id: ChartsIDs.CUMULATIVE_CO2_SAVED,
                                label: t("cumulative_kg_co2_saved_legend", { ns: Namespaces.stats }).toString(),
                                fill: theme.palette.primary.main,
                                color: theme.palette.primary.main,
                            },
                        ],
                        anchor: 'top',
                        direction: 'column',
                        justify: false,
                        translateY: -40,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 188,
                        itemHeight: 20,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    }
                ]}
                enablePointLabel={false}
                enableGridY={true}
                tooltip={({ point }) => {
                    if (point.serieId === ChartsIDs.CO2_SAVED) {
                        const date: string | number | Date = point.data.x;
                        const value = Number(point.data.y);
                        return (
                            <Card style={{ paddingTop: 4, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, borderRadius: 2 }}>
                                <Typography variant="caption">
                                    {t("date_full", {
                                        ns: Namespaces.stats,
                                        context: timeUnit,
                                        date: date,
                                    })}
                                </Typography>
                                <Typography variant="body1">
                                    {t("kg_co2_saved", { ns: Namespaces.stats, count: value })}
                                </Typography>
                            </Card>
                        );
                    }
                    else { // cumulated co2
                        const nbUnits = point.index - data[0].data.length + 1;
                        const value = Number(point.data.y);
                        return (
                            <Card style={{ paddingTop: 4, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, borderRadius: 2 }}>
                                <Typography variant="caption">
                                    {t("after", {
                                        ns: Namespaces.stats,
                                        context: timeUnit,
                                        count: nbUnits,
                                    })}
                                </Typography>
                                <Typography variant="body1">
                                    {t("kg_co2_saved", { ns: Namespaces.stats, count: value })}
                                </Typography>
                            </Card>
                        );
                    }
                }}
            />
        </Box>
    );
}

export default connect(mapStateToProps)(CO2SavedChart);