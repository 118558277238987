import { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie-player';

interface AnimationProps {
    data: object;
    loop?: boolean;
    bounce?: boolean;
    width: number | string;
    height: number | string;
    speed?: number;
}

function Animation(props: AnimationProps) {

    const { data, loop, speed, bounce, width, height } = props;

    const [animationState, setAnimationState] = useState<{ play: boolean, direction: 1 | -1 }>({
        play: true,
        direction: 1,
    });

    // if needs to bounce, play the animation after the direction has been inverted
    useEffect(() => {
        if (bounce && !animationState.play) {
            setAnimationState({...animationState, play: true});
        }
    }, [animationState.direction]);

    return (
        <Lottie
            play={animationState.play}
            loop={loop || false}
            speed={speed}
            direction={animationState.direction}
            onComplete={() => {
                if (bounce) {
                    setAnimationState({
                        play: false,
                        direction: animationState.direction === 1 ? -1 : 1
                    });
                }
            }}
            animationData={data}
            style={{ width: width, height: height }}
        />
    );
}

export default Animation;