import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import urls from "../../constants/urls";
import { Namespaces } from "../../locales/translations";

function BackToWebsiteLink() {
    const { t } = useTranslation([Namespaces.actions]);

    return (
        <Link
            to={urls.WEBSITE}
        >
            {t("back_to_website", { ns: Namespaces.actions })}
        </Link>
    );
}

export default BackToWebsiteLink;