import { Box, CircularProgress } from "@mui/material";

type SectionLoaderProps = {
    background?: boolean;
}

function SectionLoader(props: SectionLoaderProps) {
    const { background } = props;

    return (
        <Box 
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                ...(background ? { background: `rgba(255, 255, 255, 0.5)`, } : {}),
            }}
            >
            <CircularProgress />
        </Box>
    );
}

export default SectionLoader;