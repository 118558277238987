import { Card, CardContent, Typography, } from "@mui/material";
import { StoreInterface } from "store/reducers";
import { ChartsIDs } from "constants/charts";
import { connect, ConnectedProps } from "react-redux";
import SectionLoader from "components/_include/SectionLoader";
import { CO2EquivalentEmissions, CO2_EQUIVALENCES } from "constants/emissions";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";

const mapStateToProps = (state: StoreInterface) => ({
    data: state.StatsReducer.co2_saved,
    loading: state.StatsReducer.loading,
});

const connector = connect(mapStateToProps);

type CO2EquivalenceCardProps = ConnectedProps<typeof connector> & {

}

function CO2EquivalenceCard(props: CO2EquivalenceCardProps) {
    const { loading, data } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.stats]);

    // find total amount of co2 saved during period of time
    const cumulativeCO2 = data.find(dataset => dataset.id === ChartsIDs.CUMULATIVE_CO2_SAVED);
    const totalCO2 = cumulativeCO2?.data[cumulativeCO2.data.length - 1].y;

    const equivalences = Object.values(CO2_EQUIVALENCES);
    const category = equivalences[Math.floor(Math.random() * equivalences.length)];
    const equivalence: { equivalence: number, emoji: string } = CO2EquivalentEmissions[category];

    return (
        <Card sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        }}
        >
            {loading || totalCO2 === undefined ? ( // data not loaded yet
                <SectionLoader />
            ) : (
                <CardContent sx={{
                    textAlign: "center"
                }}>
                    <Typography variant="h4" align={'center'} component="p">
                        {t("kg_co2_saved", {
                            ns: Namespaces.stats,
                            context: "integer",
                            count: totalCO2,
                        })}
                    </Typography>
                    <Typography
                        component="p"
                        sx={{
                            fontSize: "6rem",
                            lineHeight: 1,
                            marginTop: 3,
                            marginBottom: 2,
                        }}
                    >
                        {equivalence.emoji}
                    </Typography>
                    <Typography variant="h6" align={'center'} component="p">
                        {t("equivalent_of_prefix", { ns: Namespaces.stats })}
                    </Typography>
                    <Typography variant="h2" align={'center'} component="p">
                        {t("integer", {
                            ns: Namespaces.commons,
                            count: totalCO2 / equivalence.equivalence,
                        })}
                    </Typography>
                    <Typography gutterBottom variant="h6" align={'center'} component="p">
                        {t(`equivalent_of_suffix.${category}`, {
                            ns: Namespaces.stats,
                            count: totalCO2 / equivalence.equivalence
                        })}
                    </Typography>
                </CardContent>
            )}
        </Card>
    )
};

export default connector(CO2EquivalenceCard);
