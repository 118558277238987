import { ResponsiveLine } from '@nivo/line';
import { Card, useTheme, Typography, useMediaQuery, Box } from '@mui/material';
import { ChartsIDs, chartsTheme } from 'constants/charts';
import { StoreInterface } from 'store/reducers';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import ChartLoader from './ChartLoader';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    data: state.StatsReducer.quality,
});

const connector = connect(mapStateToProps);

type SortingQualityChartProps = ConnectedProps<typeof connector> & {

};

function SortingQualityChart(props: SortingQualityChartProps) {
    let { data, timeUnit, } = props;

    const { t } = useTranslation([Namespaces.stats]);

    const theme = useTheme();
    
    // responsiveness
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isVerySmall = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
            >
            <ChartLoader />
            <ResponsiveLine
                data={data}
                margin={{ top: 8, right: 16, bottom: 100, left: isVerySmall ? 40 : 64 }}
                colors={(line: any) => line.id === ChartsIDs.WELL_SORTED_TRASH ? theme.palette.success.main : theme.palette.error.light}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 0, max: 105, stacked: true, reverse: false }}
                theme={chartsTheme(isSmall)}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // orient: 'bottom',
                    tickSize: 8,
                    tickPadding: 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    legendOffset: 92,
                    legendPosition: 'middle',
                    legend: isSmall ? "" : t(timeUnit, { ns: Namespaces.commons }),
                    format: (value: string | number | Date) =>
                        t("date", {
                            ns: Namespaces.stats,
                            context: timeUnit,
                            date: moment(value),
                        }).toString()
                }}
                axisLeft={{
                    // orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: (value: any) => `${value}%`,
                }}
                curve='monotoneX'
                areaBlendMode="normal"
                areaOpacity={.5}
                enablePoints={false}
                lineWidth={2}
                useMesh={true}
                enablePointLabel={false}
                enableGridY={true}
                enableArea={true}
                defs={[
                    {
                        id: 'redToGreen',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: theme.palette.success.main },
                            { offset: 100, color: theme.palette.warning.main },
                        ],
                    },
                ]}
                fill={[
                    {
                        match: {
                            id: ChartsIDs.WELL_SORTED_TRASH
                        },
                        id: 'redToGreen'
                    },
                ]}
                tooltip={({ point }) => {
                    const date = point.data.x;
                    let value = Number(point.data.y);
                    if (point.serieId === ChartsIDs.BADLY_SORTED_TRASH) {
                        value = 100 - value;
                    }

                    return (
                        <Card style={{ paddingTop: 4, paddingBottom: 4, paddingRight: 8, paddingLeft: 8, borderRadius: 2 }}>
                            <Typography variant="caption">
                                {t("date_full", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: date,
                                })}
                            </Typography>
                            <Typography variant="body1">
                                {t("percent_correct_sorting", { ns: Namespaces.stats, count: value })}
                            </Typography>
                        </Card>
                    );
                }}
            />
        </Box>
    );
}

export default connector(SortingQualityChart);