import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Namespaces } from "locales/translations";
import { useLocation } from "react-router-dom";

type DrawerListItemProps = {
    label: string;
    icon: JSX.Element;
    link: string;
    onClose?: () => void;
}

function DrawerListItem(props: DrawerListItemProps) {
    const { label, icon, link, onClose } = props;

    const { t } = useTranslation([Namespaces.titles]);

    const { search, } = useLocation();

    const active = useMatch(link) ? true : false;

    return (
        <Link
            style={{ textDecoration: 'none' }}
            to={link + search}
            onClick={() => { if (onClose) onClose(); }}
        >
            <ListItemButton
                sx={{
                    py: 1.5,
                    color: "rgba(0,0,0,0.8)",
                    '&:hover,&:focus': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                }}
                selected={active}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 'auto',
                        marginRight: 2,
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={t(`cocon_drawer.${label}`, { ns: Namespaces.titles })}
                    primaryTypographyProps={{
                        fontSize: "0.9rem",
                        lineHeight: "1rem",
                    }}
                />
            </ListItemButton>
        </Link>
    );
}

export default DrawerListItem;