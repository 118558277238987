import { TableRow, TableCell, Typography, useTheme } from "@mui/material";
import CoconAction from "actions/cocon";
import { ucFirst } from "helpers/strings";
import { Namespaces } from "locales/translations";
import CoconIssue, { IssueCoconData, IssueStatus } from "models/Cocons/CoconIssue";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from "store/reducers";
import CoconIssueActions from "./CoconIssueActions";
import IssueChip from "./IssueChip";

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
    updateIssueStatus: (cocon: IssueCoconData, issueId: string, status: IssueStatus.FIXING | IssueStatus.FIXED) => dispatch(CoconAction.updateIssueStatus(cocon, issueId, status)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CoconIssueRowProps = ConnectedProps<typeof connector> & {
    issue: CoconIssue;
    loading: boolean;
}

function CoconIssueRow(props: CoconIssueRowProps) {
    const { issue, loading, updateIssueStatus } = props;

    const { t } = useTranslation([Namespaces.issues]);

    const theme = useTheme();

    return (
        <TableRow>
            <TableCell>
                <img 
                    src={issue.pictureURL} 
                    style={{
                        maxWidth: theme.spacing(40),
                    }}
                    />
            </TableCell>
            <TableCell>
                <Typography variant="body1">
                    {ucFirst(t(`${issue.type}`, { ns: Namespaces.issues }))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">
                    {issue.description}
                </Typography>
            </TableCell>
            <TableCell>
                {t(`date`, { ns: Namespaces.issues, date: issue.date })}
            </TableCell>
            <TableCell>
                <IssueChip
                    status={issue.status}
                    />
            </TableCell>
            <TableCell style={{ position: "relative" }}>
                <CoconIssueActions
                    loading={loading}
                    status={issue.status}
                    onClick={(status) => updateIssueStatus(issue.cocon, issue.id, status)}
                />
            </TableCell>
        </TableRow>
    );
}

export default connector(CoconIssueRow);