import { History } from 'history';
import queryString from 'query-string';
import _ from 'lodash';

export function getURLParam(key: string) {
    const queryParams = queryString.parse(window.location.search, {});
    return queryParams[key]?.toString();
}

export function getURLParams(keys: string[]) {
    const queryParams = queryString.parse(window.location.search, {});
    return _.pick(queryParams, keys);
}

export function getURLComponents(url: string) {
    const regex = /\/([a-zA-Z0-9_\-]*)/g;
    let components: string[] = [];
    let m;

    while ((m = regex.exec(url)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        
        // The result can be accessed through the `m`-variable.
        components.push(m[1]);
    }

    return components;
}