import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navigator from "./Navigation/Navigator";
import { StoreInterface } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: StoreInterface) => ({
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CoconSummaryProps = ConnectedProps<typeof connector> & {

}

function SingleCoconLayout(props: CoconSummaryProps) {

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                position: "relative",
                flexGrow: 1,
            }}
        >
            <Navigator />

            <Outlet />
        </Box>
    )
}

export default connector(SingleCoconLayout);