import React from 'react';
import Drawer from './Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Hidden, useTheme } from '@mui/material';

type NavigatorProps = {

}

function Navigator(props: NavigatorProps) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box
            display="flex"
            // component="nav"
            sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/* <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
                }}
            >
                {drawer}
            </Drawer> */}
            <Drawer
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { 
                        width: DRAWER_WIDTH,
                        position: "absolute"
                    },
                }}
                open
            />
        </Box>
    );
}

const DRAWER_WIDTH = 200;

export default Navigator;
