import { createTheme } from '@mui/material/styles';
import { CSSProperties } from "@mui/styles/withStyles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({});

const spacing = (value: number) => `${8 * value}px`;

const palette = {
    primary: {
        main: '#FF3399',
    },
    secondary: {
        main: '#336699',
    },
    text: {
        primary: "rgba(0, 0, 0, 0.9)",
        secondary: "rgba(0, 0, 0, 0.8)",
        disabled: "rgba(0, 0, 0, 0.6)",
    },
    white: {
        main: "#ffffff",
    },
};

const typography = {
    fontFamily: [
        "CircularStd", 
        "Roboto"
    ].join(','),
    root: {
        whiteSpace: "break-spaces",
    },
    h1: {
        fontWeight: 700,
        fontSize: '2rem',
        marginBottom: spacing(4),
        marginTop: spacing(4),
        [breakpoints.up('sm')]: { // not mobile
            fontSize: '3rem',
        },
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.8rem',
        marginBottom: spacing(2),
        marginTop: spacing(2),
        [breakpoints.up('sm')]: { // not mobile
            fontSize: '2.5rem',
        },
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.5rem',
        marginBottom: spacing(2),
        marginTop: spacing(2),
        [breakpoints.up('sm')]: { // not mobile
            fontSize: '2rem',
        },
    },
    h4: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginBottom: spacing(2),
        marginTop: spacing(2),
        [breakpoints.up('sm')]: { // not mobile
            fontSize: '1.3rem',
        },
    },
    h5: {
        fontWeight: 600,
        fontSize: "1.2rem",
        lineHeight: 1.4,
        letterSpacing: 0.5,
    },
    subtitle1: {
        fontStyle: "italic",
    },
    // h1: {
    //     fontWeight: 300,
    // },
    // h5: {
    //     fontWeight: 500,
    //     fontSize: 26,
    //     letterSpacing: 0.5,
    // },
    fontWeightMedium: 500,
};

const tableHead: CSSProperties = {
    backgroundColor: palette.primary.main,
    color: palette.white.main,
    fontSize: 14,
    fontWeight: "bold",
};

const theme = createTheme({
    spacing: spacing,
    palette: palette,
    typography: typography,
    shape: {
        borderRadius: 8,
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1100,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    html: {
                        [breakpoints.down('sm')]: {
                            fontSize: 13,
                        }
                    }
                }
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: 1280,
                    position: "relative",
                    flexGrow: 1,
                    paddingTop: spacing(3),
                    paddingBottom: spacing(4),
                    px: spacing(2),
                    display: "flex",
                    flexDirection: "column",
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                
            }
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    minHeight: spacing(8),
                },
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    zIndex: 1000,
                    margin: 0,
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: "0.5rem",
                    // padding: "1rem 2rem",
                    textTransform: 'none',
                },
                text: {

                },
                contained: {
                    // boxShadow: 'none',
                    // '&:active': {
                    //     boxShadow: 'none',
                    // },
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: spacing(1),
                },
                flexContainer: {
                    height: "100%",
                },
                indicator: {
                    height: 6,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                },
            }
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                labelIcon: {
                    fontFamily: "CircularStd",
                    fontWeight: "bold",
                    fontSize: 18,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: spacing(1),
                },
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0,0,0,0.6)",
                },
                // shrink: {
                //     color: "rgba(0,0,0,0.8)",
                // },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    // backgroundColor: '#404854',
                },
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: typography.fontWeightMedium,
                },
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginRight: 0,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "rgba(0,0,0,0)",
                        color: palette.primary.main,
                        "&:hover,&:focus": {
                            backgroundColor: "rgba(0,0,0,0)",
                        },
                        "& .MuiListItemText-primary": {
                            fontWeight: 600,
                        },
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {

                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: tableHead
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: tableHead
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: "1.5rem",
                    maxWidth: 400,
                    width: '90%',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white.main",
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // marginBottom: "1.5rem",
                    // maxWidth: 400,
                    // width: '90%',
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    position: "absolute",
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: spacing(2),
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    position: "relative",
                },
            }
        },
    },
});

export default theme;

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        white?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        white: true;
    }
}

declare module '@mui/material/ListItem' {
    interface ListItemPropsColorOverrides {
        white: true;
    }
}