import { useState, FormEvent } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { useTheme, TextField, Typography, FormControl, FormLabel, Slider, Container, Grid, styled, Box, FormControlProps, Alert } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import ActionButton from 'components/_include/ActionButton';
import i18next from 'i18next';
import { Namespaces } from 'locales/translations';
import BackToWebsiteLink from 'components/_include/BackToWebsiteLink';
import CoconAction from 'actions/cocon';
import { WasteReportStatus } from 'store/reducers/waste_report';
import { FileInput, NumberInput, TextInput } from 'helpers/forms';
import ImageInput from 'components/_include/ImageInput';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Rating } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const GreyBinsStyledRating = styled(Rating)(() => ({
    iconFilled: {
        color: '#333333',
    },
    iconHover: {
        color: '#333333',
    },
}));

const InputSection = styled(Box)(() => ({
    mb: 2,
}));

const FieldSet = (props: FormControlProps) => (
    <FormControl
        sx={{ maxWidth: "100%" }}
        {...props}
        />
);

const mapStateToProps = (state: StoreInterface) => ({
    status: state.WasteReportReducer.status,
    error: state.WasteReportReducer.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    sendReport: (coconId: string, reportData: FormData) => dispatch(CoconAction.sendWasteReport(coconId, reportData)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type WasteReportPageProps = ConnectedProps<typeof connector> & {

};

interface ReportInputs {
    greyFillFactor: NumberInput;
    sortFillFactor: NumberInput;
    sortError: NumberInput;
    coconIssue: TextInput;
    picture: FileInput;
}

function WasteReportPage(props: WasteReportPageProps) {

    const { status, error, sendReport, } = props;

    const { coconId, clusterId } = useParams();

    const theme = useTheme();

    const { t } = useTranslation([Namespaces.snacks]);

    const [inputs, setInputs] = useState<ReportInputs>({
        greyFillFactor: {
            value: 2.5,
            error: null
        },
        sortFillFactor: {
            value: 2.5,
            error: null
        },
        sortError: {
            value: 0,
            error: null,
        },
        coconIssue: {
            value: "",
            error: null,
        },
        picture: {
            file: null,
            error: null
        },
    });

    const { greyFillFactor, sortFillFactor, sortError, coconIssue, picture } = inputs;

    const [greyFillFactorHover, setGreyFillFactorHover] = useState(-1);
    const [sortFillFactorHover, setSortFillFactorHover] = useState(-1);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string | number) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }

    const handlePictureChange = (file: File) => {
        setInputs({
            ...inputs,
            picture: {
                file: file,
                error: null,
            }
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        if (!coconId && !clusterId) return;

        let error = false;

        if (error) {
            setInputs({
                ...inputs,
                greyFillFactor: greyFillFactor,
                sortFillFactor: sortFillFactor,
                sortError: sortError,
            });
        }
        else {
            let data = new FormData();
            const pic = picture.file;
            if (pic) { // attach picture
                data.append("picture", pic);
            }
            data.append("greyBinFillFactor", greyFillFactor.value.toString());
            data.append("sortBinFillFactor", sortFillFactor.value.toString());
            data.append("sortError", sortError.value.toString());

            if (coconIssue.value) {
                data.append("coconIssue", coconIssue.value);
            }
            if (coconId)
                sendReport(coconId, data);
            else if(clusterId)
                sendReport(clusterId, data)
        }
    }

    const getFieldLabel = (field: string) => {
        return i18next.t(`waste_report.${field}`, { ns: Namespaces.forms });
    }

    const getPercentageText = (value: number) => {
        return `${value}%`;
    }

    if (!coconId && !clusterId) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                }}
            >
                <Alert
                    severity="error"
                    aria-describedby="error-message-id"
                    >
                    <Typography id="error-message-id" variant="body1" >
                        {t("invalid_link", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
                <BackToWebsiteLink />
            </Box>
        );
    }

    if (status === WasteReportStatus.SENT) { // request completed successfully
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                }}
            >
                <Alert
                    severity="success"
                    aria-describedby="message-id"
                    >
                    <Typography id="message-id" variant="body1" >
                        {t("waste_report.success", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
            </Box>
        );
    }

    const formIsValid = true; // enter here conditions that could make the form invalid
    const loading = status === WasteReportStatus.SENDING;


    return (
        <Container
            sx={{
                [theme.breakpoints.up('sm')]: {
                    marginTop: '2rem',
                },
                maxWidth: 1280,
                margin: "0 auto",
                padding: "2rem",
                textAlign: 'center',
            }}
        >
            <Typography variant="h1">
                {i18next.t("waste_report", { ns: Namespaces.titles })}
            </Typography>
            <form
                style={{
                    marginTop: 40,
                }}
                autoComplete="on"
                method="post"
                action="#"
            >
                <InputSection>
                    <FieldSet>
                        <FormLabel
                            sx={{ mb: 1 }}
                            component="legend"
                        >
                            {`${getFieldLabel("fill_factor")}:`}
                        </FormLabel>

                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <GreyBinsStyledRating
                                    name="greyFillFactor"
                                    value={greyFillFactor.value}
                                    precision={0.5}
                                    icon={<DeleteIcon style={{ fontSize: 48 }} />}
                                    onChange={(event, newValue) => {
                                        if (newValue) handleInputChange('greyFillFactor', newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setGreyFillFactorHover(newHover);
                                    }}
                                    style={{}}
                                />
                                <Typography>
                                    {`${getFieldLabel("grey_bins")}: ${i18next.t("bin_count", {
                                        ns: Namespaces.glossary,
                                        count: greyFillFactorHover >= 0 ? greyFillFactorHover : greyFillFactor.value
                                    })}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Rating
                                    name="sortFillFactor"
                                    value={sortFillFactor.value}
                                    precision={0.5}
                                    icon={<DeleteIcon style={{ fontSize: 48 }} />}
                                    onChange={(event, newValue) => {
                                        if (newValue) handleInputChange('sortFillFactor', newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setSortFillFactorHover(newHover);
                                    }}
                                />
                                <Typography>
                                    {`${getFieldLabel("sort_bins")}: ${i18next.t("bin_count", {
                                        ns: Namespaces.glossary,
                                        count: sortFillFactorHover >= 0 ? sortFillFactorHover : sortFillFactor.value
                                    })}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Slider
                            value={fillFactor.value}
                            getAriaValueText={getPercentageText}
                            aria-labelledby="fillFactor-slider"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(e, value) => handleInputChange('fillFactor', Number(value))}
                        /> */}
                    </FieldSet>
                </InputSection>

                <InputSection>
                    <FieldSet
                        error={sortError.error !== null}
                    >
                        <FormLabel
                            sx={{ mb: 1 }}
                            component="legend"
                            id="sortError-slider"
                        >
                            {`${getFieldLabel("sort_error")}: ${sortError.value}%`}
                        </FormLabel>
                        <Slider
                            value={sortError.value}
                            getAriaValueText={getPercentageText}
                            aria-labelledby="sortError-slider"
                            valueLabelDisplay="auto"
                            step={5}
                            min={0}
                            max={100}
                            onChange={(e, value) => handleInputChange('sortError', Number(value))}
                        />
                    </FieldSet>
                </InputSection>

                <InputSection>
                    <FieldSet
                        error={coconIssue.error !== null}
                    >
                        <FormLabel
                            sx={{ mb: 1 }}
                            component="legend"
                            id="coconIssue-slider"
                        >
                            {`${getFieldLabel("cocon_issue")}`}
                        </FormLabel>
                        <TextField
                            variant="outlined"
                            multiline
                            sx={{ 
                                maxWidth: "100%",
                                width: "100%",
                                marginBottom: 0,
                            }}
                            value={coconIssue.value}
                            placeholder={`${getFieldLabel("cocon_issue_placeholder")}`}
                            onChange={(e) => handleInputChange('coconIssue', e.target.value)}
                        />
                    </FieldSet>
                </InputSection>

                <ImageInput
                    image={picture.file}
                    label={getFieldLabel("choose_picture")}
                    changeLabel={getFieldLabel("change_picture")}
                    disabled={loading}
                    onImageSelected={handlePictureChange}
                />

                <Box
                    sx={{
                        textAlign: "center",
                        mt: 5,
                    }}>
                    <ActionButton
                        color="secondary"
                        disabled={!formIsValid || loading}
                        loading={loading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {i18next.t("submit", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>

                {error && (
                    <Alert
                        severity="error"
                        aria-describedby="unknown-error-message-id"
                        >
                        <Typography id="unknown-error-message-id" variant="body1" >
                            {t("unknown_error", { ns: Namespaces.snacks })}
                        </Typography>
                    </Alert>
                )}
            </form>
        </Container>
    )
}

export default connector(WasteReportPage);