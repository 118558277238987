import { ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppBar, Toolbar, Typography, useTheme, Tab, Tabs, useMediaQuery } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useMatch } from "react-router-dom";
import AssignmentIcon from '@mui/icons-material/Assignment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Icon from '@mdi/react'
import { mdiBullhornOutline } from '@mdi/js';

type TabRoute = {
    route: string;
    icon: ReactElement;
    label: string;
}

const ROUTES: TabRoute[] = [
    {
        route: "/cocons",
        icon: <AssignmentIcon />,
        label: "cocons",
    },
    {
        route: "/shop",
        icon: <StorefrontIcon />,
        label: "shop",
    },
    {
        route: "/communication",
        icon: <Icon path={mdiBullhornOutline} size={1} />,
        label: "communication",
    },
];

const mapStateToProps = (state: StoreInterface) => ({
    user: state.UserReducer.user,
    selectedCocon: state.CoconReducer.selectedCocon.data,
    selectedCluster: state.CoconReducer.selectedCluster.data,
});

const mapDispatchToProps = (dispatch: any) => ({

});

type PageState = { tab: string, title: string };

const connector = connect(mapStateToProps, mapDispatchToProps);

type NavigationBarProps = ConnectedProps<typeof connector> & {

}

function NavigationBar(props: NavigationBarProps) {

    const { selectedCocon,selectedCluster } = props;

    const { pathname } = useLocation();
    
    const isCoconsTab = useMatch({ path: "cocons", end: false });
    const isClusterTab = useMatch({path: "clusters", end: false });
    const isSingleClusterPage = useMatch({ path: "clusters/:clusterId", end: false});
    const isSingleCoconPage = useMatch({ path: "cocons/:coconId", end: false });
    const isCoconIssuesPage = useMatch({ path: "cocons/:coconId/issues", end: false });
    const isClustersIssuesPage = useMatch({ path: "clusters/:clusterId/issues", end: false });
    const isShopPage = useMatch({ path: "shop", end: false });
    const isSuggestRewardPage = useMatch({ path: "shop/suggest", end: false });
    const isCommunicationPage = useMatch({ path: "communication", end: false });
    
    const theme = useTheme();
    const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const { t } = useTranslation([Namespaces.titles]);

    const getPageTitle = (page: string) => t(page, { ns: Namespaces.titles });

    const getPageState: () => PageState = () => {
        let tab = "";
        let title = "";

        if (isCoconsTab || isClustersIssuesPage) {
            tab = "/cocons";
            if (isSingleCoconPage || isClustersIssuesPage) {
                if (isCoconIssuesPage || isClustersIssuesPage) {
                    title = `${getPageTitle("cocon_issues")} ${selectedCocon?.address && isUpXl ? t("at_address", { ns: Namespaces.commons, address: selectedCocon.address }) : ""}`;
                }
                else {
                    title = `${getPageTitle("cocon_stats")} ${selectedCocon?.address && isUpXl ? t("at_address", { ns: Namespaces.commons, address: selectedCocon.address }) : ""}`;
                }
            }
            else {
                title = getPageTitle("cocons");
            }
        }
        else if (isShopPage) {
            tab = "/shop";
            if (isSuggestRewardPage) {
                title = getPageTitle("suggest_reward");
            }
            else title = getPageTitle("shop");
        }
        else if (isClusterTab){
            tab = "/cocons";
            if (isSingleClusterPage){
                    title = `${getPageTitle("cluster_stats")} ${selectedCluster?.displayAddress && isUpXl ? t("at_address", { ns: Namespaces.commons, address: selectedCluster.displayAddress }) : ""}`;
            }
        }
        else if (isCommunicationPage) {
            tab = "/communication";
            title = getPageTitle("communication_media");
        }

        return {
            tab: tab,
            title: title,
        };
    };

    const [pageState, setPageState] = useState<PageState>(getPageState());

    useEffect(() => {
        setPageState(getPageState());
    }, [pathname]);

    useEffect(() => {
        if (selectedCocon || selectedCluster) {
            setPageState(getPageState());
        }
    }, [selectedCocon, selectedCluster]);

    return (
        <AppBar
            position="relative"
            color="secondary"
            sx={{
                // height: theme.spacing(10),
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
                [theme.breakpoints.down('md')]: {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
            }}
        >
            <Toolbar>
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{
                        textTransform: "uppercase",
                        // textShadow: `${theme.palette.primary.main} 2px 4px`,
                        marginRight: theme.spacing(4),
                        [theme.breakpoints.down('md')]: {
                            maxWidth: theme.spacing(13),
                            marginRight: 0,
                            fontSize: "1rem",
                            // textShadow: `${theme.palette.primary.main} 1px 2px`,
                        }
                    }}>
                    {pageState.title}
                </Typography>
            </Toolbar>

            <Tabs
                value={pageState.tab}
                aria-label="Navigation principale"
                indicatorColor='primary'
            >
                {ROUTES.map(route => (
                    <Tab
                        key={route.label}
                        value={route.route}
                        icon={route.icon}
                        label={isUpMd ? getPageTitle(route.label) : ""}
                        component={Link}
                        to={route.route}
                        wrapped
                        sx={{
                            my: 0,
                            mx: {
                                xs: 1,
                                md: 2,
                            },
                            minWidth: (theme) => ({
                                xs: theme.spacing(4),
                                sm: theme.spacing(12),
                                md: theme.spacing(22),
                            }),
                            padding: 0,
                            paddingTop: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            color: "rgba(255, 255, 255, 0.7)",
                            "&.Mui-selected": {
                                color: theme.palette.white.main,
                            },
                            "& svg:first-of-type": {
                                marginBottom: ".1rem !important",
                                // WebkitFilter: `drop-shadow(2px 2px 0px ${theme.palette.secondary.main})`,
                                // filter: `drop-shadow(2px 2px 0px ${theme.palette.secondary.main})`,
                                [theme.breakpoints.up('md')]: {
                                    marginRight: theme.spacing(1.5),
                                }
                            }
                        }}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
}

export default connector(NavigationBar);