import { connect, ConnectedProps } from 'react-redux';
import { useTheme, Grid, useMediaQuery, CircularProgress, Typography } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import RewardsAction from 'actions/rewards';
import RewardCard from './RewardCard';
import { useEffect } from 'react';

const mapStateToProps = (state: StoreInterface) => ({
    rewards: state.RewardReducer.rewards.data,
    loading: state.RewardReducer.rewards.loading,
    loadingError: state.RewardReducer.rewards.error,
    next: state.RewardReducer.rewards.next,
    // filters: state.RewardReducer.filters,
});

const mapDispatchToProps = (dispatch: any) => ({
    refreshList: () => dispatch(RewardsAction.reloadRewards()),
    loadMoreRewards: (next: string) => dispatch(RewardsAction.loadMoreRewards(next)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type RewardsListProps = ConnectedProps<typeof connector> & {

}

function RewardsList(props: RewardsListProps) {

    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const { rewards, loading, loadingError, next, refreshList, loadMoreRewards, } = props;

    const GRID_WIDTH = Math.min(1280, window.innerWidth - 24 * 2);
    const NB_COLUMNS = isUpMd
        ? 3
        : ( isUpSm
            ? 2
            : 1
        );
    const CARD_SPACING = 4;

    useEffect(() => {
        if (!rewards.length) { // load list of rewards if not done yet
            refreshList();
        }
    }, []);

    return (
        <Grid
            container
            spacing={4}
            >
             {loading ? (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Grid>
            ) : loadingError ? (
                <Grid item xs={12}>
                    <Typography color="error">Error loading rewards: {loadingError.message}</Typography>
                </Grid>
            ) : (
                rewards.map((reward, index) => (
                    <Grid 
                        item 
                        key={reward.id} 
                        md={4} 
                        sm={6}
                        xs={12}
                    >
                        <RewardCard reward={reward} />
                    </Grid>
                ))
            )}
        </Grid>
    );
}

export default connector(RewardsList);