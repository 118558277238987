import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import CoconsCluster from "models/Cocons/CoconsCluster";
import CoconCard from "./CoconCard";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type CoconsClusterSectionProps = {
    cluster: CoconsCluster;
}

function CoconsClusterSection(props: CoconsClusterSectionProps) {
    const { cluster } = props;

    const { t } = useTranslation([Namespaces.commons, Namespaces.issues]);

    const navigate = useNavigate();

    const navigateToClusterIssues = (clusterId: string) => {
        navigate(`/clusters/${clusterId}/issues`);
    }

    const navigateToClusterSummary = (clusterId: string) => {
        navigate(`/clusters/${clusterId}`)
    }

    return (
        <Grid
            container
            spacing={1}
            mt={1}>
            <Grid
                item
                xs={12}
            >
                <Box
                    display="flex"
                    alignItems="center">
                    <Typography
                        variant="h4"
                        mr={4}
                    >
                        {cluster.displayAddress}
                    </Typography>
                    <Button
                        variant="outlined"
                        color={cluster.pendingIssues > 0 ? "error" : "success"}
                        onClick={() => { navigateToClusterIssues(cluster.id) }}
                    >
                        {t("pending_issue", { ns: Namespaces.issues, count: cluster.pendingIssues })}
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"info"}
                        onClick={() => { navigateToClusterSummary(cluster.id) }}
                    >
                        {t("display_stats", { ns: Namespaces.actions })}
                    </Button>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant="subtitle1"
                    mb={2}
                >
                    {t("users_count", { ns: Namespaces.commons, count: cluster.usersCount })}
                </Typography>
            </Grid>
            {cluster.cocons.map((cocon, index) => (
                <Grid
                    item
                    xs={12}
                    lg={6}
                    key={index}
                >
                    <CoconCard
                        cocon={cocon}
                        cocons_cluster={cluster}
                    />
                </Grid>
            ))}

            <Grid
                item
                xs={12}
                my={2}
            >
                <Divider light />
            </Grid>
        </Grid>
    )
}

export default CoconsClusterSection;