import { Button } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { Namespaces } from 'locales/translations';
import { IssueStatus } from 'models/Cocons/CoconIssue';
import { useTranslation } from 'react-i18next';

interface CoconIssueActionsProps {
    loading: boolean;
    status: IssueStatus;
    onClick: (status: IssueStatus.FIXING | IssueStatus.FIXED) => void;
}

function CoconIssueActions(props: CoconIssueActionsProps) {
    const { loading, status, onClick } = props;

    const { t } = useTranslation([Namespaces.issues]);

    if (loading) {
        return (
            <SectionLoader />
        );
    }

    if (status === IssueStatus.FIXED) {
        return (null);
    }

    if (status === IssueStatus.FIXING) {
        return (
            <Button
                onClick={() => onClick(IssueStatus.FIXED)}
                color="success"
                variant="outlined"
            >
                {t("mark_as", { ns: Namespaces.issues, context: IssueStatus.FIXED })}
            </Button>
        );
    }

    return ( // reported issue
        <div>
            <Button
                onClick={() => onClick(IssueStatus.FIXING)}
                color="info"
                variant="outlined"
            >
                {t("mark_as", { ns: Namespaces.issues, context: IssueStatus.FIXING })}
            </Button>
            <Button
                onClick={() => onClick(IssueStatus.FIXED)}
                color="success"
                variant="outlined"
            >
                {t("mark_as", { ns: Namespaces.issues, context: IssueStatus.FIXED })}
            </Button>
        </div>
    );
}

export default CoconIssueActions;