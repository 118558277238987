import { Box, Card, useTheme, Typography, useMediaQuery } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import { DatedValue, TimeUnit } from 'store/reducers/stats';
import { Trans, useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import moment from 'moment';
import { connect } from 'react-redux';
import ChartLoader from './ChartLoader';
import { ResponsiveBoxPlot } from '@nivo/boxplot'
import { useMemo } from 'react';
import { chartsTheme } from 'constants/charts';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    fillRates: state.StatsReducer.fillRates,
});

interface CO2SavedProps {
    fillRates: DatedValue[];
    timeUnit: TimeUnit;
};

function CO2SavedChart({ fillRates, timeUnit, }: CO2SavedProps) {
    const { t } = useTranslation([Namespaces.stats]);

    const theme = useTheme();

    // responsiveness
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isVerySmall = useMediaQuery(theme.breakpoints.down("xs"));

    const data = useMemo(() => fillRates.map(datedValue => ({
        group: t("date", {
            ns: Namespaces.stats,
            context: timeUnit,
            date: moment(datedValue.x),
        }),
        value: Math.round(datedValue.y * 100),
    })), [fillRates]);

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <ChartLoader />
            <ResponsiveBoxPlot
                theme={{
                    ...chartsTheme(isSmall),
                    translation: {},
                }}
                data={data}
                margin={{ top: 40, right: 16, bottom: 100, left: isVerySmall ? 28 : 64 }}
                minValue={0}
                maxValue={100}
                quantiles={[ 0, 0.25, 0.5, 0.75, 1 ]}
                valueFormat={val => `${Math.round(val)}%`}
                whiskerEndSize={.5}
                borderRadius={2}
                borderWidth={2}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.3
                        ]
                    ]
                }}
                medianColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.3
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 8,
                    tickPadding: isVerySmall ? 4 : 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    legend: isSmall ? "" : t(timeUnit, { ns: Namespaces.commons }),
                    legendOffset: 92,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: tick => `${tick}%`
                }}
                enableGridX={true}
                enableGridY={true}
                tooltip={({ label, formatted, }) => {
                    return (
                        <Card sx={{ py: 1, px: 2, borderRadius: .5, textAlign:"left" }}>
                            <Typography variant="caption">
                                {label}
                            </Typography>
                            <Typography variant="body1" mb={.25}>
                                <Trans
                                    i18nKey="maximum_value"
                                    ns={Namespaces.stats}
                                    values={{ value: formatted.extrema[1] }}
                                    />
                            </Typography>
                            <Typography variant="body1" mb={.25}>
                                <Trans
                                    i18nKey="mean_value"
                                    ns={Namespaces.stats}
                                    values={{ value: formatted.mean }}
                                    />
                                    </Typography>
                            <Typography variant="body1">
                                <Trans
                                    i18nKey="minimum_value"
                                    ns={Namespaces.stats}
                                    values={{ value: formatted.extrema[0] }}
                                    />
                            </Typography>
                        </Card>
                    );
                }}
            />
        </Box>
    );
}

export default connect(mapStateToProps)(CO2SavedChart);