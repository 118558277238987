import { useState, FormEvent } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { TextField, Typography, Alert, Box, } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import UserAction from 'actions/user';
import ActionButton from 'components/_include/ActionButton';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { Namespaces } from '../../../locales/translations';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: StoreInterface) => ({
    userLoading: state.UserReducer.loading,
    userError: state.UserReducer.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    resetPassword: (token: string, email: string, password: string) => dispatch(UserAction.resetPassword(token, email, password)),
    login: (email: string, password: string) => dispatch(UserAction.login(email, password)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ResetPasswordPageProps = ConnectedProps<typeof connector> & {

};

interface DataInputs {
    password: {
        value: string,
        error: string | null
    },
    confirmPassword: {
        value: string,
        error: string | null
    },
}

function ResetPasswordPage(props: ResetPasswordPageProps) {
    const { t } = useTranslation([Namespaces.forms, Namespaces.snacks, Namespaces.actions, Namespaces.titles]);

    const [searchParams] = useSearchParams();
    const token = searchParams.get("t");
    const email = searchParams.get("email");

    const { userLoading, userError, resetPassword, login, } = props;

    const [inputs, setInputs] = useState<DataInputs>({
        password: {
            value: "",
            error: null
        },
        confirmPassword: {
            value: "",
            error: null
        },
    });

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    };

    const onConfirmPasswordDoneChanging = () => {
        // compare to password
        if (inputs.password.value !== inputs.confirmPassword.value) { // mismatch
            setInputs({
                ...inputs,
                confirmPassword: {
                    ...inputs.confirmPassword,
                    error: t("reset_password.confirm_password_mismatch", { ns: Namespaces.forms }),
                },
            });
        }
    };

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        if (!token || !email) return;

        let { password, confirmPassword, } = inputs;
        let error = false;

        if (!password.value) {
            error = true;
            password.error = "";
        }

        if (!confirmPassword.value) {
            error = true;
            confirmPassword.error = "";
        }
        else if (confirmPassword.value !== password.value) {
            error = true;
            confirmPassword.error = t("reset_password.confirm_password_mismatch", { ns: Namespaces.forms });
        }

        if (error) {
            setInputs({
                password: password,
                confirmPassword: confirmPassword,
            });
        }
        else {
            resetPassword(token, email, password.value)
                .then(() => { // log the user in immediatly
                    login(email, password.value);
                });
        }
    }

    const { password, confirmPassword, } = inputs;
    const formIsValid = password.value.length && confirmPassword.value.length && password.value === confirmPassword.value;

    if (!token || !email) {
        return (
            <Box>
                <Alert severity='error'>
                    <Typography variant="body1">
                        {t("invalid_link", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
                <BackToWebsiteLink />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h1" sx={{ mt: 0 }}>
                {t("reset_password", { ns: Namespaces.titles })}
            </Typography>
            <form
                style={{ marginTop: 40 }}
                autoComplete="on"
                method="post"
                action="#"
            >
                {userError && (
                    <Alert severity='error'>
                        <Typography variant="body1">
                            {t("unknown_error", { ns: Namespaces.snacks })}
                        </Typography>
                    </Alert>
                )}

                <TextField
                    id="password"
                    type="password"
                    label={t("reset_password.password", { ns: Namespaces.forms })}
                    value={password.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('password', event.target.value) },
                    }}
                    error={Boolean(password.error)}
                    helperText={password.error}
                    required
                />

                <br />

                <TextField
                    id="confirm-password"
                    type="password"
                    label={t("reset_password.confirm_password", { ns: Namespaces.forms })}
                    value={confirmPassword.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('confirmPassword', event.target.value) },
                        onBlur: onConfirmPasswordDoneChanging,
                    }}
                    error={Boolean(confirmPassword.error)}
                    helperText={confirmPassword.error}
                    required
                />

                <Box textAlign="center">
                    <ActionButton
                        color="primary"
                        disabled={!formIsValid}
                        loading={userLoading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {t("update", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>
            </form>
        </Box>
    )
}

export default connector(ResetPasswordPage);