import { ChartsIDs } from "constants/charts";
import { CO2EquivalentEmissions } from "constants/emissions";
import { CommunicationMedia, CommunicationMediaType, generateTemplateMedia } from "helpers/communication_media";
import { ConnectedProps, connect } from "react-redux";
import { StoreInterface } from "store/reducers";
import CommunicationMediaCard from "./CommunicationMediaCard";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


const mapStateToProps = (state: StoreInterface) => ({
    co2Saved: state.StatsReducer.co2_saved,
    partnerLogo: state.CommunicationMediaReducer.partnerLogo,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);


type CarCommunicationMediaWrapperType = ConnectedProps<typeof connector> & {
}

function CarCommunicationMediaWrapper(props: CarCommunicationMediaWrapperType) {

    const { co2Saved, partnerLogo } = props;

    const cumulativeCO2 = co2Saved.find(dataset => dataset.id === ChartsIDs.CUMULATIVE_CO2_SAVED);
    const totalCO2 = cumulativeCO2?.data[cumulativeCO2.data.length - 1].y;

    const totalKlmCarEq = totalCO2 ? Math.round(totalCO2 / CO2EquivalentEmissions.car_km.equivalence) : 0;

    const [communicationMediaList, setCommunicationMediaList] = useState<CommunicationMedia>();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("selected") === "1") {
            (async () => {
                const communicationMedia = await generateTemplateMedia(
                    CommunicationMediaType.KLM_CAR_EQUIVALENT,
                    "klm_car_equivalent",
                    totalKlmCarEq,
                    "car.png",
                    "Bukhari-Script.ttf",
                    "BukhariScript",
                    partnerLogo
                );
                setCommunicationMediaList(communicationMedia);
            })();
        }
    }, [totalKlmCarEq, searchParams]);

    if (communicationMediaList) {
        return <CommunicationMediaCard media={communicationMediaList} />;
    }
    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(CarCommunicationMediaWrapper);