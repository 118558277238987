import { isNull } from "util";

export enum CO2_EQUIVALENCES {
    BEEF_MEAL = "beef_meal",
    CHIKEN_MEAL = "chicken_meal",
    VEGETARIAN_MEAL = "vegetarian_meal",
    GAS_HEATING_DAY = "gas_heating_day",
    CAR_KM = "car_km",
    HIGH_SPEED_TRAIN_KM = "high_speed_train_km",
    SMARTPHONE = "smartphone",
    COTTON_JEANS = "cotton_jeans",
    POCKET_BOOK = "pocket_book",
    CONVERTIBLE_SOFA = "convertible_sofa",
    COTTON_SHIRT = "cotton_shirt",
    PLANE_KM = "plane_km",
    FORTY_FIVE_INCHES_TV = "45_inches_tv",
    PORTABLE_COMPUTER = "portable_computer",
    TWENTY_FOUR_INCHES_SCREEN = "24_inches_screen",
    A4_PAPER_SHEET = "a4_paper_sheet",
    HOUSEHOLD_WASTE_KG = "household_waste_kg",
    TAP_WATER_LITER = "tap_water_liter",
    BOTTLE_WATER_LITER = "bottle_water_liter",
}

export const CO2EquivalentEmissions = {
    [CO2_EQUIVALENCES.BEEF_MEAL]: {
        equivalence: 7.26,
        unit: null,
        emoji: "🍔",
    },
    [CO2_EQUIVALENCES.CHIKEN_MEAL]: {
        equivalence: 1.58,
        unit: null,
        emoji: "🍗",
    },
    [CO2_EQUIVALENCES.VEGETARIAN_MEAL]: {
        equivalence: 0.51,
        unit: null,
        emoji: "🍎",
    },
    [CO2_EQUIVALENCES.GAS_HEATING_DAY]: {
        equivalence: 18.63,
        unit: "day",
        emoji: "🏘️",
    },
    [CO2_EQUIVALENCES.CAR_KM]: {
        equivalence: 0.193,
        unit: "km",
        emoji: "🚗",
    },
    [CO2_EQUIVALENCES.HIGH_SPEED_TRAIN_KM]: {
        equivalence: 0.00173,
        unit: "km",
        emoji: "🚅",
    },
    [CO2_EQUIVALENCES.PLANE_KM]: {
        equivalence: 0.186,
        unit: "km",
        emoji:  "✈️",
    },
    [CO2_EQUIVALENCES.SMARTPHONE]: {
        equivalence: 16.5,
        unit: null,
        emoji: "📱",
    },
    [CO2_EQUIVALENCES.COTTON_JEANS]: {
        equivalence: 23.2,
        unit: null,
        emoji: "👖",
    },
    [CO2_EQUIVALENCES.POCKET_BOOK]: {
        equivalence: 1.18,
        unit: null,
        emoji: "📚",
    },
    [CO2_EQUIVALENCES.CONVERTIBLE_SOFA]: {
        equivalence: 197,
        unit: null,
        emoji: "🛋",
    },
    [CO2_EQUIVALENCES.COTTON_SHIRT]: {
        equivalence: 5.2,
        unit: null,
        emoji: "👕",
    },
    [CO2_EQUIVALENCES.FORTY_FIVE_INCHES_TV]: {
        equivalence: 371,
        unit: null,
        emoji: "📺",
    },
    [CO2_EQUIVALENCES.PORTABLE_COMPUTER]: {
        equivalence: 156,
        unit: null,
        emoji: "💻",
    },
    [CO2_EQUIVALENCES.TWENTY_FOUR_INCHES_SCREEN]: {
        equivalence: 248,
        unit: null,
        emoji: "🖥️",
    },
    [CO2_EQUIVALENCES.A4_PAPER_SHEET]: {
        equivalence: 0.00458,
        unit: null,
        emoji: "📄",
    },
    [CO2_EQUIVALENCES.HOUSEHOLD_WASTE_KG]: {
        equivalence: 0.386,
        unit: "kg",
        emoji: "🚮",
    },
    [CO2_EQUIVALENCES.TAP_WATER_LITER]: {
        equivalence: 0.000132,
        unit: "liter",
        emoji: "🚰",
    },
    [CO2_EQUIVALENCES.BOTTLE_WATER_LITER]: {
        equivalence: 0.453,
        unit: "liter",
        emoji: "💧",
    },
}