import { Actions, Types } from "constants/actions";

const setPartnerLogo = (partnerLogo: string) => (dispatch: any) => {
    dispatch({ action: Actions.SET_PARTNER_LOGO, type: Types.SUCCESS, payload: partnerLogo });
}

const setJoinCode = (joinCode: string) => (dispatch: any) => {
    dispatch({ action: Actions.SET_JOIN_CODE, type: Types.SUCCESS, payload: joinCode });
}

const CommunicationMediaAction = {
    setPartnerLogo,
    setJoinCode,
};

export default CommunicationMediaAction;