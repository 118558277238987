import { useTheme, Grid, GridProps, Paper, PaperProps } from "@mui/material";

type WrapperProps = GridProps & {
    PaperProps?: PaperProps;
}

function ChartPaperWrapper(props: WrapperProps) {
    const theme = useTheme();

    const { children, PaperProps, ...other } = props;

    return (
        <Grid
            item
            lg={6}
            xs={12}
            sx={{
                mb: 4,
                [theme.breakpoints.up("md")]: {
                    marginBottom: theme.spacing(2),
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
            {...other}
        >
            <Paper
                elevation={4}
                {...PaperProps}
                sx={{
                    px: 3,
                    pt: 2,
                    pb: 10,
                    height: (theme) => theme.spacing(50),
                    ...PaperProps?.sx,
                }}
            >
                {children}
            </Paper>
        </Grid>
    )
}

export default ChartPaperWrapper;