import { Coordinates } from "helpers/geo";
import moment from "moment";
import _ from "lodash";

export enum CoconStatus {
    DEV = "dev",
    PREPARING = "preparing",
    DEPLOYED = "deployed",
}

export type CoconOwner = {
    id: string;
    name: string;
    imageURL: string;
}

export interface CoconData {
    id: string;
    clusterId: string;
    address: string;
    postcode: string;
    city: string;
    coordinates: Coordinates;
    usersCount: number;
    unitsCount?: number;
    batchesCount: number;
    pendingIssues: number;
    status: CoconStatus;
    dateDeployed?: number; // milliseconds timestamp
    joinCode: string;
}

export default class Cocon {
    id: string;
    clusterId: string;
    address: string;
    postcode: string;
    city: string;
    coordinates: Coordinates;
    usersCount: number;
    unitsCount?: number;
    batchesCount: number;
    pendingIssues: number;
    status: CoconStatus;
    dateDeployed?: moment.Moment; // milliseconds timestamp
    joinCode: string;

    constructor(data: CoconData) {
        this.id = data.id;
        this.clusterId = data.clusterId;
        this.address = data.address;
        this.postcode = data.postcode;
        this.city = data.city;
        this.coordinates = data.coordinates;
        this.usersCount = data.usersCount;
        this.unitsCount = data.unitsCount;
        this.batchesCount = data.batchesCount;
        this.pendingIssues = data.pendingIssues;
        this.status = data.status;
        if (data.dateDeployed) {
            this.dateDeployed = moment(data.dateDeployed, 'x');
        }
        this.joinCode = data.joinCode;
    }

    
    public data(): CoconData;
    public data(fields: string[]): Partial<CoconData>;
    public data(fields?: string[]): Partial<CoconData> {
        const data: CoconData = {
            id: this.id,
            clusterId: this.clusterId,
            address: this.address,
            postcode: this.postcode,
            city: this.city,
            coordinates: this.coordinates,
            usersCount: this.usersCount,
            unitsCount: this.unitsCount,
            batchesCount: this.batchesCount,
            pendingIssues: this.pendingIssues,
            status: this.status,
            dateDeployed: this.dateDeployed?.unix(),
            joinCode: this.joinCode,
        };

        return fields ? _.pick(data, fields) : data;
    }

    getAddress = () => {
        return `${this.address}, ${this.postcode} ${this.city}`;
    }
}
