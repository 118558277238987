import { Grid, Typography } from '@mui/material';
import { Contact } from 'constants/contacts';
import ContactsCard from './ContactsCard';

interface ContactsCompanySectionProps {
    company: string,
    contacts: Contact[],
}

function ContactsCompanySection(props: ContactsCompanySectionProps) {
    const { company, contacts } = props;

    return (
        <div>
            <Typography
                variant="h5"
                sx={{
                    marginBottom: 2,
                }}
                >
                {company}
            </Typography>

            <Grid
                container
                spacing={4}
            >
                {contacts.map(contact => (
                    <Grid item xs={12} md={6} key={contact.email}>
                        <ContactsCard
                            contact={contact}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default ContactsCompanySection;