import { Grid, GridProps } from "@mui/material";
import WeeklyBatchesCard from "./WeeklyBatchesCard";
import WeeklyQualityCard from "./WeeklyQualityCard";
import WeeklyUsersCard from "./WeeklyUsersCard";

const CardWrapper = (props: GridProps) => (
    <Grid 
        item
        xs={12}
        sm={4}
        {...props}
        />
);

export default function StatsCardsGrid() {
    return (
        <Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
                flexGrow: 1,
                maxHeight: (theme) => theme.spacing(40)
            }}
        >
            <CardWrapper>
                <WeeklyBatchesCard />
            </CardWrapper>
            <CardWrapper>
                <WeeklyUsersCard />
            </CardWrapper>
            <CardWrapper>
                <WeeklyQualityCard />
            </CardWrapper>
        </Grid>
    );
}
