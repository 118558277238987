import { connect } from 'react-redux';
import { Box, Container } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import RewardsList from './RewardsList';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';
import ActionButton from 'components/_include/ActionButton';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

interface ShopPageProps {

}

function ShopPage(props: ShopPageProps) {

    const { } = props;

    const navigate = useNavigate();

    const { t } = useTranslation([Namespaces.actions]);

    return (
        <Container sx={{ pt: 3 }}>
            <Box 
                sx={{
                    textAlign: "center",
                    mb: 5,
                }}
                >
                <ActionButton
                    color="gradient"
                    icon={<AddIcon />}
                    onClick={() => { navigate("/shop/suggest") }}
                >
                    {t("suggest_reward", { ns: Namespaces.actions })}
                </ActionButton>
            </Box>

            <RewardsList />
        </Container>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);