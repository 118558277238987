import { ResponsiveBar } from '@nivo/bar';
import { Box, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChartsIDs } from 'constants/charts';
import { Namespaces } from 'locales/translations';
import { chartsTheme } from 'constants/charts';
import { useTranslation } from 'react-i18next';
import { StoreInterface } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';
import ChartLoader from './ChartLoader';

const mapStateToProps = (state: StoreInterface) => ({
    timeUnit: state.StatsReducer.data.timeUnit,
    data: state.StatsReducer.activeUsers,
});

const connector = connect(mapStateToProps);

type ActiveUsersBarChartProps = ConnectedProps<typeof connector> & {

};

interface ColorsInterface {
    [dataName: string]: string,
};

function ActiveUsersBarChart(props: ActiveUsersBarChartProps) {
    const { data, timeUnit } = props;

    const { t } = useTranslation([Namespaces.stats]);
    
    const theme = useTheme();

    const colors: ColorsInterface = { [ChartsIDs.USERS_COUNT]: theme.palette.primary.main };
    const getColor = (line: any) => { return colors[line.id] };

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isVerySmall = useMediaQuery(theme.breakpoints.down('xs'));

    const dates = data.map(weeklyBatches => {
        return weeklyBatches[ChartsIDs.DATES];
    });

    const chartData = data.map(momentStat => {
        return {
            [ChartsIDs.DATES]: t(
                "date",
                {
                    ns: Namespaces.stats,
                    context: timeUnit,
                    date: momentStat[ChartsIDs.DATES],
                }
            ),
            [ChartsIDs.USERS_COUNT]: momentStat[ChartsIDs.USERS_COUNT],
        };
    });

    return (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <ChartLoader />
            <ResponsiveBar
                data={chartData}
                keys={[ChartsIDs.USERS_COUNT]}
                indexBy={`${[ChartsIDs.DATES]}`}
                margin={{ top: 20, right: 0, bottom: 100, left: isVerySmall ? 0 : 68 }}
                padding={0.3}
                colors={getColor}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                theme={chartsTheme(isSmall)}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // orient: 'bottom',
                    tickSize: 8,
                    tickPadding: isVerySmall ? 4 : 8,
                    tickRotation: isVerySmall ? -90 : -45,
                    ...(isSmall ? {} : {
                        legend: t(timeUnit, { ns: Namespaces.commons }),
                        legendOffset: 92,
                        legendPosition: 'middle'
                    })

                }}
                axisLeft={isSmall ? null : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    ...(isSmall ? {} : {
                        legend: t("summary.active_users", { ns: Namespaces.stats }),
                        legendPosition: 'middle',
                        legendOffset: -48,
                    }),
                }}
                gridYValues={5}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="white"
                animate={true}
                tooltip={({ index, value }) => (
                    <Card sx={{ px: 1, py: .5, borderRadius: .5 }}>
                            <Typography variant="caption">
                                {t("date_full", {
                                    ns: Namespaces.stats,
                                    context: timeUnit,
                                    date: dates[index],
                                })}
                            </Typography>
                            <Typography variant="body1">
                                {t("active_users", { ns: Namespaces.stats, count: value })}
                            </Typography>
                    </Card>
                )}
            />
        </Box>
    );
}

export default connector(ActiveUsersBarChart);