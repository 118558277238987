import { Alert, Typography } from '@mui/material';
import StatTextTemplate from 'components/_include/Layout/StatTextTemplate';
import { Namespaces } from 'locales/translations';
import { IssueStatus } from 'models/Cocons/CoconIssue';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';
import { Link } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.CoconReducer.coconIssues.loading,
    issues: state.StatsReducer.coconWeeklySummary.issues,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReportedIssuesTextProps = ConnectedProps<typeof connector> & {

}

function ReportedIssuesText(props: ReportedIssuesTextProps) {
    const { issues, loading } = props;

    const { t } = useTranslation([Namespaces.issues, Namespaces.stats,]);

    const reportedIssuesCount = issues.filter(issue => issue.status === IssueStatus.REPORTED).length;
    const fixingIssuesCount = issues.filter(issue => issue.status === IssueStatus.FIXING).length;

    let body: JSX.Element[] = [];
    if (reportedIssuesCount > 0) {
        body.push(
            <Alert key="reported_issues" severity='warning' sx={{ my: 1 }}>
                <Typography variant="body1" >
                    {t("reported_issue", { ns: Namespaces.issues, count: reportedIssuesCount })}
                </Typography>
            </Alert>
        );
    }
    if (fixingIssuesCount > 0) {
        body.push(
            <Alert key="fixing_issues" severity='info' sx={{ my: 1 }}>
                <Typography variant="body1">
                    {t("fixing_issue", { ns: Namespaces.issues, count: fixingIssuesCount })}
                </Typography>
            </Alert>
        );
    }
    if (!reportedIssuesCount && !fixingIssuesCount) {
        body.push(
            <Alert key="no_issue" severity='success' sx={{ my: 1 }}>
                <Typography variant="body1">
                    {t("all_good", { ns: Namespaces.issues })}
                </Typography>
            </Alert>
        );
    }

    return (
        <StatTextTemplate
            title={t("summary.reported_issues", { ns: Namespaces.stats })}
            loading={loading}
            body={
                <Link
                    to={"issues"}
                >
                    {body}
                </Link>
            }
        />
    );
}

export default connector(ReportedIssuesText);