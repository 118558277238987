import { useEffect, } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { Alert, Box, Typography } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import UserAction from 'actions/user';
import SectionLoader from '../../_include/SectionLoader';
import { usePrevious } from '../../../helpers/refs';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../locales/translations';
import { CONFLICT_ERROR } from '../../../constants/api_errors';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { useSearchParams } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({
    userLoading: state.UserReducer.loading,
    userError: state.UserReducer.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    verifyEmailAddress: (token: string) => dispatch(UserAction.verifyEmailAddress(token)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type VerifyEmailPageProps = ConnectedProps<typeof connector> & {

}

function VerifyEmailPage(props: VerifyEmailPageProps) {

    const { userLoading, userError, verifyEmailAddress } = props;

    const [searchParams] = useSearchParams();

    const { t } = useTranslation([Namespaces.snacks]);

    const token = searchParams.get("t");

    useEffect(() => {
        if (token) {
            verifyEmailAddress(token);
        }
    }, []);

    const wasLoading = usePrevious(userLoading);

    if (!token) {
        return (
            <Box>
                <Alert
                    severity="error"
                >
                    <Typography variant="body1" >
                        {t("invalid_link", { ns: Namespaces.snacks })}
                    </Typography>
                </Alert>
                <BackToWebsiteLink />
            </Box>
        );
    }

    if (wasLoading && !userLoading) { // request to verify email address done
        if (!userError) { // request completed successfully
            return (
                <Box>
                    <Alert
                        severity="success"
                    >
                        <Typography variant="body1" >
                            {t("verify_email.success", { ns: Namespaces.snacks })}
                        </Typography>
                    </Alert>
                    <BackToWebsiteLink />
                </Box>
            );
        }
        else { // something went wrong
            return (
                <Box>
                    <Alert
                        severity={userError.code === CONFLICT_ERROR.code ? "warning" : "error"}
                    >
                        <Typography variant="body1" >
                            {t(userError.message, { ns: Namespaces.snacks })}
                        </Typography>
                    </Alert>
                    <BackToWebsiteLink />
                </Box>
            );
        }
    }

    return (
        <Box>
            <SectionLoader />
        </Box>
    );
}

export default connector(VerifyEmailPage)