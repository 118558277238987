export const BAD_REQUEST_ERROR = {
    code: 400,
    message: "Bad request"
};

export const UNAUTHORIZED_ERROR = {
    code: 401,
    message: "Unauthorized"
};

export const NOT_FOUND_ERROR = {
    code: 404,
    message: "Not Found"
};

export const CONFLICT_ERROR = {
    code: 409,
    message: "Conflict"
};

export const FIREBASE_WRONG_PASSWORD = "auth/wrong-password";