import { ucFirst } from "helpers/strings";
import i18next from "i18next";
import moment from "moment";
import { initReactI18next } from 'react-i18next';

const momentLocales = {
    fr: require('moment/locale/fr'),
    en: require('moment/locale/en-gb'),
};

const getMacrolanguage = (locale: string) => {
    if (locale.indexOf('-') === -1) return locale;
    return locale.substr(0, locale.indexOf('-'))
}

export enum Locales {
    FRENCH = "fr",
    // ENGLISH = "en",
}

export enum Namespaces {
    actions = 'actions',            // labels for buttons (eg "filter", "confirm")
    commons = 'commons',            // common words (eg "points", ...)
    explanations = 'explanations',  // explanations (eg "Vous pouvez utiliser ...")
    forms = 'forms',                // forms placeholders/labels (eg "Entrez votre adresse...")
    glossary = 'glossary',          // ficha specific words (eg "cocon"...)
    issues = 'issues',              // reported issues (eg "encombrant"...)
    snacks = 'snacks',              // messages for the global snackbar (eg "Action successful!")
    stats = 'stats',                // eg "kg of CO2"...
    titles = 'titles',              // eg "Chats", "Statistiques"...
    wastes = 'wastes',              // types of waste (eg "plastic bottle", ...)
}

const resources = {
    fr: {
        [Namespaces.actions]: require('./fr/actions.json'),
        [Namespaces.commons]: require('./fr/commons.json'),
        [Namespaces.explanations]: require('./fr/explanations.json'),
        [Namespaces.forms]: require('./fr/forms.json'),
        [Namespaces.glossary]: require('./fr/glossary.json'),
        [Namespaces.issues]: require('./fr/issues.json'),
        [Namespaces.snacks]: require('./fr/snacks.json'),
        [Namespaces.stats]: require('./fr/stats.json'),
        [Namespaces.titles]: require('./fr/titles.json'),
        [Namespaces.wastes]: require('./fr/wastes.json'),
    }
}

export const format = (value: any, format: string | undefined, lng: string | undefined) => {
    if (format === "integer") {
        if (value > 1) { // large number as integer
            return new Intl.NumberFormat(lng, {
                style: 'decimal',
                maximumFractionDigits: 0,
            }).format(value);
        }
        else { // eg 0.55
            return new Intl.NumberFormat(lng, {
                style: 'decimal',
                maximumFractionDigits: 2,
            }).format(value);
        }
    }
    if (format === "number") {
        return new Intl.NumberFormat(lng, {
            style: 'decimal',
            maximumFractionDigits: 2,
        }).format(value);
    }
    if (format === "currency" || format === "rounded_currency") {
        const fractionDigits = format === "rounded_currency" ? 0 : 2;
        return value.toLocaleString(lng, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
    }
    if (format === "date") {
        // let macrolanguage = "fr"; // default to french
        // if (lng) {
        //     macrolanguage = getMacrolanguage(lng);
        // }

        // moment.locale("fr", momentLocales.fr); // default to french

        return moment(value).format("LL");
    }
    if (format === "weekday") {
        return moment(value).format("dddd");
    }
    if (format === "day") {
        return moment(value).format("ddd D MMM");
    }
    if (format === "day_full") {
        return ucFirst(moment(value).format("dddd D MMMM"));
    }
    if (format === "week") {
        return `${moment(value).startOf("week").format("D MMM")}-${moment(value).endOf("week").format("D MMM")}`;
    }
    if (format === "week_full") {
        return `Semaine du ${moment(value).startOf("week").format("D MMMM")} au ${moment(value).endOf("week").format("D MMMM")}`;
    }
    if (format === "month") {
        return ucFirst(moment(value).format("MMMM"));
    }
    return value;
}

export const configureTranslations = () => {
    i18next
        .use(initReactI18next)
        .init({
            resources,
            fallbackLng: 'fr',
            supportedLngs: Object.values(Locales),
            load: 'languageOnly',
            // debug: true,
            interpolation: {
                format: format,
            }
        });
}