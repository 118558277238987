import { Box, Card, CardContent, Theme, Typography, } from "@mui/material";
import { ResponsiveStyleValue, SxProps } from "@mui/system";
import SectionLoader from "components/_include/SectionLoader";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";

type TextTemplateProps = {
    title: string;
    loading: boolean;
    body: string | JSX.Element | JSX.Element[];
}

function StatTextTemplate(props: TextTemplateProps) {
    const { title, loading, body, } = props;

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: (theme) => theme.spacing(24),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Typography
                variant="body1"
                fontWeight="bold"
                color="secondary"
                sx={{
                    mb: 1,
                }}
            >
                {title}
            </Typography>
            {loading ? (
                <SectionLoader />
            ) : (
                <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    {body}
                </Box>
            )}
        </Box>
    );
}

export default StatTextTemplate;