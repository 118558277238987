import {  } from '@mui/material';
import IconValueCardTemplate from 'components/_include/Layout/IconValueCardTemplate';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
    rewardsList: state.StatsReducer.data.purchases,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type RewardedUsersCardProps = ConnectedProps<typeof connector> & {

}

function RewardedUsersCard(props: RewardedUsersCardProps) {
    const { loading, rewardsList } = props;
    
    const { t } = useTranslation([Namespaces.commons, Namespaces.stats]);

    let usersList : Array<string> = [] ;
    rewardsList.forEach(rewards => {
        if ((rewards.user.firstName) && (rewards.user.lastName)) {
          usersList.push(`${rewards.user.firstName} ${rewards.user.lastName}`);
        }
        else {
            usersList.push(t("unauthenticated_user", { ns: Namespaces.commons }));
        }
    });
    const uniqueUserListArray = [... new Set(usersList)];

    const namesList = uniqueUserListArray.map((item)=> <li style={{padding : 7}} key={item}><b>{item}</b></li>)

    return (
        <IconValueCardTemplate
            text={t("rewarded_users", { ns: Namespaces.stats, count: uniqueUserListArray.length })}
            Icon={LocalActivityIcon}
            loading={loading}
            value={uniqueUserListArray.length}
            color={(theme) => theme.palette.primary.main}
            swipe={true}
            namesList={namesList}
            />
    );
}

export default connector(RewardedUsersCard);