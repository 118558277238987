import { ChartsIDs } from "constants/charts";
import { CO2EquivalentEmissions } from "constants/emissions";
import urls from "constants/urls";
import i18next from "i18next";
import { jsPDF } from "jspdf";
import { Namespaces, format } from "locales/translations";
import { StatsContextInterface } from "store/reducers/stats";
import storage, { StorageType } from "../storage";
import { title } from "process";

export enum CommunicationMediaType {
    CO2_SAVED = "co2_saved",
    WATER_SAVED = "water_saved",
    USAGE_NOTICE = "usage_notice",
    KLM_CAR_EQUIVALENT = "klm_car_equivalent",
    COMPUTER_PRODUCTION_EQUIVALENT = "computer_production_equivalent",
    PHONE_PRODUCTION_EQUIVALENT = "phone_production_equivalent",
    TSHIRT_PRODUCTION_EQUIVALENT = "tshirt_production_equivalent",
    MASK = "mask",
    GLASS = "glass",
    FOOD_LEFTOVERS = "food_leftovers",
    ELECTRONIC_WASTE = "electronic_waste",
    COLLECTION_BAG = "collection_bag",
};


export const COMMUNICATION_MEDIA_LIST = [
    CommunicationMediaType.CO2_SAVED,
    CommunicationMediaType.KLM_CAR_EQUIVALENT,
    CommunicationMediaType.COMPUTER_PRODUCTION_EQUIVALENT,
    CommunicationMediaType.PHONE_PRODUCTION_EQUIVALENT,
    CommunicationMediaType.TSHIRT_PRODUCTION_EQUIVALENT,
    CommunicationMediaType.MASK,
    CommunicationMediaType.GLASS,
    CommunicationMediaType.FOOD_LEFTOVERS,
    CommunicationMediaType.ELECTRONIC_WASTE,
    CommunicationMediaType.COLLECTION_BAG,
]

export type Placeholder = { // text for the placeholder (e.g. "103kg")
    label?: string;
    color?: string | [number, number, number];
    opacity?: number;
    font?: {
        path: string;
        name: string;
        style: string;
        weight?: string | number;
    };
    size?: number;
    x?: number;
    y?: number;
    options?: Object;
};

type Image = {
    src: string; // link to the static image
    orientation?: "l" | "p";
    format: "jpeg" | "png";
    x?: number; // coordinates to place the image
    y?: number;
    width: number; // width of the image in px
    height: number;
    url?: string,
};

export interface CommunicationMedia {
    id: CommunicationMediaType;
    label: string; // human-friendly name of the media
    images: Image[],
    placeholders: Placeholder[];
}

export function getCommunicationMediaList(t: any, statsReducer: StatsContextInterface): CommunicationMedia[] {
    const cumulativeCO2 = statsReducer.co2_saved.find(dataset => dataset.id === ChartsIDs.CUMULATIVE_CO2_SAVED);
    const totalCO2 = cumulativeCO2?.data[cumulativeCO2.data.length - 1].y;

    const totalLitersWater = totalCO2 ? Math.round(totalCO2 / CO2EquivalentEmissions.tap_water_liter.equivalence) : undefined;

    return [
        {
            id: CommunicationMediaType.CO2_SAVED,
            label: t("co2_saved", { ns: Namespaces.stats }),
            images: [{
                src: `${urls.IMAGES_FOLDER}/congrats_co2_saved.png`,
                width: 2000,
                height: 1414,
                format: "png",
            }],
            placeholders: [
                {
                    label: t("kg", { ns: Namespaces.stats, context: "integer", count: totalCO2 }),
                    color: [0, 0, 0,],
                    opacity: 0.4,
                    font: {
                        path: `${urls.FONTS_FOLDER}/CircularStd-Black.ttf`,
                        name: 'CircularStd',
                        style: 'normal',
                        weight: 'Black',
                    },
                    size: 240,
                    x: 1410,
                    y: 1235,
                    options: {
                        align: "center",
                    },
                },
                {
                    label: t("kg", { ns: Namespaces.stats, context: "integer", count: totalCO2 }),
                    color: "#FFFFFF",
                    font: {
                        path: `${urls.FONTS_FOLDER}/CircularStd-Black.ttf`,
                        name: 'CircularStd',
                        style: 'normal',
                        weight: 'Black',
                    },
                    size: 240,
                    x: 1395,
                    y: 1220,
                    options: {
                        align: "center",
                    },
                },
            ],
        },
        {
            id: CommunicationMediaType.WATER_SAVED,
            label: t("water_saved", { ns: Namespaces.stats }),
            images: [{
                src: `${urls.IMAGES_FOLDER}/water_saved.png`,
                width: 2000,
                height: 1414,
                format: "png",
            }],
            placeholders: [
                {
                    label: t("integer", { ns: Namespaces.commons, count: totalLitersWater }),
                    color: [0, 0, 0,],
                    opacity: 0.4,
                    font: {
                        path: `${urls.FONTS_FOLDER}/CircularStd-Black.ttf`,
                        name: 'CircularStd',
                        style: 'normal',
                        weight: 'Black',
                    },
                    size: 240,
                    x: 1015,
                    y: 500,
                    options: {
                        align: "center",
                    },
                },
                {
                    label: t("integer", { ns: Namespaces.commons, count: totalLitersWater }),
                    color: "#FFFFFF",
                    font: {
                        path: `${urls.FONTS_FOLDER}/CircularStd-Black.ttf`,
                        name: 'CircularStd',
                        style: 'normal',
                        weight: 'Black',
                    },
                    size: 240,
                    x: 1000,
                    y: 485,
                    options: {
                        align: "center",
                    },
                },
            ],
        },
        {
            id: CommunicationMediaType.USAGE_NOTICE,
            label: t("usage_notice", { ns: Namespaces.titles }),
            images: [{
                src: `${urls.IMAGES_FOLDER}/usage_notice.png`,
                width: 1417,
                height: 569,
                format: "png",
            }],
            placeholders: [],
        },
    ];
}

enum ImageType {
    CO2 = "co2.png",
    CAR = "car.png"
}


export async function generateUnsortableWasteMedia(id: CommunicationMediaType, titleLabel: string, imageURL: string): Promise<CommunicationMedia> {
    const template = await storage.downloadFile(StorageType.REPORTS, imageURL);
    const templateURL = await storage.getUrl(StorageType.REPORTS, imageURL);

    return {
        id: id,
        label: titleLabel,
        images: [{
            src: template.toString("base64"),
            orientation: 'p',
            width: 1000,
            height: 1500,
            format: "png",
            url: templateURL,
        }],
        placeholders: [],
    }

}

export async function generateTemplateMedia(communicationMediaId: CommunicationMediaType, titleLabel: string, value: number, imageURL: string, fontURL: string, fontName: string, partnerLogo: string): Promise<CommunicationMedia> {
    const template = await storage.downloadFile(StorageType.TEMPLATES, imageURL);
    const label = format(value, "integer", i18next.language).replace(/ /g, " ");
    const templateURL = await storage.getUrl(StorageType.TEMPLATES, imageURL);

     // Define the original and new dimensions for the partner logo
     const originalWidth = 260;
     const originalHeight = 120;
     const newWidth = originalWidth * 1.5;
     const newHeight = originalHeight * 1.5;
 
     // Adjust the x and y coordinates to keep the logo centered
     const originalX = 870;
     const originalY = 1185;
     const newX = originalX - (newWidth - originalWidth) / 2;
     const newY = originalY - (newHeight - originalHeight) / 2;

    return {
        id: communicationMediaId,
        label: i18next.t(titleLabel, { ns: Namespaces.stats }),
        images: [
            {
                src: template.toString("base64"),
                orientation: "l",
                width: 2000,
                height: 1414,
                format: "png",
                url: templateURL
            },
            {
                src: partnerLogo,
                format: "png",
                width: newWidth,
                height: newHeight,
                x: newX,
                y: newY,
            }
        ],
        placeholders: [
            {
                label: (imageURL === ImageType.CO2) ? `${label} Kg` : (imageURL === ImageType.CAR) ? `${label} Km` : label,
                color: "#ffcc33",
                opacity: 1,
                font: {
                    path: `${urls.FONTS_FOLDER}/${fontURL}`,
                    name: fontName,
                    style: 'normal',
                },
                size: 240,
                x: 1000,
                y: 850,
                options: {
                    align: "center",
                },
            },
        ],
    }
}

export function generatePDF(media: CommunicationMedia) {

    const { images, placeholders } = media;

    const backgroundImage = images[0];

    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
        orientation: backgroundImage.orientation,
        unit: "px",
        format: [backgroundImage.width, backgroundImage.height],
    });

    for (let image of images) {
        doc.addImage(image.src, image.format ?? "png", image.x ?? 0, image.y ?? 0, image.width, image.height);
    }

    let addedFonts: string[] = [];

    media.placeholders.forEach(placeholder => {
        const { label, color, opacity, font, size, x, y, options } = placeholder;

        if (font) { // check custom font has been added to document
            const fontString = `${font.name}-${font.style}-${font.weight}`
            if (addedFonts.indexOf(fontString) < 0) { // no style of this font added
                addedFonts.push(fontString);
                doc.addFont(font.path, font.name, font.style, font.weight);
            }
            doc.setFont(font.name, font.style, font.weight);
        }
        if (size)
            doc.setFontSize(size);

        if (Array.isArray(color)) {
            if (color.length == 3) doc.setTextColor(color[0], color[1], color[2]); // RGB
            else doc.setTextColor(color[0], color[1], color[2], color[3]); // RGBA
        }
        else if (color) {
            doc.setTextColor(color); // HEX
        }
        // const initialGState = doc.GState;
        if (opacity) {
            doc.setGState(doc.GState({ opacity: opacity }));
        }
        if (label && x && y) {
            doc.text(label, x, y, options);
        }
        if (opacity) { // restore initial opacity
            doc.setGState(doc.GState({ opacity: 1 }));
        }
    });

    doc.save("support_communication_ficha.pdf");
}