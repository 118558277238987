import { StoreInterface } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Container, Grid, GridProps, } from '@mui/material';
import SectionLoader from "components/_include/SectionLoader";
import NameAndSelectHeader from "../NameAndSelectHeader";
import CoconStatsFilters from "../CoconStatsFilters";
import ChartPaperWrapper from "components/Statistics/ChartPaperWrapper";
import ChartTitle from "components/Statistics/Charts/ChartTitle";
import ActiveUsersBarChart from "components/Statistics/Charts/ActiveUsersBarChart";
import MistakenUsersBarChart from "components/Statistics/Charts/MistakenUsersBarChart";
import OrderedRewardsCard from "./OrderedRewardsCard";
import RewardedUsersCard from "./RewardedUsersCard";
import AddIcon from "@mui/icons-material/Add";
import ActionButton from "components/_include/ActionButton";

const CardWrapper = (props: GridProps) => (
    <Grid
        item
        xs={12}
        sm={4}
        {...props}
    />
);

const mapStateToProps = (state: StoreInterface) => ({
    coconLoading: state.CoconReducer.selectedCocon.loading,
    purchases: state.StatsReducer.data.purchases,
});

const mapDispatchToProps = () => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type UsersStatsProps = ConnectedProps<typeof connector> & {

}

function UsersStatsPage(props: UsersStatsProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.stats, Namespaces.titles,])

    const { coconLoading, purchases, } = props;

    console.debug("purchases", purchases);

    return (
        coconLoading ? (
            <SectionLoader />
        ) : (
            <Container>
                <NameAndSelectHeader />

                <CoconStatsFilters />

                <Grid container spacing={4}>
                    <ChartPaperWrapper>
                        <ChartTitle>
                            {t("summary.active_users", { ns: Namespaces.stats })}
                        </ChartTitle>
                        <ActiveUsersBarChart />
                    </ChartPaperWrapper>
                    <ChartPaperWrapper>
                        <ChartTitle>
                            {t("mistaken_users", { ns: Namespaces.stats })}
                        </ChartTitle>
                        <MistakenUsersBarChart />
                    </ChartPaperWrapper>
                    <CardWrapper>
                        <OrderedRewardsCard />
                    </CardWrapper>
                    <CardWrapper>
                        <RewardedUsersCard />
                    </CardWrapper>
                    <CardWrapper 
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            top: 80
                        }}
                        >
                        <ActionButton
                            color="gradient"
                            icon={<AddIcon />}
                            to="/shop/suggest"
                        >
                            {t("suggest_reward", { ns: Namespaces.actions })}
                        </ActionButton>
                    </CardWrapper>
                </Grid>
            </Container>
        )

    )
};

export default connector(UsersStatsPage);