import { useState, FormEvent, useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import UserAction from '../../actions/user';
import ActionButton from '../_include/ActionButton';
import { StoreInterface } from 'store/reducers';
import { isValidEmail } from 'helpers/strings';
import { Actions, Types } from 'constants/actions';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import TextOverDivider from 'components/_include/TextOverDivider';
import { TextInput } from 'helpers/forms';
import { Box, Container, TextField, Typography, } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const mapStateToProps = (state: StoreInterface) => ({
    userLoading: state.UserReducer.loading,
    userError: state.UserReducer.error,
});

const mapDispatchToProps = (dispatch: any) => ({
    login: (email: string, password: string) => dispatch(UserAction.login(email, password)),
    signInWithToken: (inviteToken: string) => dispatch(UserAction.signInWithToken(inviteToken)),
    resetUserError: () => dispatch({ type: Types.SUCCESS, action: Actions.RESET_USER_ERROR }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginPageProps = ConnectedProps<typeof connector> & {

}

enum LoginMethods {
    CREDENTIALS,
    INVITATION,
}

function LoginPage(props: LoginPageProps) {

    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.titles]);

    const { userLoading, userError, login, signInWithToken, resetUserError } = props;

    const [searchParams] = useSearchParams();

    const invitationToken = searchParams.get("invite");

    const [inputs, setInputs] = useState<{
        email: TextInput,
        password: TextInput,
        invitationCode: TextInput,
        loginType: LoginMethods,
    }>({
        email: {
            value: "",
            error: null
        },
        password: {
            value: "",
            error: null
        },
        invitationCode: {
            value: invitationToken || "",
            error: null
        },
        loginType: invitationToken ? LoginMethods.INVITATION : LoginMethods.CREDENTIALS,
    });

    useEffect(() => {
        if (invitationToken) {
            signInWithToken(invitationToken);
        }
    }, [invitationToken]);

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        resetUserError();

        let loginType: LoginMethods;
        const invitationEdited = ["invitationCode"].includes(name);
        if (value) { // text has been entered
            loginType = invitationEdited ? LoginMethods.INVITATION : LoginMethods.CREDENTIALS;
        }
        else { // text removed
            loginType = invitationEdited ? LoginMethods.CREDENTIALS : LoginMethods.INVITATION;
        }

        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
            loginType: loginType,
        });
    }

    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let { email, password, invitationCode, loginType } = inputs;
        let error = false;

        if (loginType === LoginMethods.CREDENTIALS) { // login with email and password
            if (!email.value) {
                error = true;
                email.error = "";
            }
            else if (!isValidEmail(email.value)) {
                error = true;
                email.error = "Addresse email non valide";
            }

            if (!password.value) {
                error = true;
                password.error = "";
            }
        }
        else { // login with invitation
            if (!invitationCode.value) {
                error = true;
                invitationCode.error = "";
            }
        }

        if (error) {
            setInputs({
                ...inputs,
                email: email,
                password: password,
                invitationCode: invitationCode,
            });
        }
        else {
            if (loginType === LoginMethods.CREDENTIALS) { // login with email and password
                login(email.value, password.value);
            }
            else { // login with invitation
                signInWithToken(invitationCode.value);
            }
        }
    }

    const { email, password, invitationCode, loginType } = inputs;
    const formIsValid = 
        loginType === LoginMethods.CREDENTIALS && (email.value.length > 0 && password.value.length > 0)
        || loginType === LoginMethods.INVITATION && invitationCode.value.length > 0;

    const getPlaceholder = (field: string) => {
        return t(`login.${field}`, { ns: Namespaces.forms });
    }

    return (
        <Container
            sx={{
                mt: 4,
                pt: 6,
                maxWidth: 1280,
                margin: "0 auto",
                padding: "2rem",
                textAlign: 'center',
            }}
        >
            <Typography variant="h1">
                {t("ficha_dashboard", { ns: Namespaces.titles })}
            </Typography>
            <form
                style={{ marginTop: 40, }}
                autoComplete="on"
                method="post"
                action="#"
            >
                <TextField
                    id="email"
                    label={getPlaceholder("email")}
                    value={email.value}
                    margin="normal"
                    color="primary"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { handleInputChange('email', event.target.value) }
                    }}
                    error={Boolean(email.error)}
                    helperText={email.error}
                    disabled={userLoading}
                />

                <br />

                <TextField
                    id="password"
                    label={getPlaceholder("password")}
                    value={password.value}
                    margin="normal"
                    variant="outlined"
                    type='password'
                    InputProps={{
                        onChange: (event) => { handleInputChange('password', event.target.value) }
                    }}
                    error={Boolean(password.error)}
                    helperText={password.error}
                    disabled={userLoading}
                />

                <TextOverDivider
                    text={t("or", { ns: Namespaces.commons })}
                    />

                <TextField
                    id="invitation"
                    label={getPlaceholder("invitation_code")}
                    value={invitationCode.value}
                    margin="normal"
                    variant="outlined"
                    // type='password'
                    InputProps={{
                        onChange: (event) => { handleInputChange('invitationCode', event.target.value) }
                    }}
                    error={Boolean(invitationCode.error)}
                    helperText={invitationCode.error}
                    disabled={userLoading}
                />

                <Box textAlign="center">
                    <ActionButton
                        color="gradient"
                        disabled={!formIsValid}
                        loading={userLoading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {t("sign_in", { ns: Namespaces.actions })}
                    </ActionButton>
                    {/* <Divider className={classes.buttonsDivider} />
                    <Button
                        className={`${classes.actionButton} ${classes.registerButton}`}
                        color="primary"
                        variant="outlined"
                        href="/register/" >
                        Créer un compte
                    </Button> */}
                </Box>
            </form>
        </Container>
    )
}

export default connector(LoginPage);