import { Box, Typography } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from "store/reducers";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CoconSelect from "components/_include/Filters/CoconSelect";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { useEffect, useState } from "react";

const mapStateToProps = (state: StoreInterface) => ({
    selectedCocon: state.CoconReducer.selectedCocon.data,
    selectedCluster: state.CoconReducer.selectedCluster.data,
    coconLoading: state.CoconReducer.selectedCocon.loading,
    nbCocons: state.CoconReducer.cocons.data.length,
    clusters: state.CoconReducer.clusters.data,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps)

type HeaderProps = ConnectedProps<typeof connector> & {

}

function NameAndSelectHeader(props: HeaderProps) {

    const { selectedCocon, selectedCluster, nbCocons, clusters } = props;

    const [pagePropTitle, setPageTitle] = useState("");
    const [joinPropCode, setJoinCode] = useState("");

    const handleTitleChange = (title: string) => {
        setPageTitle(title);
    };


    const handleJoinCodeChange = (code: string) => {
        setJoinCode(code);
    };


    const cluster = clusters.find((cluster) => cluster.cocons.some((cocon) => cocon.id === selectedCocon?.id));


    const { t } = useTranslation([Namespaces.glossary]);

    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const { coconId, clusterId } = useParams();

    const handleCoconChange = (newCoconId: string) => {
        if (selectedCluster){
            const newPath = pathname.replace(`clusters/${clusterId}`, `cocons/${newCoconId}`);
            navigate(newPath + search);
        } 
        else {
            const newPath = pathname.replace(`cocons/${coconId}`, `cocons/${newCoconId}`);
            navigate(newPath + search);
        }
    }

    const handleClusterChange = (newClusterId: string) => {
        if (selectedCocon){
            const newPath = pathname.replace(`cocons/${coconId}`, `clusters/${newClusterId}`);
            navigate(newPath + search);
        } else {
            const newPath = pathname.replace(`clusters/${clusterId}`, `clusters/${newClusterId}`);
            navigate(newPath + search);
        }
    }


    useEffect(() => {
        if (selectedCluster) {
            handleTitleChange(selectedCluster?.displayAddress + " - " + selectedCluster?.managedBy.name);
            handleJoinCodeChange(t("join_code", { ns: Namespaces.glossary }) + " : " + selectedCluster?.joinCode);
        } else if (selectedCocon) {
            handleTitleChange(selectedCocon.getAddress() + " - " + cluster?.managedBy.name);
            handleJoinCodeChange(t("join_code", { ns: Namespaces.glossary }) + " : " + selectedCocon?.joinCode);
        }
    }, [selectedCluster, selectedCocon]);

    return (
        <Box
            display="flex"
            alignItems="center"
            mb={4}
        >
            <Box
                flexGrow={1}
            >
                <Typography
                    component="h2"
                    fontWeight={700}
                    fontSize="1.8rem"
                >
                    {pagePropTitle}
                </Typography>

                <Typography
                    variant="h6"
                // fontSize="1.8rem"
                >
                    {joinPropCode}
                </Typography>
            </Box>
            {nbCocons > 0 && (
                <Box>
                    <CoconSelect
                      onCoconChange={handleCoconChange}
                      onClusterChange={handleClusterChange}
                    />
                </Box>
            )}
        </Box>
    );
}

export default connector(NameAndSelectHeader);