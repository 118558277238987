import {  } from '@mui/material';
import StatTextTemplate from 'components/_include/Layout/StatTextTemplate';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { StoreInterface } from 'store/reducers';

const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
    errors: state.StatsReducer.coconWeeklySummary.errors,
});

const mapDispatchToProps = (dispatch: any) => ({

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CommonErrorsTextProps = ConnectedProps<typeof connector> & {

}

function CommonErrorsText(props: CommonErrorsTextProps) {
    const { errors, loading } = props;

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats, Namespaces.wastes]);

    const errorsString = (
        <ol style={{ lineHeight: 2.5 }}>
            {errors.map((error, index) => (
                <li key={error.type}>
                    {`${t(`${error.type}.label`, { ns: Namespaces.wastes })} (${t("error_count", { ns: Namespaces.glossary, count: error.count })})`}
                </li>
            ))}
        </ol>
    );

    return (
        <StatTextTemplate
            title={t("summary.common_errors", { ns: Namespaces.stats })}
            loading={loading}
            body={errorsString}
            />
    );
}

export default connector(CommonErrorsText);