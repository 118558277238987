import React, { } from 'react';
import { connect } from 'react-redux';
import { Avatar, Card, CardContent, CardMedia, Theme, Typography } from '@mui/material';
import { StoreInterface } from 'store/reducers';
import { makeStyles, createStyles } from '@mui/styles';
import Reward from 'models/Shop/Reward';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import RewardCostChip from './RewardCostChip';

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

interface RewardCardProps {
    reward: Reward;
}

function RewardCard(props: RewardCardProps) {

    const classes = useStyles();

    const { reward, } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.glossary]);

    return (
        <Card
            className={classes.card}
        >
            <CardMedia
                className={classes.media}
                image={reward.imageURL}
                title={reward.name}
            >
                <Avatar
                    sx={{
                        backgroundColor: "white.main",
                        width: (theme) => theme.spacing(10),
                        height: (theme) => theme.spacing(10),
                        padding: .75,
                        left: (theme) => theme.spacing(4),
                        marginTop: -5,
                        boxShadow: "rgba(0,0,0,0.1) 2px 4px 10px",

                        "& img": {
                            borderRadius: (theme) => theme.spacing(5),
                        },
                    }}
                    alt={reward.partner.name}
                    src={reward.partner.imageURL}
                />
                <RewardCostChip
                    cost={reward.cost}
                    />
            </CardMedia>
            <CardContent
                sx={{
                    marginTop: 7,
                    height: (theme) => theme.spacing(32),
                    overflowY: "scroll",
                    width: "100%",
                }}
            >
                <Typography
                    variant="caption"
                >
                    {t("offered_by", { ns: Namespaces.glossary, name: reward.partner.name })}
                </Typography>
                <Typography
                    variant="h5"
                >
                    {reward.name}
                </Typography>
                <Typography
                    variant="body1"
                >
                    {reward.description}
                </Typography>
            </CardContent>
        </Card>
    );
}

const GOLDEN_RATIO = 1 / 1.618;

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        flexGrow: 1,
        boxShadow: "2px 4px 10px rgba(0,0,0,0.2)",
    },

    media: {
        height: 0,
        paddingTop: `${GOLDEN_RATIO * 100}%`,
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(RewardCard);