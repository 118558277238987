import { ThemeProvider, } from '@mui/material/styles';
import theme from 'constants/theme';
import { BrowserRouter } from 'react-router-dom';
import Pages from 'components/Pages';
import { Provider } from 'react-redux';
import store from 'store/store';
import GlobalSnackbar from 'components/_include/GlobalSnackbar';

function App() {

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Pages />
          <GlobalSnackbar />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}


export default App;
