import { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Snackbar, Alert, Typography, AlertColor } from '@mui/material';
import { Types, Actions } from 'constants/actions';
import { StoreInterface } from 'store/reducers';

const mapStateToProps = (state: StoreInterface) => ({
    variant: state.ActionResultReducer.variant,
    message: state.ActionResultReducer.message,
});

const mapDispatchToProps = (dispatch: any) => ({
    resetActionResult: () => dispatch({ type: Types.SUCCESS, action: Actions.RESET_ACTION_RESULT }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type SnackbarProps = ConnectedProps<typeof connector> & {

}

function GlobalSnackbar(props: SnackbarProps) {

    const { message, variant } = props;

    const [open, setOpen] = useState(false);
    const [snackbarMessage, setMessage] = useState("");
    const [snackbarVariant, setVariant] = useState<AlertColor>("info");

    // callback to display messages pushed into localStorage or redux store
    useEffect(() => {
        let { message, variant, resetActionResult, } = props;
        let storedActionResult = localStorage.getItem("actionResult");
        if (storedActionResult) {
            const actionResult = JSON.parse(storedActionResult);
            variant = actionResult.variant;
            message = actionResult.message;
            localStorage.removeItem("actionResult");
        }

        if (message !== "") {
            setOpen(true);
            setMessage(message);
            setVariant(variant);

            resetActionResult();
        }
    }, [message]);

    const handleClose = () => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
        setMessage("");
        setVariant("info");
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            sx={{
                margin: '0 auto 2rem',
            }}
            >
            <Alert
                severity={snackbarVariant}
                onClose={handleClose}
                aria-describedby="message-id"
                >
                <Typography id="message-id" variant="body1" >
                    {snackbarMessage}
                </Typography>
            </Alert>
        </Snackbar>
    );
}

export default connector(GlobalSnackbar);