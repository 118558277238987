export default class Partner {
  id: string;
  name: string;
  imageURL: string;
  description?: string;
  email?: string;
  website?: string;
  category?: string;
  tags?: string[];

  public constructor(data: PartnerData) {
    this.id = data.id;
    this.name = data.name;
    this.imageURL = data.imageURL;
    this.description = data.description;
    this.email = data.email;
    this.website = data.website;
    this.category = data.category;
    this.tags = data.tags || [];
  }

  data(fields?: string[]): PartnerData {
    let data: any = {
      id: this.id,
      name: this.name,
      imageURL: this.imageURL,
      description: this.description,
      email: this.email,
      website: this.website,
      category: this.category,
      tags: this.tags,
    };

    if (fields) {
      Object.keys(data).forEach(field => {
        if (fields.indexOf(field) < 0) { // field not in fields to return
          delete data[field];
        }
      });
    }

    return data;
  };
}

export interface PartnerData {
  id: string;
  name: string;
  imageURL: string;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  address?: string;
  category?: string;
  tags?: string[];
}