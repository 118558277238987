import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from "store/reducers";
import moment from "moment";
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useTranslation } from "react-i18next";
import { DATE_SELECT_TYPES, getDatesFromDateSelectType, getDatesFromSearchParams } from "helpers/dates";
import { useSearchParams } from "react-router-dom";
import StatsAction from "actions/stats";
import { DatePicker } from 'react-rainbow-components';


const mapStateToProps = (state: StoreInterface) => ({
    loading: state.StatsReducer.loading,
    dateDeployed: state.CoconReducer.selectedCocon.data?.dateDeployed,
    selectedCocon: state.CoconReducer.selectedCocon.data,
    selectedCluster: state.CoconReducer.selectedCluster.data,
    coconId: state.CoconReducer.selectedCocon.data?.id,
    clusterId: state.CoconReducer.selectedCluster.data?.id,
});

const mapDispatchToProps = (dispatch: any) => ({
    // setDateSelectType: (type: DATE_SELECT_TYPES) => dispatch({ action: Actions.SET_DATES_SELECT, type: Types.SUCCESS, payload: { type: type } })
    loadCoconStats: (coconId: string, startDate: moment.Moment, endDate: moment.Moment,) => dispatch(StatsAction.getCoconStats(coconId, startDate, endDate,)),
    loadClusterStats: (clusterId: string, startDate: moment.Moment, endDate: moment.Moment,) => dispatch(StatsAction.getClusterStats(clusterId, startDate, endDate)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DatesSelectProps = ConnectedProps<typeof connector> & {

}

function DatesSelect(props: DatesSelectProps) {
    const { loading, dateDeployed, selectedCocon, selectedCluster, coconId, clusterId, loadClusterStats, loadCoconStats, } = props;

    const { t } = useTranslation([Namespaces.commons]);

    const [searchParams, setSearchParams] = useSearchParams();
    const { range, from, to } = getDatesFromSearchParams(searchParams);

    const handleDateSelectTypeChanged = (type: DATE_SELECT_TYPES) => {

        let dates: { start: moment.Moment, end: moment.Moment } = { start: moment(null), end: moment(null) };

        if (selectedCocon) {
            dates = getDatesFromDateSelectType(type, selectedCocon?.dateDeployed, from, to);
            loadCoconStats(selectedCocon.id, dates.start, dates.end);
        } else if (selectedCluster) {
            dates = getDatesFromDateSelectType(type, selectedCluster?.dateDeployed, from, to);
            loadClusterStats(selectedCluster.id, dates.start, dates.end);
        }
        // update URL search params
        searchParams.set("range", type);
        searchParams.set("from", dates.start.format("YYYY-MM-DD"));
        searchParams.set("to", dates.end.format("YYYY-MM-DD"));
        searchParams.set("selected", "1")
        setSearchParams(searchParams, { replace: true });

    };

    // Event handler for custom date selection
    const handleCustomDateSelected = (date: Date | null, type: "start" | "end") => {
        if ((selectedCocon || selectedCluster) && date) {
            // convert the Date to moment
            const momentDate = moment(date);

            if (coconId && from && to) {
                loadCoconStats(coconId, from, to);
            } else if (clusterId && from && to) {
                loadClusterStats(clusterId, from, to);
            }
            switch (type) {
                case "start":
                    // update URL search params
                    searchParams.set("from", momentDate.format("YYYY-MM-DD"));
                    setSearchParams(searchParams, { replace: true });
                    break;

                case "end":
                    // update URL search params
                    searchParams.set("to", momentDate.format("YYYY-MM-DD"));
                    setSearchParams(searchParams, { replace: true });
                    searchParams.set("selected", "1")
                    break;
            }

        }
    }

    return (
        <FormControl
            variant="outlined"
            disabled={loading}
            sx={{
                textAlign: "center",
                margin: 0,
                width: "100%",
                maxWidth: "none",
            }}
        >
            <InputLabel id="dates-select-label">
                {t("dates", { ns: Namespaces.commons })}
            </InputLabel>
            <Select
                labelId="dates-select-label"
                label={t("dates", { ns: Namespaces.commons })}
                value={range}
                onChange={(e) => handleDateSelectTypeChanged(e.target.value as DATE_SELECT_TYPES)}
                variant="outlined"
            >
                {Object.values(DATE_SELECT_TYPES).map(type => {
                    if (type === DATE_SELECT_TYPES.SINCE_INSTALLATION) {
                        return [
                            ...[<Divider light variant="middle" sx={{ my: 1, }} />], // add divider
                            dateDeployed ?
                                <MenuItem
                                    key={type}
                                    value={type}
                                >
                                    {t(type, { ns: Namespaces.commons })}
                                </MenuItem>
                                : undefined
                        ];
                    }

                    return (
                        <MenuItem
                            key={type}
                            value={type}
                        >
                            {t(type, { ns: Namespaces.commons })}
                        </MenuItem>
                    )
                })}
            </Select>
            <Grid
                container
                spacing={2}
                sx={{
                    mt: 1,
                    mb: 2,
                }}
            >
                {range === DATE_SELECT_TYPES.CUSTOM && (
                    <>
                        <Grid item sm={12} md={6}>
                        <DatePicker
                            borderRadius="semi-rounded"
                            id="datePicker-1"
                            minDate={dateDeployed?.toDate()}
                            maxDate={new Date()} 
                            value={from?.toDate()} //converting moment object to Date 
                            onChange={(date: Date) => handleCustomDateSelected(date, "start")}
                            label="Du"
                            locale="fr-Fr"
                        />
                        </Grid>
                        <Grid item sm={12} md={6}>
                        <DatePicker
                            borderRadius="semi-rounded"
                            id="datePicker-2"
                            minDate={from?.toDate()}
                            maxDate={new Date()} 
                            value={to?.toDate()} //converting moment object to Date
                            onChange={(date: Date) => handleCustomDateSelected(date, "end")}
                            label="Au"
                            locale="fr-Fr"
                        />
                        </Grid>
                    </>
                )}
            </Grid>
        </FormControl >
    );
}

export default connector(DatesSelect);