import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import moment from "moment";
import "moment/locale/fr";

import { configureTranslations } from "locales/translations";

import App from './App';

moment.locale("fr");

configureTranslations();

const root = createRoot(document.getElementById('root')!); // createRoot(container!) if you use TypeScript
root.render(App());

serviceWorker.unregister();
