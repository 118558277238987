import { connect, ConnectedProps } from "react-redux";
import { StoreInterface } from 'store/reducers';
import { Typography, Container, } from '@mui/material';
import RewardAction from 'actions/rewards';
import { RewardFormData } from 'models/Shop/Reward';
import RewardForm from './RewardForm';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'locales/translations';

const mapStateToProps = (state: StoreInterface) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
    suggestReward: (rewardData: FormData) => dispatch(RewardAction.suggestReward(rewardData)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type SuggestRewardPageProps = ConnectedProps<typeof connector> & {

}

function SuggestRewardPage(props: SuggestRewardPageProps) {

    const { suggestReward, } = props;

    const { t } = useTranslation([Namespaces.forms]);

    const initialData: RewardFormData = {
        partner: "",
        description: "",
        quantity: null,
        suggestedCost: 0,
        realPrice: 0,
        image: null,
        exclusiveCoconsClusters: [],
    };

    return (
        <Container 
            sx={{
                marginTop: 2,
                marginBottom: 0,
                paddingBottom: 4,
                overflow: "hidden",
            }}
            >
            <Typography sx={{ my: 2 }} variant="subtitle1">
                {t("reward.explanation", { ns: Namespaces.forms })}
            </Typography>
            <RewardForm
                rewardData={initialData}
                submitForm={suggestReward}
                />
        </Container>
    )
}

export default connector(SuggestRewardPage)